<template>
  <div class="header"><!-- header -->
    <div class="inner_header">
      <h1 class="logo"><a href="#" @click.prevent="$router.push('/sample/home').catch(()=>{}); setMenuOn(0)">e-kmtc</a></h1>
      <div class="wrap_gnb">

        <ul class="gnb">
          <li><a href="#" @click.prevent="$router.push('/sample/home').catch(()=>{}); setMenuOn(0)" v-bind:class="{ on: menus[0].on }" >{{menus[0].text}}</a></li>
          <li><a href="#" @click.prevent="$router.push('/sample/popup').catch(()=>{}); setMenuOn(1)" v-bind:class="{ on: menus[1].on }" >{{menus[1].text}}</a></li>
          <li><a href="#" @click.prevent="$router.push('/sample/process-work').catch(()=>{}); setMenuOn(2)" v-bind:class="{ on: menus[2].on }" >{{menus[2].text}}</a></li>
          <li><a href="#" @click.prevent="$router.push('/sample/grid').catch(()=>{}); setMenuOn(3)" v-bind:class="{ on: menus[3].on }" >{{menus[3].text}}</a></li>
          <li><a href="#" @click.prevent="$router.push('/sample/uploader').catch(()=>{}); setMenuOn(4)" v-bind:class="{ on: menus[4].on }" >{{menus[4].text}}</a></li>
          <li><a href="#" @click.prevent="$router.push('/sample/editor').catch(()=>{}); setMenuOn(5)" v-bind:class="{ on: menus[5].on }" >{{menus[5].text}}</a></li>
        </ul>

      </div>

      <e-header-util></e-header-util>
    </div>
  </div><!-- header // -->
</template>

<script>
import { rootComputed, rootMethods } from '@/store/helpers'
import EHeaderUtil from '@/components/layout/EHeaderUtil'

export default {
  name: 'Header',
  components: { EHeaderUtil },
  methods: {
    ...rootMethods,
    setMenuOn (id) {
      this.menus.forEach(menu => {
        menu.on = id === menu.id
      })
    }
  },
  computed: {
    ...rootComputed
  },
  data () {
    return {

      menus: [
        { id: 0, text: 'Sample Home', on: true },
        { id: 1, text: '팝업-개발', on: false },
        { id: 2, text: 'Tab Page', on: false },
        { id: 3, text: '그리드', on: false },
        { id: 4, text: '업로더', on: false },
        { id: 5, text: '에디터', on: false },
        { id: 6, text: '자료실', on: false },
        { id: 7, text: 'FAQ', on: false },
        { id: 8, text: '공지사항', on: false }
      ]

    }
  }
}
</script>

<style>
</style>
