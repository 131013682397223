<template>
  <div>
    <table class="tbl_row">
      <colgroup>
        <col width="140">
        <col>
      </colgroup>
      <tbody>
        <tr>
          <th>Select Box<p style="color:red;">(퍼블 관련 확인필요)</p></th>
          <td>
            <select-box
              v-model="data"
              :items="items"
              :input_id="'selectbox_id'"
              @input="value => { data = value }"
            >
            </select-box>
            <select-box
              v-model="data2"
              :items="items2"
              :input_id="'selectbox2_id'"
              @input="value => { data2 = value }"
            >
            </select-box>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import SelectBox from '@/components/SelectBox'

export default {
  name: 'SampleMultiSelectBox',
  components: {
    SelectBox
  },
  data: function () {
    return {
      data: '',
      items: {},
      data2: '',
      items2: {}
    }
  },
  watch: {
    data: function () {
      // console.log('data = ' + this.data)
      if (this.data === 'option1') {
        this.setOption1Item()
      } else if (this.data === 'option2') {
        this.setOption2Item()
      } else if (this.data === 'option3') {
        this.setOption3Item()
      }
    }
  },
  mounted () {
    // console.log('mounted')
    this.getSelectBoxItem()
  },
  methods: {
    getSelectBoxItem () {
      // console.log('getSelectBoxItem')
      this.items = { option1: '옵션1', option2: '옵션2', option3: '옵션3' }
    },
    setOption1Item () {
      this.items2 = { option1: '옵션1Item1', option2: '옵션1Item2', option3: '옵션1Item3' }
    },
    setOption2Item () {
      this.items2 = { option1: '옵션2Item1', option2: '옵션2Item2', option3: '옵션2Item3' }
    },
    setOption3Item () {
      this.items2 = { option1: '옵션3Item1', option2: '옵션3Item2', option3: '옵션3Item3' }
    }
  }
}
</script>

<style scoped>

</style>
