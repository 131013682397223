var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container_ekmtc" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("h1", { staticClass: "page_title" }, [_vm._v("팝업 리스트")]),
        _c(
          "win-layer-pop",
          { staticClass: "sample_popup_list" },
          [
            _vm.customComponent
              ? _c(_vm.customComponent, {
                  tag: "component",
                  attrs: { "parent-info": _vm.parentInfo },
                  on: { close: _vm.closePopup },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "content_box" }, [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v("COMMON => " + _vm._s(_vm.urlInfo.commonUrl)),
          ]),
          _c("h1", { staticClass: "page_title" }, [
            _vm._v("TRANS => " + _vm._s(_vm.urlInfo.transUrl)),
          ]),
          _c("h1", { staticClass: "page_title" }, [
            _vm._v("REPORT => " + _vm._s(_vm.urlInfo.reportUrl)),
          ]),
          _c("h1", { staticClass: "page_title" }, [
            _vm._v("CTR_CD => " + _vm._s(_vm.memberDetail.userCtrCd)),
          ]),
          _c("h1", { staticClass: "page_title" }, [
            _vm._v("PLC_CD => " + _vm._s(_vm.memberDetail.bkgPlcCd)),
          ]),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("h1", { staticClass: "page_title" }, [_vm._v("Arrival Notice")]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(0),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("BKG NO")]),
                    _c("th", [_vm._v("BL NO")]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addArrivalNotice()
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                    _c("th"),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.popupParams.ArrivalNoticePop.items,
                    function (vo, idx) {
                      return _c("tr", { key: "ArrivalNoticePop_" + idx }, [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.bkgNo,
                                expression: "vo.bkgNo",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: vo.bkgNo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "bkgNo", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.blNo,
                                expression: "vo.blNo",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: vo.blNo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "blNo", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeArrivalNotice(idx)
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ]),
                        _c("td"),
                      ])
                    }
                  ),
                  0
                ),
              ]),
              _c("p", { staticClass: "comment" }, [
                _vm._v(
                  "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                ),
              ]),
              _c("table", { staticClass: "tbl_type mt5" }, [
                _vm._m(1),
                _vm._m(2),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.ArrivalNoticePop.testUsrNo,
                            expression:
                              "popupParams.ArrivalNoticePop.testUsrNo",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.ArrivalNoticePop.testUsrNo,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.ArrivalNoticePop,
                              "testUsrNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.ArrivalNoticePop.testCtrCd,
                            expression:
                              "popupParams.ArrivalNoticePop.testCtrCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.ArrivalNoticePop.testCtrCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.ArrivalNoticePop,
                              "testCtrCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.popupParams.ArrivalNoticePop.testBkgPlcCd,
                            expression:
                              "popupParams.ArrivalNoticePop.testBkgPlcCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.ArrivalNoticePop.testBkgPlcCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.ArrivalNoticePop,
                              "testBkgPlcCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("ArrivalNoticePop")
                      },
                    },
                  },
                  [_vm._v("A/N 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [_vm._v("Delivery Order")]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(3),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("B/L No.")]),
                    _c("th", [_vm._v("D/O No.")]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addDeliveryOrder()
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                    _c("th"),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.popupParams.DeliveryOrderPop.items,
                    function (vo, idx) {
                      return _c("tr", { key: "DeliveryOrderPop_" + idx }, [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.blNo,
                                expression: "vo.blNo",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: vo.blNo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "blNo", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.doRno,
                                expression: "vo.doRno",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: vo.doRno },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "doRno", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeDeliveryOrder(idx)
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ]),
                        _c("td"),
                      ])
                    }
                  ),
                  0
                ),
              ]),
              _c("p", { staticClass: "comment" }, [
                _vm._v(
                  "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                ),
              ]),
              _c(
                "p",
                {
                  staticClass: "comment",
                  staticStyle: { "font-weight": "bold" },
                },
                [_vm._v("※나라가 KR, ID 에서만 정상 작동합니다.")]
              ),
              _c("table", { staticClass: "tbl_type mt5" }, [
                _vm._m(4),
                _vm._m(5),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.DeliveryOrderPop.testUsrNo,
                            expression:
                              "popupParams.DeliveryOrderPop.testUsrNo",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.DeliveryOrderPop.testUsrNo,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.DeliveryOrderPop,
                              "testUsrNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.DeliveryOrderPop.testCtrCd,
                            expression:
                              "popupParams.DeliveryOrderPop.testCtrCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.DeliveryOrderPop.testCtrCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.DeliveryOrderPop,
                              "testCtrCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.DeliveryOrderPop.testAgtCd,
                            expression:
                              "popupParams.DeliveryOrderPop.testAgtCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.DeliveryOrderPop.testAgtCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.DeliveryOrderPop,
                              "testAgtCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.popupParams.DeliveryOrderPop.testBkgPlcCd,
                            expression:
                              "popupParams.DeliveryOrderPop.testBkgPlcCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.DeliveryOrderPop.testBkgPlcCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.DeliveryOrderPop,
                              "testBkgPlcCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("DeliveryOrderPop")
                      },
                    },
                  },
                  [_vm._v("D/O 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v("BL Invoice Freight"),
          ]),
          _c(
            "ul",
            { staticClass: "ul_box", staticStyle: { "overflow-x": "auto" } },
            [
              _c("li", { staticClass: "fl", staticStyle: { width: "70%" } }, [
                _c("table", { staticClass: "tbl_type" }, [
                  _vm._m(6),
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("Invoice No.")]),
                      _c("th", [_vm._v("TO")]),
                      _c("th", [_vm._v("Remark")]),
                      _c("th", [_vm._v("Filter")]),
                      _c("th", [_vm._v("B/L No")]),
                      _c("th", [_vm._v("Esign (PH 에서만 사용)")]),
                      _c("th", [
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon plus",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addBlInvoiceFreight()
                              },
                            },
                          },
                          [_vm._v("plus")]
                        ),
                      ]),
                      _c("th"),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(
                      _vm.popupParams.BlInvoiceFreightPop.items,
                      function (vo, idx) {
                        return _c("tr", { key: "BlInvoiceFreightPop_" + idx }, [
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.invIssNo,
                                  expression: "vo.invIssNo",
                                },
                              ],
                              attrs: { type: "text" },
                              domProps: { value: vo.invIssNo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "invIssNo", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.to,
                                  expression: "vo.to",
                                },
                              ],
                              attrs: { type: "text" },
                              domProps: { value: vo.to },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "to", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.remark,
                                  expression: "vo.remark",
                                },
                              ],
                              attrs: { type: "text" },
                              domProps: { value: vo.remark },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "remark", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.filter,
                                  expression: "vo.filter",
                                },
                              ],
                              attrs: { type: "text" },
                              domProps: { value: vo.filter },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "filter", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.blNo,
                                  expression: "vo.blNo",
                                },
                              ],
                              attrs: { type: "text" },
                              domProps: { value: vo.blNo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "blNo", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.esign,
                                  expression: "vo.esign",
                                },
                              ],
                              attrs: { type: "text" },
                              domProps: { value: vo.esign },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "esign", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c(
                            "td",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "tbl_icon minus",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.removeBlInvoiceFreight(idx)
                                    },
                                  },
                                },
                                [_vm._v("minus")]
                              ),
                            ]
                          ),
                          _c("td"),
                        ])
                      }
                    ),
                    0
                  ),
                ]),
                _c("p", { staticClass: "comment" }, [
                  _vm._v(
                    "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                  ),
                ]),
                _c("table", { staticClass: "tbl_type mt5" }, [
                  _vm._m(7),
                  _vm._m(8),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.popupParams.BlInvoiceFreightPop.testCtrCd,
                              expression:
                                "popupParams.BlInvoiceFreightPop.testCtrCd",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value:
                              _vm.popupParams.BlInvoiceFreightPop.testCtrCd,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.BlInvoiceFreightPop,
                                "testCtrCd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.popupParams.BlInvoiceFreightPop.testAgtCd,
                              expression:
                                "popupParams.BlInvoiceFreightPop.testAgtCd",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value:
                              _vm.popupParams.BlInvoiceFreightPop.testAgtCd,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.BlInvoiceFreightPop,
                                "testAgtCd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.popupParams.BlInvoiceFreightPop
                                  .testBkgPlcCd,
                              expression:
                                "popupParams.BlInvoiceFreightPop.testBkgPlcCd",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value:
                              _vm.popupParams.BlInvoiceFreightPop.testBkgPlcCd,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.BlInvoiceFreightPop,
                                "testBkgPlcCd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td"),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "btn_wrap" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("BlInvoiceFreightPop")
                        },
                      },
                    },
                    [_vm._v("BL Invoice Freight 팝업")]
                  ),
                ]),
              ]),
              _c("li", { staticClass: "fr", staticStyle: { width: "29%" } }),
            ]
          ),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [_vm._v("Certificate")]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(9),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("Certi No.")]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addCertificate()
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                    _c("th"),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.popupParams.CertificatePop.items,
                    function (vo, idx) {
                      return _c("tr", { key: "CertificatePop_" + idx }, [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.crtfNo,
                                expression: "vo.crtfNo",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: vo.crtfNo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "crtfNo", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeCertificate(idx)
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ]),
                        _c("td"),
                      ])
                    }
                  ),
                  0
                ),
              ]),
              _c("p", { staticClass: "comment" }, [
                _vm._v(
                  "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                ),
              ]),
              _c("table", { staticClass: "tbl_type mt5" }, [
                _vm._m(10),
                _vm._m(11),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.CertificatePop.testCtrCd,
                            expression: "popupParams.CertificatePop.testCtrCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.CertificatePop.testCtrCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.CertificatePop,
                              "testCtrCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.CertificatePop.testBkgPlcCd,
                            expression:
                              "popupParams.CertificatePop.testBkgPlcCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.CertificatePop.testBkgPlcCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.CertificatePop,
                              "testBkgPlcCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("CertificatePop")
                      },
                    },
                  },
                  [_vm._v("Certificate 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [_vm._v("B/L Copy")]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(12),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("B/L No.")]),
                    _c("th", [_vm._v("Por Dly(Y/N)")]),
                    _c("th", [_vm._v("No Cnee(Y/N)")]),
                    _c("th", [_vm._v("No Notify(Y/N)")]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addBlCopy()
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                    _c("th"),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.popupParams.BlCopyPop.items, function (vo, idx) {
                    return _c("tr", { key: "BlCopyPop_" + idx }, [
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.blNo,
                              expression: "vo.blNo",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: { value: vo.blNo },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(vo, "blNo", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.porDlyYn,
                              expression: "vo.porDlyYn",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: { value: vo.porDlyYn },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(vo, "porDlyYn", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.noCnee,
                              expression: "vo.noCnee",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: { value: vo.noCnee },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(vo, "noCnee", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.noNotify,
                              expression: "vo.noNotify",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: { value: vo.noNotify },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(vo, "noNotify", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon minus",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.removeBlCopy(idx)
                              },
                            },
                          },
                          [_vm._v("minus")]
                        ),
                      ]),
                      _c("td"),
                    ])
                  }),
                  0
                ),
              ]),
              _c("p", { staticClass: "comment" }, [
                _vm._v(
                  "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                ),
              ]),
              _c("table", { staticClass: "tbl_type mt5" }, [
                _vm._m(13),
                _vm._m(14),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.BlCopyPop.testUserId,
                            expression: "popupParams.BlCopyPop.testUserId",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.BlCopyPop.testUserId,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.BlCopyPop,
                              "testUserId",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.BlCopyPop.testCtrCd,
                            expression: "popupParams.BlCopyPop.testCtrCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.BlCopyPop.testCtrCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.BlCopyPop,
                              "testCtrCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.BlCopyPop.testBkgPlcCd,
                            expression: "popupParams.BlCopyPop.testBkgPlcCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.BlCopyPop.testBkgPlcCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.BlCopyPop,
                              "testBkgPlcCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("BlCopyPop")
                      },
                    },
                  },
                  [_vm._v("B/L Copy 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v("Booking Confirmation"),
          ]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(15),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("BKG NO")]),
                    _c("th", [_vm._v("GUBUN")]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addBookingConfirm()
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.popupParams.BookingConfirmPop.items,
                    function (vo, idx) {
                      return _c("tr", { key: "BookingConfirmPop_" + idx }, [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.bkgNo,
                                expression: "vo.bkgNo",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: vo.bkgNo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "bkgNo", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.gubun,
                                expression: "vo.gubun",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: vo.gubun },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "gubun", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeBookingConfirm(idx)
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ]),
                      ])
                    }
                  ),
                  0
                ),
              ]),
              _c("p", { staticClass: "comment" }, [
                _vm._v(
                  "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                ),
              ]),
              _c("table", { staticClass: "tbl_type mt5" }, [
                _vm._m(16),
                _vm._m(17),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.BookingConfirmPop.testUsrNo,
                            expression:
                              "popupParams.BookingConfirmPop.testUsrNo",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.BookingConfirmPop.testUsrNo,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.BookingConfirmPop,
                              "testUsrNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.BookingConfirmPop.testCtrCd,
                            expression:
                              "popupParams.BookingConfirmPop.testCtrCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.BookingConfirmPop.testCtrCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.BookingConfirmPop,
                              "testCtrCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.popupParams.BookingConfirmPop.testBkgPlcCd,
                            expression:
                              "popupParams.BookingConfirmPop.testBkgPlcCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.BookingConfirmPop.testBkgPlcCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.BookingConfirmPop,
                              "testBkgPlcCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("BookingConfirmPop")
                      },
                    },
                  },
                  [_vm._v("Booking Confirmation 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v("Delay Notice(수출)"),
          ]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(18),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("DOC NO")]),
                    _c("th", [_vm._v("PRT GUBUN")]),
                    _c("th", [_vm._v("PARM1")]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addDelayNotice("out")
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.popupParams.DelayNoticeOutPop.items,
                    function (vo, idx) {
                      return _c("tr", { key: "DelayNoticeOutPop_" + idx }, [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.docNo,
                                expression: "vo.docNo",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: vo.docNo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "docNo", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.prtGubun,
                                expression: "vo.prtGubun",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: vo.prtGubun },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "prtGubun", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.parm1,
                                expression: "vo.parm1",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: vo.parm1 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "parm1", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeDelayNotice(idx, "out")
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ]),
                      ])
                    }
                  ),
                  0
                ),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("DelayNoticeOutPop")
                      },
                    },
                  },
                  [_vm._v("Deplay Notice(수출) 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v("Delay Notice(수입)"),
          ]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(19),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("DOC NO")]),
                    _c("th", [_vm._v("PRT GUBUN")]),
                    _c("th", [_vm._v("PARM1")]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addDelayNotice("in")
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.popupParams.DelayNoticeInPop.items,
                    function (vo, idx) {
                      return _c("tr", { key: "DelayNoticeInPop_" + idx }, [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.docNo,
                                expression: "vo.docNo",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: vo.docNo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "docNo", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.prtGubun,
                                expression: "vo.prtGubun",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: vo.prtGubun },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "prtGubun", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.parm1,
                                expression: "vo.parm1",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: vo.parm1 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "parm1", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeDelayNotice(idx, "in")
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ]),
                      ])
                    }
                  ),
                  0
                ),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("DelayNoticeInPop")
                      },
                    },
                  },
                  [_vm._v("Deplay Notice(수입) 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v("Vessel Change Notice"),
          ]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(20),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("DOC NO")]),
                    _c("th", [_vm._v("PRT GUBUN")]),
                    _c("th", [_vm._v("PARM1")]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addDelayNotice("in")
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.popupParams.VesselChangePop.items,
                    function (vo, idx) {
                      return _c("tr", { key: "DelayNoticeInPop_" + idx }, [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.docNo,
                                expression: "vo.docNo",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: vo.docNo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "docNo", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.prtGubun,
                                expression: "vo.prtGubun",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: vo.prtGubun },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "prtGubun", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.parm1,
                                expression: "vo.parm1",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: vo.parm1 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "parm1", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeDelayNotice(idx, "in")
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ]),
                      ])
                    }
                  ),
                  0
                ),
              ]),
              _c("p", { staticClass: "comment" }, [
                _vm._v(
                  "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                ),
              ]),
              _c("table", { staticClass: "tbl_type mt5" }, [
                _vm._m(21),
                _vm._m(22),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.VesselChangePop.testUsrNo,
                            expression: "popupParams.VesselChangePop.testUsrNo",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.VesselChangePop.testUsrNo,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.VesselChangePop,
                              "testUsrNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td"),
                    _c("td"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("VesselChangePop")
                      },
                    },
                  },
                  [_vm._v("Vessel Change 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v("Proforma Freight Invoice"),
          ]),
          _c(
            "ul",
            { staticClass: "ul_box", staticStyle: { "overflow-x": "auto" } },
            [
              _c("li", { staticClass: "fl" }, [
                _c("table", { staticClass: "tbl_type" }, [
                  _vm._m(23),
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("BL_NO")]),
                      _c("th", [_vm._v("PORT_CD")]),
                      _c("th", [_vm._v("PAY_AGTORG_NO")]),
                      _c("th", [_vm._v("CST_NO")]),
                      _c("th", [_vm._v("TO")]),
                      _c("th", [_vm._v("REMARK")]),
                      _c("th", [
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon plus",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addProformaFreight()
                              },
                            },
                          },
                          [_vm._v("plus")]
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(
                      _vm.popupParams.ProformaFreightPop.items,
                      function (vo, idx) {
                        return _c("tr", { key: "DelayNoticeInPop_" + idx }, [
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.blNo,
                                  expression: "vo.blNo",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.blNo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "blNo", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.portCd,
                                  expression: "vo.portCd",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.portCd },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "portCd", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.payAgtorgNo,
                                  expression: "vo.payAgtorgNo",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.payAgtorgNo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    vo,
                                    "payAgtorgNo",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.cstNo,
                                  expression: "vo.cstNo",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.cstNo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "cstNo", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.to,
                                  expression: "vo.to",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.to },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "to", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.remark,
                                  expression: "vo.remark",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.remark },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "remark", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "tbl_icon minus",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.removeProformaFreight(idx)
                                  },
                                },
                              },
                              [_vm._v("minus")]
                            ),
                          ]),
                        ])
                      }
                    ),
                    0
                  ),
                ]),
                _c("p", { staticClass: "comment" }, [
                  _vm._v(
                    "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                  ),
                ]),
                _c("table", { staticClass: "tbl_type mt5" }, [
                  _vm._m(24),
                  _vm._m(25),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.popupParams.ProformaFreightPop.testUsrNo,
                              expression:
                                "popupParams.ProformaFreightPop.testUsrNo",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.popupParams.ProformaFreightPop.testUsrNo,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.ProformaFreightPop,
                                "testUsrNo",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.popupParams.ProformaFreightPop.testCtrCd,
                              expression:
                                "popupParams.ProformaFreightPop.testCtrCd",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.popupParams.ProformaFreightPop.testCtrCd,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.ProformaFreightPop,
                                "testCtrCd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.popupParams.ProformaFreightPop.testAgtCd,
                              expression:
                                "popupParams.ProformaFreightPop.testAgtCd",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.popupParams.ProformaFreightPop.testAgtCd,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.ProformaFreightPop,
                                "testAgtCd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.popupParams.ProformaFreightPop.testBkgPlcCd,
                              expression:
                                "popupParams.ProformaFreightPop.testBkgPlcCd",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value:
                              _vm.popupParams.ProformaFreightPop.testBkgPlcCd,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.ProformaFreightPop,
                                "testBkgPlcCd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "btn_wrap" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("ProformaFreightPop")
                        },
                      },
                    },
                    [_vm._v("Proforma Freight 팝업")]
                  ),
                ]),
              ]),
              _c("li", { staticClass: "fr" }),
            ]
          ),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [_vm._v("S/O Download")]),
          _c(
            "ul",
            { staticClass: "ul_box", staticStyle: { "overflow-x": "auto" } },
            [
              _c("li", { staticClass: "fl" }, [
                _c("table", { staticClass: "tbl_type" }, [
                  _vm._m(26),
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("BKG_NO")]),
                      _c("th", [_vm._v("GUBUN")]),
                      _c("th", [
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon plus",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addShippingOrder()
                              },
                            },
                          },
                          [_vm._v("plus")]
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(
                      _vm.popupParams.ShippingOrderPop.items,
                      function (vo, idx) {
                        return _c("tr", { key: "DelayNoticeInPop_" + idx }, [
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.bkgNo,
                                  expression: "vo.bkgNo",
                                },
                              ],
                              attrs: { type: "text" },
                              domProps: { value: vo.bkgNo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "bkgNo", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.gubun,
                                  expression: "vo.gubun",
                                },
                              ],
                              attrs: { type: "text" },
                              domProps: { value: vo.gubun },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "gubun", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "tbl_icon minus",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.removeShippingOrder(idx)
                                  },
                                },
                              },
                              [_vm._v("minus")]
                            ),
                          ]),
                        ])
                      }
                    ),
                    0
                  ),
                ]),
                _c("p", { staticClass: "comment" }, [
                  _vm._v(
                    "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                  ),
                ]),
                _c(
                  "p",
                  {
                    staticClass: "comment",
                    staticStyle: { "font-size": "12px", "font-weight": "bold" },
                  },
                  [
                    _vm._v(
                      "#1-[CN(HKCSZP)], #2-[CN(SZP,HUA,NSN)], #3-[CN(TAO)], #4-[CN(그외)] 가 같이 포함되어 있습니다."
                    ),
                  ]
                ),
                _c(
                  "p",
                  {
                    staticClass: "comment",
                    staticStyle: { "font-weight": "bold" },
                  },
                  [_vm._v("※나라가 CN 에서만 정상 작동합니다.")]
                ),
                _c("table", { staticClass: "tbl_type mt5" }, [
                  _vm._m(27),
                  _vm._m(28),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.popupParams.ShippingOrderPop.testUsrNo,
                              expression:
                                "popupParams.ShippingOrderPop.testUsrNo",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.popupParams.ShippingOrderPop.testUsrNo,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.ShippingOrderPop,
                                "testUsrNo",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.popupParams.ShippingOrderPop.testCtrCd,
                              expression:
                                "popupParams.ShippingOrderPop.testCtrCd",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.popupParams.ShippingOrderPop.testCtrCd,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.ShippingOrderPop,
                                "testCtrCd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.popupParams.ShippingOrderPop.testAgtCd,
                              expression:
                                "popupParams.ShippingOrderPop.testAgtCd",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.popupParams.ShippingOrderPop.testAgtCd,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.ShippingOrderPop,
                                "testAgtCd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.popupParams.ShippingOrderPop.testBkgPlcCd,
                              expression:
                                "popupParams.ShippingOrderPop.testBkgPlcCd",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value:
                              _vm.popupParams.ShippingOrderPop.testBkgPlcCd,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.ShippingOrderPop,
                                "testBkgPlcCd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "btn_wrap" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("ShippingOrderPop")
                        },
                      },
                    },
                    [_vm._v("Shipping Order 팝업")]
                  ),
                ]),
              ]),
              _c("li", { staticClass: "fr" }),
            ]
          ),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [_vm._v("Free Time 승인")]),
          _c(
            "ul",
            { staticClass: "ul_box", staticStyle: { "overflow-x": "auto" } },
            [
              _c("li", { staticClass: "fl" }, [
                _c("table", { staticClass: "tbl_type" }, [
                  _vm._m(29),
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("BL_NO")]),
                      _c("th", [
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon plus",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addFreeTime()
                              },
                            },
                          },
                          [_vm._v("plus")]
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(
                      _vm.popupParams.FreeTimePop.items,
                      function (vo, idx) {
                        return _c("tr", { key: "FreeTimePop_" + idx }, [
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.blNo,
                                  expression: "vo.blNo",
                                },
                              ],
                              attrs: { type: "text" },
                              domProps: { value: vo.blNo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "blNo", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "tbl_icon minus",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.removeFreeTime(idx)
                                  },
                                },
                              },
                              [_vm._v("minus")]
                            ),
                          ]),
                        ])
                      }
                    ),
                    0
                  ),
                ]),
                _c("p", { staticClass: "comment" }, [
                  _vm._v(
                    "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                  ),
                ]),
                _c("table", { staticClass: "tbl_type mt5" }, [
                  _vm._m(30),
                  _vm._m(31),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.popupParams.FreeTimePop.testUsrNo,
                              expression: "popupParams.FreeTimePop.testUsrNo",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.popupParams.FreeTimePop.testUsrNo,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.FreeTimePop,
                                "testUsrNo",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.popupParams.FreeTimePop.testCtrCd,
                              expression: "popupParams.FreeTimePop.testCtrCd",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.popupParams.FreeTimePop.testCtrCd,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.FreeTimePop,
                                "testCtrCd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.popupParams.FreeTimePop.testAgtCd,
                              expression: "popupParams.FreeTimePop.testAgtCd",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.popupParams.FreeTimePop.testAgtCd,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.FreeTimePop,
                                "testAgtCd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.popupParams.FreeTimePop.testBkgPlcCd,
                              expression:
                                "popupParams.FreeTimePop.testBkgPlcCd",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.popupParams.FreeTimePop.testBkgPlcCd,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.FreeTimePop,
                                "testBkgPlcCd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "btn_wrap" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("FreeTimePop")
                        },
                      },
                    },
                    [_vm._v("Free Time 팝업")]
                  ),
                ]),
              ]),
              _c("li", { staticClass: "fr" }),
            ]
          ),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [_vm._v("JP CLP")]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(32),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("BL_NO")]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addJpClp()
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                    _c("th"),
                    _c("th"),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.popupParams.JpClpPop.items, function (vo, idx) {
                    return _c("tr", { key: "JpClpPop_" + idx }, [
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.blNo,
                              expression: "vo.blNo",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: { value: vo.blNo },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(vo, "blNo", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon minus",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.removeJpClp(idx)
                              },
                            },
                          },
                          [_vm._v("minus")]
                        ),
                      ]),
                      _c("td"),
                      _c("td"),
                    ])
                  }),
                  0
                ),
              ]),
              _c("p", { staticClass: "comment" }, [
                _vm._v(
                  "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                ),
              ]),
              _c(
                "p",
                {
                  staticClass: "comment",
                  staticStyle: { "font-weight": "bold" },
                },
                [_vm._v("※나라가 JP 에서만 정상 작동합니다.")]
              ),
              _c("table", { staticClass: "tbl_type mt5" }, [
                _vm._m(33),
                _vm._m(34),
                _c("tbody", [
                  _c("tr", [
                    _c("td"),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.JpClpPop.testCtrCd,
                            expression: "popupParams.JpClpPop.testCtrCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: { value: _vm.popupParams.JpClpPop.testCtrCd },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.JpClpPop,
                              "testCtrCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("JpClpPop")
                      },
                    },
                  },
                  [_vm._v("JP CLP 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [_vm._v("선적 마감 안내문")]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(35),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("BKG_NO")]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addShipmentFinish()
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                    _c("th"),
                    _c("th"),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.popupParams.ShipmentFinishPop.items,
                    function (vo, idx) {
                      return _c("tr", { key: "ShipmentFinishPop_" + idx }, [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.bkgNo,
                                expression: "vo.bkgNo",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: vo.bkgNo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "bkgNo", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeShipmentFinish(idx)
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ]),
                        _c("td"),
                        _c("td"),
                      ])
                    }
                  ),
                  0
                ),
              ]),
              _c("p", { staticClass: "comment" }, [
                _vm._v(
                  "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                ),
              ]),
              _c(
                "p",
                {
                  staticClass: "comment",
                  staticStyle: { "font-weight": "bold" },
                },
                [_vm._v("※나라가 KR 에서만 정상 작동합니다.")]
              ),
              _c("table", { staticClass: "tbl_type mt5" }, [
                _vm._m(36),
                _vm._m(37),
                _c("tbody", [
                  _c("tr", [
                    _c("td"),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.ShipmentFinishPop.testCtrCd,
                            expression:
                              "popupParams.ShipmentFinishPop.testCtrCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.ShipmentFinishPop.testCtrCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.ShipmentFinishPop,
                              "testCtrCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("ShipmentFinishPop")
                      },
                    },
                  },
                  [_vm._v("선적 마감 안내문 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [_vm._v("Receipt Print")]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(38),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("RCV_RNO")]),
                    _c("th", [_vm._v("PRT_RVST_NO")]),
                    _c("th", [_vm._v("CST_NO")]),
                    _c("th", [_vm._v("CST_NM")]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addReceiptPrint()
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.popupParams.ReceiptPrintPop.items,
                    function (vo, idx) {
                      return _c("tr", { key: "ReceiptPrintPop_" + idx }, [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.rcvRno,
                                expression: "vo.rcvRno",
                              },
                            ],
                            staticStyle: { width: "140px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.rcvRno },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "rcvRno", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.prtRvstNo,
                                expression: "vo.prtRvstNo",
                              },
                            ],
                            staticStyle: { width: "140px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.prtRvstNo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "prtRvstNo", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.cstNo,
                                expression: "vo.cstNo",
                              },
                            ],
                            staticStyle: { width: "140px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.cstNo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "cstNo", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.cstNm,
                                expression: "vo.cstNm",
                              },
                            ],
                            staticStyle: { width: "140px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.cstNm },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "cstNm", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeReceiptPrint(idx)
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ]),
                      ])
                    }
                  ),
                  0
                ),
              ]),
              _c("p", { staticClass: "comment" }, [
                _vm._v(
                  "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                ),
              ]),
              _c(
                "p",
                {
                  staticClass: "comment",
                  staticStyle: { "font-weight": "bold" },
                },
                [_vm._v("※나라가 ID 에서만 정상 작동합니다.")]
              ),
              _c("table", { staticClass: "tbl_type mt5" }, [
                _vm._m(39),
                _vm._m(40),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.ReceiptPrintPop.testUsrNo,
                            expression: "popupParams.ReceiptPrintPop.testUsrNo",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.ReceiptPrintPop.testUsrNo,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.ReceiptPrintPop,
                              "testUsrNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.ReceiptPrintPop.testCtrCd,
                            expression: "popupParams.ReceiptPrintPop.testCtrCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.ReceiptPrintPop.testCtrCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.ReceiptPrintPop,
                              "testCtrCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.ReceiptPrintPop.testAgtCd,
                            expression: "popupParams.ReceiptPrintPop.testAgtCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.ReceiptPrintPop.testAgtCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.ReceiptPrintPop,
                              "testAgtCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("ReceiptPrintPop")
                      },
                    },
                  },
                  [_vm._v("Receipt Print 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v("적하목록 (B/L 단위)"),
          ]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(41),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("BL_NO")]),
                    _c("th", [_vm._v("VSL_CD(입항선박 코드)")]),
                    _c("th", [_vm._v("VOY_NO(입항선박 항차)")]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addCargoMainFest()
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.popupParams.CargoMainFestPop.items,
                    function (vo, idx) {
                      return _c("tr", { key: "CargoMainFestPop_" + idx }, [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.blNo,
                                expression: "vo.blNo",
                              },
                            ],
                            staticStyle: { width: "140px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.blNo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "blNo", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.vslCd,
                                expression: "vo.vslCd",
                              },
                            ],
                            staticStyle: { width: "140px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.vslCd },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "vslCd", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.voyNo,
                                expression: "vo.voyNo",
                              },
                            ],
                            staticStyle: { width: "140px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.voyNo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "voyNo", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeCargoMainFest(idx)
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ]),
                      ])
                    }
                  ),
                  0
                ),
              ]),
              _c("p", { staticClass: "comment" }, [
                _vm._v(
                  "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                ),
              ]),
              _c(
                "p",
                {
                  staticClass: "comment",
                  staticStyle: { "font-weight": "bold" },
                },
                [_vm._v("※나라가 KR 에서만 정상 작동합니다.")]
              ),
              _c("table", { staticClass: "tbl_type mt5" }, [
                _vm._m(42),
                _vm._m(43),
                _c("tbody", [
                  _c("tr", [
                    _c("td"),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.CargoMainFestPop.testCtrCd,
                            expression:
                              "popupParams.CargoMainFestPop.testCtrCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.CargoMainFestPop.testCtrCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.CargoMainFestPop,
                              "testCtrCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("CargoMainFestPop")
                      },
                    },
                  },
                  [_vm._v("적하목록 (B/L 단위) 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v("수출 업무 위임장 #1"),
          ]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(44),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("GRP_CAT_CD")]),
                    _c("th", [_vm._v("REQ_NO")]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addOutCommission1()
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.popupParams.OutCommission1Pop.items,
                    function (vo, idx) {
                      return _c("tr", { key: "OutCommission1Pop_" + idx }, [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.grpCatCd,
                                expression: "vo.grpCatCd",
                              },
                            ],
                            staticStyle: { width: "140px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.grpCatCd },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "grpCatCd", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.reqNo,
                                expression: "vo.reqNo",
                              },
                            ],
                            staticStyle: { width: "140px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.reqNo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "reqNo", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeOutCommission1(idx)
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ]),
                      ])
                    }
                  ),
                  0
                ),
              ]),
              _c("p", { staticClass: "comment" }, [
                _vm._v(
                  "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                ),
              ]),
              _c(
                "p",
                {
                  staticClass: "comment",
                  staticStyle: { "font-weight": "bold" },
                },
                [_vm._v("※나라가 KR 에서만 정상 작동합니다.")]
              ),
              _c("table", { staticClass: "tbl_type mt5" }, [
                _vm._m(45),
                _vm._m(46),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.OutCommission1Pop.testUsrNo,
                            expression:
                              "popupParams.OutCommission1Pop.testUsrNo",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.OutCommission1Pop.testUsrNo,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.OutCommission1Pop,
                              "testUsrNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.OutCommission1Pop.testCtrCd,
                            expression:
                              "popupParams.OutCommission1Pop.testCtrCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.OutCommission1Pop.testCtrCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.OutCommission1Pop,
                              "testCtrCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("OutCommission1Pop")
                      },
                    },
                  },
                  [_vm._v("수출 업무 위임장 #1 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v("수출 업무 위임장 #2"),
          ]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(47),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("PORT_CD")]),
                    _c("th", [_vm._v("PARM1")]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addOutCommission2()
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.popupParams.OutCommission2Pop.items,
                    function (vo, idx) {
                      return _c("tr", { key: "OutCommission2Pop_" + idx }, [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.portCd,
                                expression: "vo.portCd",
                              },
                            ],
                            staticStyle: { width: "140px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.portCd },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "portCd", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.parm1,
                                expression: "vo.parm1",
                              },
                            ],
                            staticStyle: { width: "140px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.parm1 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "parm1", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeOutCommission2(idx)
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ]),
                      ])
                    }
                  ),
                  0
                ),
              ]),
              _c("p", { staticClass: "comment" }, [
                _vm._v(
                  "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                ),
              ]),
              _c(
                "p",
                {
                  staticClass: "comment",
                  staticStyle: { "font-weight": "bold" },
                },
                [_vm._v("※나라가 KR 에서만 정상 작동합니다.")]
              ),
              _c("table", { staticClass: "tbl_type mt5" }, [
                _vm._m(48),
                _vm._m(49),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.OutCommission2Pop.testUsrNo,
                            expression:
                              "popupParams.OutCommission2Pop.testUsrNo",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.OutCommission2Pop.testUsrNo,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.OutCommission2Pop,
                              "testUsrNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.OutCommission2Pop.testCtrCd,
                            expression:
                              "popupParams.OutCommission2Pop.testCtrCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.OutCommission2Pop.testCtrCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.OutCommission2Pop,
                              "testCtrCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("OutCommission2Pop")
                      },
                    },
                  },
                  [_vm._v("수출 업무 위임장 #2 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [_vm._v("긴급양하 요청")]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(50),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("PORT_CD")]),
                    _c("th", [_vm._v("PARM1")]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addUrgentGetOff()
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.popupParams.UrgentGetOffPop.items,
                    function (vo, idx) {
                      return _c("tr", { key: "UrgentGetOffPop_" + idx }, [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.portCd,
                                expression: "vo.portCd",
                              },
                            ],
                            staticStyle: { width: "140px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.portCd },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "portCd", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.parm1,
                                expression: "vo.parm1",
                              },
                            ],
                            staticStyle: { width: "140px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.parm1 },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "parm1", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeUrgentGetOff(idx)
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ]),
                      ])
                    }
                  ),
                  0
                ),
              ]),
              _c("p", { staticClass: "comment" }, [
                _vm._v(
                  "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                ),
              ]),
              _c(
                "p",
                {
                  staticClass: "comment",
                  staticStyle: { "font-weight": "bold" },
                },
                [_vm._v("※나라가 KR 에서만 정상 작동합니다.")]
              ),
              _c("table", { staticClass: "tbl_type mt5" }, [
                _vm._m(51),
                _vm._m(52),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.UrgentGetOffPop.testUsrNo,
                            expression: "popupParams.UrgentGetOffPop.testUsrNo",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.UrgentGetOffPop.testUsrNo,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.UrgentGetOffPop,
                              "testUsrNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.UrgentGetOffPop.testCtrCd,
                            expression: "popupParams.UrgentGetOffPop.testCtrCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.UrgentGetOffPop.testCtrCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.UrgentGetOffPop,
                              "testCtrCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("UrgentGetOffPop")
                      },
                    },
                  },
                  [_vm._v("긴급양하 요청 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [_vm._v("Shpping Request")]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(53),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("B/L No.")]),
                    _c("th", [_vm._v("Rider(Y/N)")]),
                    _c("th", [_vm._v("Por Dly(Y/N)")]),
                    _c("th", [_vm._v("No Cnee(Y/N)")]),
                    _c("th", [_vm._v("No Notify(Y/N)")]),
                    _c("th", [_vm._v("ORG_CD")]),
                    _c("th", [_vm._v("LANG_CTR_CD")]),
                    _c("th", [_vm._v("CTR_CUR_CD")]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addShippingRequest()
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.popupParams.ShippingRequestPop.items,
                    function (vo, idx) {
                      return _c("tr", { key: "ShippingRequestPop_" + idx }, [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.blNo,
                                expression: "vo.blNo",
                              },
                            ],
                            staticStyle: { width: "100px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.blNo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "blNo", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.riderYn,
                                expression: "vo.riderYn",
                              },
                            ],
                            staticStyle: { width: "100px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.riderYn },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "riderYn", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.porDlyYn,
                                expression: "vo.porDlyYn",
                              },
                            ],
                            staticStyle: { width: "100px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.porDlyYn },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "porDlyYn", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.noCnee,
                                expression: "vo.noCnee",
                              },
                            ],
                            staticStyle: { width: "100px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.noCnee },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "noCnee", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.noNotify,
                                expression: "vo.noNotify",
                              },
                            ],
                            staticStyle: { width: "100px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.noNotify },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "noNotify", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.orgCd,
                                expression: "vo.orgCd",
                              },
                            ],
                            staticStyle: { width: "100px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.orgCd },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "orgCd", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.langCtrCd,
                                expression: "vo.langCtrCd",
                              },
                            ],
                            staticStyle: { width: "100px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.langCtrCd },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "langCtrCd", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.ctrCurCd,
                                expression: "vo.ctrCurCd",
                              },
                            ],
                            staticStyle: { width: "100px" },
                            attrs: { type: "text" },
                            domProps: { value: vo.ctrCurCd },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "ctrCurCd", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeShippingRequest(idx)
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ]),
                      ])
                    }
                  ),
                  0
                ),
              ]),
              _c("p", { staticClass: "comment" }, [
                _vm._v(
                  "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                ),
              ]),
              _c("table", { staticClass: "tbl_type mt5" }, [
                _vm._m(54),
                _vm._m(55),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.popupParams.ShippingRequestPop.testUserId,
                            expression:
                              "popupParams.ShippingRequestPop.testUserId",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.ShippingRequestPop.testUserId,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.ShippingRequestPop,
                              "testUserId",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.ShippingRequestPop.testCtrCd,
                            expression:
                              "popupParams.ShippingRequestPop.testCtrCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.ShippingRequestPop.testCtrCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.ShippingRequestPop,
                              "testCtrCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.ShippingRequestPop.testAgtCd,
                            expression:
                              "popupParams.ShippingRequestPop.testAgtCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.ShippingRequestPop.testAgtCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.ShippingRequestPop,
                              "testAgtCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.popupParams.ShippingRequestPop.testBkgPlcCd,
                            expression:
                              "popupParams.ShippingRequestPop.testBkgPlcCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value:
                            _vm.popupParams.ShippingRequestPop.testBkgPlcCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.ShippingRequestPop,
                              "testBkgPlcCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("ShippingRequestPop")
                      },
                    },
                  },
                  [_vm._v("Shipping Request 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [_vm._v("Invoice")]),
          _c(
            "ul",
            { staticClass: "ul_box", staticStyle: { "overflow-x": "auto" } },
            [
              _c("li", { staticClass: "fl" }, [
                _c("table", { staticClass: "tbl_type" }, [
                  _vm._m(56),
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("BL_NO")]),
                      _c("th", [_vm._v("PORT_CD")]),
                      _c("th", [_vm._v("PAY_AGTORG_NO")]),
                      _c("th", [_vm._v("CST_NO")]),
                      _c("th", [_vm._v("TO")]),
                      _c("th", [_vm._v("REMARK")]),
                      _c("th", [
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon plus",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addInvoicePop()
                              },
                            },
                          },
                          [_vm._v("plus")]
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(
                      _vm.popupParams.InvoicePop.items,
                      function (vo, idx) {
                        return _c("tr", { key: "DelayNoticeInPop_" + idx }, [
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.blNo,
                                  expression: "vo.blNo",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.blNo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "blNo", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.portCd,
                                  expression: "vo.portCd",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.portCd },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "portCd", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.payAgtorgNo,
                                  expression: "vo.payAgtorgNo",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.payAgtorgNo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    vo,
                                    "payAgtorgNo",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.cstNo,
                                  expression: "vo.cstNo",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.cstNo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "cstNo", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.to,
                                  expression: "vo.to",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.to },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "to", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.remark,
                                  expression: "vo.remark",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.remark },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "remark", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "tbl_icon minus",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.removeInvoicePop(idx)
                                  },
                                },
                              },
                              [_vm._v("minus")]
                            ),
                          ]),
                        ])
                      }
                    ),
                    0
                  ),
                ]),
                _c("p", { staticClass: "comment" }, [
                  _vm._v(
                    "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                  ),
                ]),
                _c(
                  "p",
                  {
                    staticClass: "comment",
                    staticStyle: { "font-weight": "bold" },
                  },
                  [_vm._v("※나라가 ID 에서만 정상 작동합니다.")]
                ),
                _c("table", { staticClass: "tbl_type mt5" }, [
                  _vm._m(57),
                  _vm._m(58),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.popupParams.InvoicePop.testUsrNo,
                              expression: "popupParams.InvoicePop.testUsrNo",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.popupParams.InvoicePop.testUsrNo,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.InvoicePop,
                                "testUsrNo",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.popupParams.InvoicePop.testCtrCd,
                              expression: "popupParams.InvoicePop.testCtrCd",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.popupParams.InvoicePop.testCtrCd,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.InvoicePop,
                                "testCtrCd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.popupParams.InvoicePop.testAgtCd,
                              expression: "popupParams.InvoicePop.testAgtCd",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.popupParams.InvoicePop.testAgtCd,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.InvoicePop,
                                "testAgtCd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.popupParams.InvoicePop.testBkgPlcCd,
                              expression: "popupParams.InvoicePop.testBkgPlcCd",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.popupParams.InvoicePop.testBkgPlcCd,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.InvoicePop,
                                "testBkgPlcCd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "btn_wrap" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("InvoicePop")
                        },
                      },
                    },
                    [_vm._v("InvoicePop 팝업")]
                  ),
                ]),
              ]),
              _c("li", { staticClass: "fr" }),
            ]
          ),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [_vm._v("통합 Invocie")]),
          _c(
            "ul",
            { staticClass: "ul_box", staticStyle: { "overflow-x": "auto" } },
            [
              _c("li", { staticClass: "fl" }, [
                _c("table", { staticClass: "tbl_type" }, [
                  _vm._m(59),
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("B/L No")]),
                      _c("th", [_vm._v("To")]),
                      _c("th", [_vm._v("Remark")]),
                      _c("th", [_vm._v("Filter")]),
                      _vm._m(60),
                      _c("th", [
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon plus",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addCombineInvoice()
                              },
                            },
                          },
                          [_vm._v("plus")]
                        ),
                      ]),
                      _c("th"),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(
                      _vm.popupParams.CombineInvoicePop.items,
                      function (vo, idx) {
                        return _c("tr", { key: "combineInvoicePop_" + idx }, [
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.blNo,
                                  expression: "vo.blNo",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.blNo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "blNo", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.to,
                                  expression: "vo.to",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.to },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "to", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.remark,
                                  expression: "vo.remark",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.remark },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "remark", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.filter,
                                  expression: "vo.filter",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.filter },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "filter", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.esign,
                                  expression: "vo.esign",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.esign },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "esign", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c(
                            "td",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "tbl_icon minus",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.removeCombineInvoice(idx)
                                    },
                                  },
                                },
                                [_vm._v("minus")]
                              ),
                            ]
                          ),
                          _c("td"),
                        ])
                      }
                    ),
                    0
                  ),
                ]),
                _c("p", { staticClass: "comment" }, [
                  _vm._v(
                    "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                  ),
                ]),
                _vm._m(61),
                _c("table", { staticClass: "tbl_type mt5" }, [
                  _vm._m(62),
                  _vm._m(63),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.popupParams.CombineInvoicePop.testUserNo,
                              expression:
                                "popupParams.CombineInvoicePop.testUserNo",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.popupParams.CombineInvoicePop.testUserNo,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.CombineInvoicePop,
                                "testUserNo",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.popupParams.CombineInvoicePop.testCtrCd,
                              expression:
                                "popupParams.CombineInvoicePop.testCtrCd",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.popupParams.CombineInvoicePop.testCtrCd,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.CombineInvoicePop,
                                "testCtrCd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.popupParams.CombineInvoicePop.testAgtCd,
                              expression:
                                "popupParams.CombineInvoicePop.testAgtCd",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.popupParams.CombineInvoicePop.testAgtCd,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.CombineInvoicePop,
                                "testAgtCd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value:
                                _vm.popupParams.CombineInvoicePop.testBkgPlcCd,
                              expression:
                                "popupParams.CombineInvoicePop.testBkgPlcCd",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value:
                              _vm.popupParams.CombineInvoicePop.testBkgPlcCd,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.CombineInvoicePop,
                                "testBkgPlcCd",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td"),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "btn_wrap" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("CombineInvoicePop")
                        },
                      },
                    },
                    [_vm._v("Combine Invoice 팝업")]
                  ),
                ]),
              ]),
              _c("li", { staticClass: "fr" }),
            ]
          ),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [_vm._v("DEMDET Invocie")]),
          _c(
            "ul",
            { staticClass: "ul_box", staticStyle: { "overflow-x": "auto" } },
            [
              _c("li", { staticClass: "fl" }, [
                _c("table", { staticClass: "tbl_type" }, [
                  _vm._m(64),
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("B/L No")]),
                      _c("th", [_vm._v("DEM/DET")]),
                      _c("th", [_vm._v("Out/In")]),
                      _c("th", [
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon plus",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addDemDetInvoice()
                              },
                            },
                          },
                          [_vm._v("plus")]
                        ),
                      ]),
                      _c("th"),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(
                      _vm.popupParams.DemInvoiceMultiPop.items,
                      function (vo, idx) {
                        return _c("tr", { key: "combineInvoicePop_" + idx }, [
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.blNo,
                                  expression: "vo.blNo",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.blNo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "blNo", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            idx === 0
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.popupParams.DemInvoiceMultiPop
                                            .demDetCatCd,
                                        expression:
                                          "popupParams.DemInvoiceMultiPop.demDetCatCd",
                                      },
                                    ],
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.popupParams.DemInvoiceMultiPop,
                                          "demDetCatCd",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "01" } }, [
                                      _vm._v("DEM"),
                                    ]),
                                    _c("option", { attrs: { value: "02" } }, [
                                      _vm._v("DET"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c("td", [
                            idx === 0
                              ? _c(
                                  "select",
                                  {
                                    directives: [
                                      {
                                        name: "model",
                                        rawName: "v-model",
                                        value:
                                          _vm.popupParams.DemInvoiceMultiPop
                                            .eiCatCd,
                                        expression:
                                          "popupParams.DemInvoiceMultiPop.eiCatCd",
                                      },
                                    ],
                                    on: {
                                      change: function ($event) {
                                        var $$selectedVal =
                                          Array.prototype.filter
                                            .call(
                                              $event.target.options,
                                              function (o) {
                                                return o.selected
                                              }
                                            )
                                            .map(function (o) {
                                              var val =
                                                "_value" in o
                                                  ? o._value
                                                  : o.value
                                              return val
                                            })
                                        _vm.$set(
                                          _vm.popupParams.DemInvoiceMultiPop,
                                          "eiCatCd",
                                          $event.target.multiple
                                            ? $$selectedVal
                                            : $$selectedVal[0]
                                        )
                                      },
                                    },
                                  },
                                  [
                                    _c("option", { attrs: { value: "O" } }, [
                                      _vm._v("EXPORT"),
                                    ]),
                                    _c("option", { attrs: { value: "I" } }, [
                                      _vm._v("IMPORT"),
                                    ]),
                                  ]
                                )
                              : _vm._e(),
                          ]),
                          _c(
                            "td",
                            { staticStyle: { "text-align": "center" } },
                            [
                              _c(
                                "button",
                                {
                                  staticClass: "tbl_icon minus",
                                  attrs: { type: "button" },
                                  on: {
                                    click: function ($event) {
                                      $event.preventDefault()
                                      return _vm.removeDemDetInvoice(idx)
                                    },
                                  },
                                },
                                [_vm._v("minus")]
                              ),
                            ]
                          ),
                          _c("td"),
                        ])
                      }
                    ),
                    0
                  ),
                ]),
                _c("div", { staticClass: "btn_wrap" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("DemInvoiceMultiPop")
                        },
                      },
                    },
                    [_vm._v("Combine Invoice 팝업")]
                  ),
                ]),
              ]),
              _c("li", { staticClass: "fr" }),
            ]
          ),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v("Schedule Change Notice (선명/항차)"),
          ]),
          _c(
            "ul",
            { staticClass: "ul_box", staticStyle: { "overflow-x": "auto" } },
            [
              _c("li", { staticClass: "fl" }, [
                _c("table", { staticClass: "tbl_type" }, [
                  _vm._m(65),
                  _c("thead", [
                    _c("tr", [
                      _c("th", [_vm._v("VSL_CD")]),
                      _c("th", [_vm._v("VOY_NO")]),
                      _c("th", [_vm._v("POL_PORT")]),
                      _c("th", [_vm._v("POD_PORT")]),
                      _c("th", [_vm._v("GUBUN")]),
                      _c("th", [_vm._v("DOC_NO")]),
                      _c("th", [_vm._v("EDIT_TABLE")]),
                      _c("th", [
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon plus",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.addChangeNotice()
                              },
                            },
                          },
                          [_vm._v("plus")]
                        ),
                      ]),
                    ]),
                  ]),
                  _c(
                    "tbody",
                    _vm._l(
                      _vm.popupParams.ChangeNoticePop.items,
                      function (vo, idx) {
                        return _c("tr", { key: "changeNoticePop_" + idx }, [
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.vslCd,
                                  expression: "vo.vslCd",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.vslCd },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "vslCd", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.voyNo,
                                  expression: "vo.voyNo",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.voyNo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "voyNo", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.polPortCd,
                                  expression: "vo.polPortCd",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.polPortCd },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "polPortCd", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.podPortCd,
                                  expression: "vo.podPortCd",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.podPortCd },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "podPortCd", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.gubun,
                                  expression: "vo.gubun",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.gubun },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "gubun", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.docNo,
                                  expression: "vo.docNo",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.docNo },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "docNo", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: vo.editTable,
                                  expression: "vo.editTable",
                                },
                              ],
                              staticStyle: { width: "100px" },
                              attrs: { type: "text" },
                              domProps: { value: vo.editTable },
                              on: {
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(vo, "editTable", $event.target.value)
                                },
                              },
                            }),
                          ]),
                          _c("td", [
                            _c(
                              "button",
                              {
                                staticClass: "tbl_icon minus",
                                attrs: { type: "button" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.removeChangeNotice(idx)
                                  },
                                },
                              },
                              [_vm._v("minus")]
                            ),
                          ]),
                        ])
                      }
                    ),
                    0
                  ),
                ]),
                _c("p", { staticClass: "comment" }, [
                  _vm._v(
                    "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                  ),
                ]),
                _c(
                  "p",
                  {
                    staticClass: "comment",
                    staticStyle: { "font-weight": "bold" },
                  },
                  [_vm._v("※나라가 KR 에서만 정상 작동합니다.")]
                ),
                _c("table", { staticClass: "tbl_type mt5" }, [
                  _vm._m(66),
                  _vm._m(67),
                  _c("tbody", [
                    _c("tr", [
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.popupParams.ChangeNoticePop.testUsrNo,
                              expression:
                                "popupParams.ChangeNoticePop.testUsrNo",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: {
                            value: _vm.popupParams.ChangeNoticePop.testUsrNo,
                          },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(
                                _vm.popupParams.ChangeNoticePop,
                                "testUsrNo",
                                $event.target.value
                              )
                            },
                          },
                        }),
                      ]),
                      _c("td"),
                      _c("td"),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "btn_wrap" }, [
                  _c(
                    "button",
                    {
                      staticClass: "button blue lg mt10 layer_open",
                      on: {
                        click: function ($event) {
                          return _vm.openPopup("ChangeNoticePop")
                        },
                      },
                    },
                    [_vm._v("InvoicePop 팝업")]
                  ),
                ]),
              ]),
              _c("li", { staticClass: "fr" }),
            ]
          ),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [_vm._v("Surrender Notice")]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(68),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("B/L No.")]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addSurrenderNotice()
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                    _c("th"),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.popupParams.SurrenderNoticePop.items,
                    function (vo, idx) {
                      return _c("tr", { key: "SurrenderNoticePop_" + idx }, [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.blNo,
                                expression: "vo.blNo",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: vo.blNo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "blNo", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeSurrenderNotice(idx)
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ]),
                        _c("td"),
                      ])
                    }
                  ),
                  0
                ),
              ]),
              _c("p", { staticClass: "comment" }, [
                _vm._v(
                  "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                ),
              ]),
              _c("table", { staticClass: "tbl_type mt5" }, [
                _vm._m(69),
                _vm._m(70),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.SurrenderNoticePop.testUsrNo,
                            expression:
                              "popupParams.SurrenderNoticePop.testUsrNo",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.SurrenderNoticePop.testUsrNo,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.SurrenderNoticePop,
                              "testUsrNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td"),
                    _c("td"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("SurrenderNoticePop")
                      },
                    },
                  },
                  [_vm._v("Surrender Notice 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
        _c("div", { staticClass: "content_box mt5" }, [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v("Seawaybill Notice"),
          ]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(71),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("B/L No.")]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addSeawaybillNotice()
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                    _c("th"),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.popupParams.SeawaybillNoticePop.items,
                    function (vo, idx) {
                      return _c("tr", { key: "SeawaybillNoticePop_" + idx }, [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.blNo,
                                expression: "vo.blNo",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: vo.blNo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "blNo", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", { staticStyle: { "text-align": "center" } }, [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeSeawaybillNotice(idx)
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ]),
                        _c("td"),
                      ])
                    }
                  ),
                  0
                ),
              ]),
              _c("p", { staticClass: "comment" }, [
                _vm._v(
                  "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                ),
              ]),
              _c("table", { staticClass: "tbl_type mt5" }, [
                _vm._m(72),
                _vm._m(73),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value:
                              _vm.popupParams.SeawaybillNoticePop.testUsrNo,
                            expression:
                              "popupParams.SeawaybillNoticePop.testUsrNo",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.SeawaybillNoticePop.testUsrNo,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.SeawaybillNoticePop,
                              "testUsrNo",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td"),
                    _c("td"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("SeawaybillNoticePop")
                      },
                    },
                  },
                  [_vm._v("Surrender Notice 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "200" } }),
      _c("col", { attrs: { width: "200" } }),
      _c("col", { attrs: { width: "80" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("USR_NO")]),
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
        _c("th"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "200" } }),
      _c("col", { attrs: { width: "200" } }),
      _c("col", { attrs: { width: "80" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("USR_NO")]),
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("AGT_CD")]),
        _c("th", [
          _vm._v("BKG_PLC_CD"),
          _c("span", { staticStyle: { color: "red", "font-weight": "bold" } }, [
            _vm._v("(ID)"),
          ]),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "140" } }),
      _c("col", { attrs: { width: "140" } }),
      _c("col", { attrs: { width: "140" } }),
      _c("col", { attrs: { width: "140" } }),
      _c("col", { attrs: { width: "140" } }),
      _c("col", { attrs: { width: "140" } }),
      _c("col", { attrs: { width: "80" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("AGT_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
        _c("th"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "200" } }),
      _c("col", { attrs: { width: "80" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
        _c("th"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "200" } }),
      _c("col", { attrs: { width: "100" } }),
      _c("col", { attrs: { width: "100" } }),
      _c("col", { attrs: { width: "100" } }),
      _c("col", { attrs: { width: "80" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("UsER_ID")]),
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
        _c("th"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "200" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "80" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("USR_NO")]),
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
        _c("th"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "80px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "80px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "80px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("USR_NO")]),
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "80px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("USR_NO")]),
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("AGT_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "80px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("USR_NO")]),
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("AGT_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "80px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("USR_NO")]),
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("AGT_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "100px" } }),
      _c("col", { attrs: { width: "80px" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("USR_NO")]),
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "100px" } }),
      _c("col", { attrs: { width: "80px" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("USR_NO")]),
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "80px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180px" } }),
      _c("col", { attrs: { width: "180px" } }),
      _c("col", { attrs: { width: "180px" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("USR_NO")]),
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("AGT_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "80px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180px" } }),
      _c("col", { attrs: { width: "180px" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("USR_NO")]),
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "80px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180px" } }),
      _c("col", { attrs: { width: "180px" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("USR_NO")]),
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "80px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180px" } }),
      _c("col", { attrs: { width: "180px" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("USR_NO")]),
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "80px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180px" } }),
      _c("col", { attrs: { width: "180px" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("USR_NO")]),
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "80" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("USER_ID")]),
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("AGT_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "80px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("USR_NO")]),
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("AGT_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "100" } }),
      _c("col", { attrs: { width: "100" } }),
      _c("col", { attrs: { width: "100" } }),
      _c("col", { attrs: { width: "100" } }),
      _c("col", { attrs: { width: "100" } }),
      _c("col", { attrs: { width: "100" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("Esign "),
      _c("span", { staticStyle: { color: "red", "font-weight": "bold" } }, [
        _vm._v("(PH)"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "p",
      { staticClass: "comment", staticStyle: { "font-weight": "bold" } },
      [
        _vm._v(
          " ※나라가 CN인 경우에는 무조건 Proforma Freight Invoice만 출력되기 때문에 Freight Invoice "
        ),
        _c("br"),
        _vm._v("    섞여 있을 시 정상 노출 되지 않을 수 있다. "),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("USER_NO")]),
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("AGT_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
        _c("th"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "100" } }),
      _c("col", { attrs: { width: "100" } }),
      _c("col", { attrs: { width: "100" } }),
      _c("col", { attrs: { width: "100" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "*" } }),
      _c("col", { attrs: { width: "80px" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("USR_NO")]),
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "200" } }),
      _c("col", { attrs: { width: "80" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [_c("th", [_vm._v("USR_NO")]), _c("th"), _c("th")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "200" } }),
      _c("col", { attrs: { width: "80" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [_c("th", [_vm._v("USR_NO")]), _c("th"), _c("th")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }