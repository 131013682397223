var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "select",
    {
      directives: [
        {
          name: "model",
          rawName: "v-model",
          value: _vm.value,
          expression: "value",
        },
      ],
      staticClass: "custom-select",
      attrs: { id: _vm.input_id },
      on: {
        input: function ($event) {
          return _vm.updateValue($event.target.value)
        },
        change: function ($event) {
          var $$selectedVal = Array.prototype.filter
            .call($event.target.options, function (o) {
              return o.selected
            })
            .map(function (o) {
              var val = "_value" in o ? o._value : o.value
              return val
            })
          _vm.value = $event.target.multiple ? $$selectedVal : $$selectedVal[0]
        },
      },
    },
    _vm._l(_vm.items, function (item, index) {
      return _c("option", { key: item, domProps: { value: index } }, [
        _vm._v(_vm._s(item)),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }