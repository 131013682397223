<template>
  <div class="content resize"><!-- content : 화면 리사이징 되는 화면 resize  -->
    <Breadcrumbs />
    <h1 class="page_title">스케줄</h1>
    <div class="tab"><!-- tab -->
      <ul>
        <li class="on"><a href="#">구간</a></li>
        <li><a href="#">선박</a></li>
        <li><a href="#">기간</a></li>
      </ul>
    </div><!-- tab // -->
    <div class="content_box beforetab"><!-- content_box : 탭있을 경우 beforetab  추가 -->
      <div class="border_bottom_gray_box">
        <table class="tbl_search">
          <colgroup>
            <col width="90px"><col><col><col width="90px"><col><col>
          </colgroup>
          <tbody>
          <tr>
            <th>출발</th>
            <td class="full" style="padding-right:2px;">
              <select name="" id="">
                <option value="">Korea</option>
                <option value="">Korea</option>
              </select>
              <!--<input type="text" id="" name="" style="width:200px;">-->
            </td>
            <td class="full" style="padding-left:0;">
              <select name="" id="">
                <option value="">Pohang</option>
                <option value="">Pohang</option>
              </select>
            </td>
            <th>도착</th>
            <td class="full" style="padding-right:2px;">
              <select name="" id="">
                <option value="">Hong Kong</option>
                <option value="">Hong Kong</option>
              </select>
            </td>
            <td class="full" style="padding-left:0;">
              <select name="" id="">
                <option value="">Hong Kong</option>
                <option value="">Hong Kong</option>
              </select>
            </td>
          </tr>
          <tr>
            <th>조회년월</th>
            <td colspan="5">
              <span>
                <select name="" id="" class="select_year">
                  <option value="">2021</option>
                  <option value="">2022</option>
                </select>
              </span>
              <span style="margin-left:-1px;">
                <select name="" id="" class="select_month">
                  <option value="">7</option>
                  <option value="">8</option>
                </select>
              </span>
              <span class="ml15">
                <input type="radio" id="trad1" name="trad" checked="checked" />
                <label for="trad1" class="mr10"><span></span>수출 & 출항일</label>
                <input type="radio" id="trad2" name="trad" />
                <label for="trad2" class="mr10"><span></span>수입 & 입항일</label>
              </span>
            </td>

          </tr>
          </tbody>
        </table>
      </div>

      <div class="border_bottom_gray_box">
        <table class="tbl_search">
          <colgroup>
            <col width="90px"><col><col width="90px"><col>
          </colgroup>
          <tbody>
          <tr>
            <th>출발 터미널</th>
            <td>
              <input type="checkbox" id="c4" name="cc" checked="checked" disabled="disabled" />
              <label for="c4"><span></span>KPOL (Pohang Yeongil  New Port Terminal)</label>
            </td>
            <th>도착 터미널</th>
            <td>
              <input type="checkbox" id="c4" name="cc" checked="checked" disabled="disabled" />
              <label for="c4"><span></span>HIT (Hongkong International Terminal)</label>
            </td>
          </tr>
          <tr>
            <th>Priority</th>
            <td>
              <input type="checkbox" id="c4" name="cc" checked="checked" disabled="disabled" />
              <label for="c4"><span></span>T/S</label>
            </td>
            <th>Transit</th>
            <td>
              <input type="text" id="" name="" style="width:80px;">
              <span>~</span>
              <input type="text" id="" name="" style="width:80px;">
              Days
            </td>
          </tr>
          </tbody>
        </table>
      </div>

      <div class="text_right mt20">
        <a class="button blue sh" href="#">검색</a>
      </div>

    </div><!-- content_box // -->

    <div class="content_box mt10"><!-- content_box  -->

      <div class="wrap_schedule"><!-- wrap_schedule -->

        <div class="schedule_top"><!-- schedule_top -->

          <div class="sail_area">
            <p class="port">
              <span>POHANG</span>
              <span>HONE KONG</span>
            </p>
            <ul>
              <li><span>월</span> <span class="num">1</span></li>
              <li><span>화</span> <span class="num">1</span></li>
              <li><span>수</span> <span class="num">0</span></li>
              <li><span>목</span> <span class="num">0</span></li>
              <li><span>금</span> <span class="num">0</span></li>
              <li><span>토</span> <span class="num">0</span></li>
              <li><span>일</span> <span class="num">10</span></li>

              <li class="week"><span class="week">주</span> <span class="num">12</span> <span>항차</span></li>
            </ul>
          </div>

          <div class="date_area"><!-- date_area -->
            <div class="mark">
              <span class="finish">마감</span>
              <span class="possible">가능</span>
              <span class="after">60일 이후</span>
            </div>

            <div class="date">
              <span><a href="#" class="prev">prev</a></span>
              <span class="year">2021</span>
              <span class="month">07</span>
              <span><a href="#" class="next">next</a></span>
            </div>

            <div class="memu">
              <a href="#" class="month"></a>
              <a href="#" class="list"></a>
            </div>
          </div><!-- date_area // -->
        </div><!-- schedule_top // -->

        <table class="tbl_schedule">
          <colgroup>
            <col span="7" width="14.2%">
          </colgroup>
          <thead>
          <tr>
            <th>일</th>
            <th>월</th>
            <th>화</th>
            <th>수</th>
            <th>목</th>
            <th>금</th>
            <th>토</th>
          </tr>
          </thead>
          <tbody>
          <tr>
            <td>

            </td>
            <td>

            </td>
            <td>

            </td>
            <td>

            </td>
            <td>
              <span>1</span>
              <p class="finish"><a href="#">HYUNDAU UNITY</a></p>
              <p class="finish"><a href="#">KMTC PUSAN</a></p>
            </td>
            <td>
              <span>2</span>
              <p class="finish"><a href="#">HANSA DUBURG</a></p>
              <p class="finish"><a href="#">KMTC SINGAPORE</a></p>
              <p class="finish"><a href="#">KMTC TOKYO</a></p>
            </td>
            <td>
              <span>3</span>
              <p class="finish"><a href="#">HANSA DUBURG</a></p>
              <p class="finish"><a href="#">KMTC SINGAPORE</a></p>
            </td>
          </tr>
          <tr>
            <td>
              <span>4</span>
            </td>
            <td>
              <span>5</span>
              <p class="finish"><a href="#">ALABAMA</a></p>
            </td>
            <td>
              <span>6</span>
              <p class="finish"><a href="#">HANSA</a></p>
              <p class="finish"><a href="#">FLENSBURG</a></p>
            </td>
            <td>
              <span>7</span>
            </td>
            <td>
              <span>8</span>
            </td>
            <td>
              <span>9</span>
              <p class="finish"><a href="#">STARSHIP</a></p>
              <p class="finish"><a href="#">SAWASDEE PACIFIC</a></p>
            </td>
            <td>
              <span>10</span>
            </td>
          </tr>

          <tr>
            <td>
              <span>11</span>
              <p class="finish"><a href="#">SKY ORION</a></p>
            </td>
            <td>
              <span>12</span>
              <p class="finish"><a href="#">KMTX KEELUNG</a></p>
            </td>
            <td>
              <span>13</span>
              <p class="finish"><a href="#">PANCON SUCCESS</a></p>
              <p class="finish"><a href="#">STARTSHOP AQUILA</a></p>
              <p class="finish"><a href="#">HANSA FLENSBURG</a></p>
            </td>
            <td>
              <span>14</span>
              <p class="finish"><a href="#">SKY SUNSHINE</a></p>
            </td>
            <td>
              <span>15</span>
              <p class="possible"><a href="#">SKY SUNSHINE</a></p>
            </td>
            <td>
              <span>16</span>
              <p class="finish"><a href="#">KMTC JAKARTA</a></p>
              <p class="finish"><a href="#">KMTC OSAKA</a></p>
              <p class="finish"><a href="#">BELMONTE<br/>EXPRESS</a></p>
            </td>
            <td>
              <span>17</span>
            </td>
          </tr>

          <tr>
            <td>
              <span>18</span>
              <p class="possible"><a href="#">KMTC JAKARTA</a></p>
              <p class="possible"><a href="#">KMTC OSAKA</a></p>
            </td>
            <td>
              <span>19</span>

            </td>
            <td>
              <span>20</span>
              <p class="possible"><a href="#">KMTC JAKARTA</a></p>
              <p class="possible"><a href="#">KMTC OSAKA</a></p>
            </td>
            <td>
              <span>21</span>
              <p class="possible"><a href="#">KMTC JAKARTA</a></p>
              <p class="possible"><a href="#">KMTC OSAKA</a></p>
              <p class="possible"><a href="#">BELMONTE<br/>EXPRESS</a></p>
            </td>
            <td>
              <span>22</span>

            </td>
            <td>
              <span>23</span>
            </td>
            <td>
              <span>24</span>
              <p class="finish"><a href="#">KMTC JAKARTA</a></p>
              <p class="possible"><a href="#">KMTC OSAKA</a></p>
            </td>
          </tr>

          <tr>
            <td>
              <span>25</span>
              <p class="possible"><a href="#">KMTC JAKARTA</a></p>
              <p class="possible"><a href="#">KMTC BUSAN</a></p>
              <p class="possible"><a href="#">KMTC OSAKA</a></p>
            </td>
            <td>
              <span>26</span>
              <p class="possible"><a href="#">STARSHIP URSA</a></p>
            </td>
            <td>
              <span>27</span>
              <p class="possible"><a href="#">KMTC JAKARTA</a></p>
              <p class="possible"><a href="#">KMTC OSAKA</a></p>
            </td>
            <td>
              <span>27</span>
              <p class="possible"><a href="#">STAR EXPLOPER</a></p>
              <p class="possible"><a href="#">SAWASDEE PACIFIC</a></p>
            </td>
            <td>
              <span>29</span>
              <p class="possible text_truncate" title="HONGKONG BRID BRID BRID"><a href="#">HONGKONG BRID BRID BRID</a></p> <!-- 말줄임 표시일 경우 사용 : text_truncate -->
            </td>
            <td>
              <span>30</span>
              <p class="after"><a href="#">HANSA DUBURG</a></p>
              <p class="after"><a href="#">KMTC TOKYO</a></p>
            </td>
            <td>
              <span>31</span>
              <p class="after"><a href="#">STARSHIP LEO</a></p>
              <p class="after"><a href="#">SKY ORION</a></p>
            </td>
          </tr>

          </tbody>
        </table>

      </div><!-- wrap_schedule // -->

    </div><!-- content_box // -->
  </div><!-- content // -->
</template>

<script>

export default {
  name: 'SampleSchedule',
  components: {
  },
  methods: {
  }
}
</script>

<style scoped>

</style>
