var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "content resize" }, [
    _c("br"),
    _c("h1", { staticClass: "page_title" }, [_vm._v("PDF Viewer")]),
    _c("div", { staticClass: "mt-5" }, [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-md-4" }, [
          _c("div", { staticClass: "row" }, [
            _c(
              "div",
              { staticClass: "col-md-4 text-left" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { type: "button", pill: "", variant: "secondary" },
                    on: {
                      click: function ($event) {
                        return _vm.movePg("prev")
                      },
                    },
                  },
                  [_vm._v("이전")]
                ),
              ],
              1
            ),
            _c("div", { staticClass: "col-md-4 text-center" }, [
              _vm._v(
                " " +
                  _vm._s(_vm.currentPage) +
                  " / " +
                  _vm._s(_vm.pageCount) +
                  " "
              ),
            ]),
            _c(
              "div",
              { staticClass: "col-md-4 text-right" },
              [
                _c(
                  "b-button",
                  {
                    attrs: { type: "button", pill: "", variant: "secondary" },
                    on: {
                      click: function ($event) {
                        return _vm.movePg("next")
                      },
                    },
                  },
                  [_vm._v("다음")]
                ),
              ],
              1
            ),
          ]),
        ]),
        _c(
          "div",
          { staticClass: "col-md-4" },
          [
            _c(
              "b-select",
              {
                on: {
                  change: function ($event) {
                    return _vm.changePdf()
                  },
                },
                model: {
                  value: _vm.pdfUrl,
                  callback: function ($$v) {
                    _vm.pdfUrl = $$v
                  },
                  expression: "pdfUrl",
                },
              },
              _vm._l(_vm.arrPdf, function (u, idx) {
                return _c("option", { key: idx, domProps: { value: u } }, [
                  _vm._v(_vm._s(u)),
                ])
              }),
              0
            ),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "col-md-4 text-right" },
          [
            _c(
              "b-button",
              {
                attrs: { type: "button", variant: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.$refs.pdfViewer.print()
                  },
                },
              },
              [_vm._v("Print1")]
            ),
            _c(
              "b-button",
              {
                attrs: { type: "button", variant: "primary" },
                on: {
                  click: function ($event) {
                    return _vm.pdfPrint()
                  },
                },
              },
              [_vm._v("Print2")]
            ),
            _c("a", { attrs: { target: "_blank", href: _vm.pdfUrl } }, [
              _vm._v("Print2"),
            ]),
          ],
          1
        ),
      ]),
      _c(
        "div",
        {
          staticClass: "mt-5",
          staticStyle: {
            width: "1250px",
            height: "1770px",
            border: "5px solid #0000",
            "background-color": "pink",
          },
        },
        [
          _c("pdf", {
            ref: "pdfViewer",
            attrs: { src: _vm.pdfUrl, page: _vm.pg },
            on: {
              "num-pages": function ($event) {
                _vm.pageCount = $event
              },
              "page-loaded": function ($event) {
                _vm.currentPage = $event
              },
            },
          }),
        ],
        1
      ),
    ]),
    _c("div", { staticStyle: { height: "20px", "background-color": "red" } }, [
      _vm._v(" "),
    ]),
    _c(
      "div",
      { staticStyle: { height: "20px", "background-color": "orange" } },
      [_vm._v(" ")]
    ),
    _c(
      "div",
      { staticStyle: { height: "20px", "background-color": "yellow" } },
      [_vm._v(" ")]
    ),
    _c(
      "div",
      { staticStyle: { height: "20px", "background-color": "green" } },
      [_vm._v(" ")]
    ),
    _c("div", { staticStyle: { height: "20px", "background-color": "blue" } }, [
      _vm._v(" "),
    ]),
    _c(
      "div",
      { staticStyle: { height: "20px", "background-color": "indigo" } },
      [_vm._v(" ")]
    ),
    _c(
      "div",
      { staticStyle: { height: "20px", "background-color": "purple" } },
      [_vm._v(" ")]
    ),
    _c(
      "div",
      { staticStyle: { height: "20px", "background-color": "white" } },
      [_vm._v(" ")]
    ),
    _c("div", { staticStyle: { height: "20px", "background-color": "red" } }, [
      _vm._v(" "),
    ]),
    _c(
      "div",
      { staticStyle: { height: "20px", "background-color": "orange" } },
      [_vm._v(" ")]
    ),
    _c(
      "div",
      { staticStyle: { height: "20px", "background-color": "yellow" } },
      [_vm._v(" ")]
    ),
    _c(
      "div",
      { staticStyle: { height: "20px", "background-color": "green" } },
      [_vm._v(" ")]
    ),
    _c("div", { staticStyle: { height: "20px", "background-color": "blue" } }, [
      _vm._v(" "),
    ]),
    _c(
      "div",
      { staticStyle: { height: "20px", "background-color": "indigo" } },
      [_vm._v(" ")]
    ),
    _c(
      "div",
      { staticStyle: { height: "20px", "background-color": "purple" } },
      [_vm._v(" ")]
    ),
    _c("iframe", {
      staticStyle: { display: "block", width: "100%", height: "100%" },
      attrs: { id: "iframe1", src: _vm.pdfUrl, frameborder: "0" },
    }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }