<template>
  <div class="container_ekmtc">
    <div class="content">
      <h1 class="page_title">ITEXT 팝업 리스트</h1>
      <win-layer-pop class="sample_popup_list">
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          :parent-info="parentInfo"
        />
      </win-layer-pop>
      <!--[S] B/L Copy -->
      <div class="content_box">
        <h1 class="page_title">B/L Copy</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="200">
                <col width="80">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>B/L No.</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addBlCopy()">plus</button></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.BlCopyItextPop.items" :key="'BlCopyItextPop_' + idx">
                  <td><input type="text" v-model="vo.blNo" /></td>
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeBlCopy(idx)">minus</button></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180">
                <col width="180">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USER_ID</th>
                  <th>CTR_CD</th>
                  <th>BKG_PLC_CD</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.BlCopyItextPop.testUserId" /></td>
                  <td><input type="text" v-model="popupParams.BlCopyItextPop.testCtrCd" /></td>
                  <td><input type="text" v-model="popupParams.BlCopyItextPop.testBkgPlcCd" /></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('BlCopyItextPop')">B/L Copy 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E] B/L Copy -->
      <!--[S] DG Declaration Download -->
      <div class="content_box">
        <h1 class="page_title">DG Declaration Download</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="200">
                <col width="80">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>Booking No.</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addDg()">plus</button></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.DgItextPop.items" :key="'DgItextPop_' + idx">
                  <td><input type="text" v-model="vo.bkgNo" /></td>
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeDg(idx)">minus</button></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('DgItextPop')">DG Declaration Download 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E] DG Declaration Download -->
      <!--[S] Drop Off Charge -->
      <div class="content_box">
        <h1 class="page_title">Drop Off Charge</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="200">
                <col width="80">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>Req Rno.</th>
                  <th>
                    <!-- <button type="button" class="tbl_icon plus" @click.prevent="addDrop()">plus</button> -->
                  </th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.DropItextPop.reqRno" /></td>
                  <td>
                    <!-- <button type="button" class="tbl_icon minus" @click.prevent="removeDrop(idx)">minus</button> -->
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('DropItextPop')">Drop Off Charge 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E] Drop Off Charge -->
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'

export default {
    name: 'SamplePopupItextList',
    components: {
      BlCopyItextPop: () => import('@/pages/pdf/popup/BlCopyItextPop'),
      DgItextPop: () => import('@/pages/pdf/popup/DgItextPop'),
      DropItextPop: () => import('@/pages/pdf/popup/DropItextPop'),
      WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop')
    },
    data () {
      return {
        parentInfo: {},
        customComponent: null,
        popupParams: {
          BlCopyItextPop: {
            testCtrCd: '',
            testBkgPlcCd: '',
            testUserId: '',
            items: [
              {
                blNo: 'PUSE025712',
                type: 'SR'
              }
            ]
          },
          DgItextPop: {
            testCtrCd: '',
            testBkgPlcCd: '',
            testUserId: '',
            items: [
              {
                bkgNo: 'KR0572706'
              },
              {
                bkgNo: 'KR0573486'
              }
            ]
          },
          DropItextPop: {
            testCtrCd: '',
            testBkgPlcCd: '',
            testUserId: '',
            reqRno: 'SEK005|0000000001152'
          }
        }
      }
    },
    computed: {
      ...rootComputed
    },
    methods: {
      addBlCopy () {
        this.popupParams.BlCopyItextPop.items.push({
          blNo: '',
          type: 'SR'
        })
      },
      removeBlCopy (idx) {
        this.popupParams.BlCopyItextPop.items.splice(idx, 1)
        if (this.popupParams.BlCopyItextPop.items.length === 0) {
          this.addBlCopy()
        }
      },
      addDg () {
        this.popupParams.DgItextPop.items.push({
          bkgNo: ''
        })
      },
      removeDg (idx) {
        this.popupParams.DgItextPop.items.splice(idx, 1)
        if (this.popupParams.DgItextPop.items.length === 0) {
          this.addDg()
        }
      },
      addDrop () {
        this.popupParams.DropItextPop.items.push({
          bkgNo: ''
        })
      },
      removeDrop (idx) {
        this.popupParams.DropItextPop.items.splice(idx, 1)
        if (this.popupParams.DropItextPop.items.length === 0) {
          this.addDrop()
        }
      },
      openPopup (compNm) {
        if (this.customComponent !== null) {
          this.customComponent = null
          this.$ekmtcCommon.layerClose()
        }

        this.customComponent = compNm
        this.parentInfo = this.popupParams[compNm]
        this.$ekmtcCommon.layerOpen('.sample_popup_list')
      },
      closePopup (obj) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.sample_popup_list')
      }
    }
}
</script>

<style>
  .temp_name {
    font-weight: bold;
    font-size: 1.1em;
  }
  .tbl_type,.tbl_type th,.tbl_type td { border:0;}
  .tbl_type { width:100%; border-bottom:1px solid #e1e3eb; font-family:'돋움',dotum; font-size:12px; text-align:center; border-collapse:collapse }
  .tbl_type caption { display:none }
  .tbl_type tfoot { background-color:#f5f7f9; font-weight:bold }
  .tbl_type th { padding:7px 0 4px; border-top:1px solid #e1e3eb; border-right:1px solid #e1e3eb; border-left:1px solid #e1e3eb; background-color:#f6f9ff; color:#075bb9; font-family:'돋움',dotum; font-size:12px; font-weight:bold; }

  .tbl_type td { padding:3px 10px 2px; border:1px solid #e1e3eb; color:#4c4c4c; text-align:center; }
  .tbl_type td.ranking { font-weight:bold }
  .tbl_type td a { color:#F2084F; }
  .tbl_type td span.keep::after   { content: '유지';  color:#fff; background-color:#FFD168; }
  .tbl_type td span.change::after { content: '변경';  color:#fff; background-color:#73BDEE; }
  .tbl_type td span.new::after    { content: '신규';  color:#fff; background-color:#ABF3B3; }
  .tbl_type td span.yellow::after { margin-left:5px; width:45px; letter-spacing:-1px; content: 'SB없이'; color:#777; background-color:#F9F900; }

  .tbl_type td.ok span { width:20px; }
  .tbl_type td.ok span.ok::after   { content: '○';  color:#F1084F; width:20px; text-align:center; background-color:#fff; }
  .tbl_type td.del a { text-decoration: line-through; }
  .tbl_type td input.input_txt { width: 100px; float:right; position: relative;}
  .tbl_type td span.txt_fr { width: 100px; position: relative; display: inline-block;}
  .tbl_type td .p_params {height: 30px; line-height: 30px;}

  .ul_box {
    overflow: hidden;
  }

  .ul_box .fl {
    float: left;
    width: 49%;
  }
  .ul_box .fr {
    float: right;
    width: 49%;
  }

  .btn_wrap {
    text-align: right;
  }

  .comment {
    margin-top: 10px;
    color: red;
  }
</style>
