<template>
  <div id="real-component" class="real">
    <div id="realgrid" style="width: 100%; height: 600px"></div>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import { rootComputed, rootMethods } from '@/store/helpers'

let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  { fieldName: 'id', dataType: 'number' },
  { fieldName: 'first_name', dataType: 'text' },
  { fieldName: 'last_name', dataType: 'text' },
  { fieldName: 'email', dataType: 'text' },
  { fieldName: 'gender', dataType: 'text' },
  { fieldName: 'ip_address', dataType: 'text' },
  { fieldName: 'Monetary', dataType: 'text' },
  { fieldName: 'KorCountry', dataType: 'text' },
  { fieldName: 'dateTime2' },
  { fieldName: 'dateTime3' }
]

export const columns = [
  { name: 'id', fieldName: 'id', type: 'number', header: { text: '아이디' } },
  { name: 'first_name', fieldName: 'first_name', type: 'text', header: { text: 'FirstName' } },
  { name: 'last_name', fieldName: 'last_name', type: 'text', header: { text: 'LastName' } },
  { name: 'email', fieldName: 'email', type: 'text', header: { text: '이메일' } },
  { name: 'gender', fieldName: 'gender', type: 'text', header: { text: '성별' } },
  {
    name: 'Monetary',
    fieldName: 'Monetary',
    type: 'data',
    width: '200',
    values: ['EUR', 'USD', 'HKD', 'KRW'],
    labels: ['EUR', 'USD', 'HKD', 'KRW'],
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        let str = ''
        const data = (cell.value && cell.value.split(',')) || []
        const labels = cell.dataColumn.labels
        const values = cell.dataColumn.values
        for (let i = 0; i < labels.length; i++) {
          const checked = data.indexOf(values[i]) >= 0 ? 'checked' : ''
          str = str + "<input type='checkbox' value = '" + values[i] + "'" + checked + " onclick='vm.valuecheck(" + cell.index.dataRow + ", event)' />" + labels[i]
        }
        return str
      }
    }
  },
  { name: 'KorCountry', fieldName: 'KorCountry', type: 'data', width: '150', renderer: 'renderer_imgbtn', styleName: 'left-column custom_renderer', header: { text: '투자국가', styleName: 'orange-column' } },
  {
    name: 'dateTime2',
    fieldName: 'dateTime2',
    width: '150',
    height: '50',
    header: {
      text: 'bootstrap datepicker'
    },
    editor: {
      type: 'btdate',
      btOptions: {
        startView: 0,
        minViewMode: 0,
        todayBtn: 'linked',
        language: 'ko',
        todayHighlight: true
      },
      dropDownWhenClick: true,
      viewGridInside: true,
      textReadOnly: true,
      datetimeFormat: 'yyyy-MM-dd',
      yearNavigation: true,
      mask: {
        editMask: '9999-99-99',
        includedFormat: true
      }
    }
  },
  {
    name: 'dateTime3',
    fieldName: 'dateTime3',
    width: '110',
    header: {
      text: 'datepicker'
    },
    editor: {
      type: 'date', maxLength: 6, yearNavigation: true
    }

  }
]

export default {
  name: 'SampleBbs',
  components: { },
  data: function () {
    return {
      isEdit: false,
      isDelete: false,
      isFocus: false,
      isSearch: false,
      isPaging1: false,
      currentPage: 0,
      totalPage: 0,
      pageCount: 0,
      eventLog: ''
    }
  },
  created () {
    console.log('this.$inputNumberFormat() =' + this.$ekmtcCommon.$inputNumberFormat())
    this.eventLog = ''
    window.functionOutsideVue(this)
  },
  computed: {
    ...rootComputed
  },

  methods: {
    ...rootMethods,
    btnBeginInsertRow: function btnBeginInsertRow () {
      const curr = gridView.getCurrent()
      gridView.beginInsertRow(Math.max(0, curr.itemIndex))
      gridView.showEditor()
      gridView.setFocus()
    },

    btnBeginInsertRowShift: function btnBeginInsertRowShift () {
      const curr = gridView.getCurrent()
      gridView.beginInsertRow(Math.max(0, curr.itemIndex), true)
      gridView.showEditor()
      gridView.setFocus()
    },

    btnBeginAppendRow: function btnBeginAppendRow () {
      gridView.beginAppendRow()
      gridView.showEditor()
      gridView.setFocus()
    },
    focusOption: function () {
      this.isFocus = !this.isFocus
    },
    editOption: function () {
      if (!this.isEdit) {
        this.isEdit = true

        gridView.setEditOptions({
          insertable: true,
          appendable: true
        })
      } else {
        this.isEdit = false
        gridView.setEditOptions({
          insertable: false,
          appendable: false
        })
      }
    },
    deleteOption: function () {
      if (!this.isDelete) {
        this.isDelete = true

        gridView.setEditOptions({
          deletable: true
        })
      } else {
        this.isDelete = false
        gridView.setEditOptions({
          deletable: false
        })
      }
    },
    searchData: function () {
      this.isSearch = true
      console.log('searchData')
      provider.setRows(this.list)
    },
    valuecheck: function (dataRow, event) {
      console.log('value  check')
      console.log(JSON.stringify(dataRow))
      console.log(JSON.stringify(event))
      const target = event.target
      const value = target.value
      const dataValue2 = provider.getValue(dataRow, 'Monetary')
      console.log('dataValue2' + JSON.stringify(dataValue2))
      const dataValue = provider.getValue(dataRow, 'Monetary').split(',')
      const idx = dataValue.indexOf(value)
      if (target.checked) {
        if (idx < 0) {
          dataValue.push(value)
        }
      } else {
        if (idx >= 0) { dataValue.splice(idx, 1) }
      }
      console.log(JSON.stringify(dataRow))
      provider.setValue(dataRow, 'Monetary', dataValue.join(','))
    },
    customRender: function () {
      gridView.registerCustomRenderer('renderer_imgbtn', {
        initContent: function (parent) {
          var span = this._span = document.createElement('span')
          span.className = 'custom_render_span'
          parent.appendChild(span)
          parent.appendChild(this._button1 = document.createElement('span'))
          parent.appendChild(this._button2 = document.createElement('span'))
        },
        canClick: function () {
          console.log('can click')
          return true
        },
        clearContent: function (parent) {
          console.log('DISPOSED......')
          parent.innerHTML = ''
        },
        render: function (grid, model, width, height, info) {
          info = info || {}
          var span = this._span
          // text설정.
          span.textContent = model.value

          this._value = model.value
          this._button1.className = 'custom_search custom-hover' + (info.focused ? ' custom-focused' : '')
          this._button2.className = 'custom_popup custom-hover' + (info.focused ? ' custom-focused' : '')
        },
        click: function (event) {
          event.preventDefault()
          if (event.target === this._button1) {
            alert('조회버튼: ' + this._value)
          } else if (event.target === this._button2) {
            alert('팝업버튼: ' + this._value)
          }
        }
      })
    }
  },
  watch: {
    list: function () {
      console.log('watch')
    }
  },
  mounted: function () {
    // {"id":291,"first_name":"Burlie","last_name":"Pharo","email":"bpharo82@senate.gov","gender":"Female","ip_address":"247.206.113.77"},

    // this.$nextTick(function () {
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    gridView.setDataSource(provider)
    // 그리드 필드 생성
    provider.setFields(fields)
    console.log('mounted')
    // columns 명
    gridView.setColumns(columns)
  }
}

</script>
<style>

.custom_renderer > div {
  display: flex;
  align-items: center;
}

.custom_renderer .rg-renderer:hover .custom-hover {
  visibility: visible;
}

.custom_renderer .rg-renderer .custom-hover {
  visibility: visible;
}

.custom_renderer .rg-renderer .custom-focused {
  visibility: visible;
}

</style>
