<template>
  <div>
  <date-range-picker
    opens="inline"
    v-model="dateRange"
    :date-format="dateFormat"
    :locale-data="{
      direction: 'ltr',
      firstDay: 0,
      format: 'yyyy-mm-dd',
      separator: ' - ',
      applyLabel: '확인',
      cancelLabel: '취소',
      weekLabel: '주',
      customRangeLabel: '기간',
      daysOfWeek: ['일', '월', '화', '수', '목', '금', '토'],
      monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월']
    }"
  ></date-range-picker>
  <p>StartDate  : {{dateRange.startDate}}</p>
  <p>EndDate  : {{dateRange.endDate}}</p>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

console.log('sample componetnt')
export default {
  name: 'SampleComponent',
  components: { DateRangePicker },
  data: function () {
    return {
      dateRange: {
        startDate: '2021-07-01',
        endDate: '2021-07-31'
      }
    }
  },
  methods: {
    dateFormat (classes, date) {
      if (!classes.disabled) {
        classes.disabled = date.getTime() < new Date() // 날짜 제외 처리
      }
      return classes
    }
  }

}
</script>

<style scoped>

</style>
