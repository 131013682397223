var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content resize" },
    [
      _c("div", { staticClass: "content_box" }, [
        _c("form", { attrs: { id: "frm" } }, [
          _c("h2", { staticClass: "content_title" }, [_vm._v("화물정보")]),
          _c("table", { staticClass: "tbl_row" }, [
            _vm._m(0),
            _c("tbody", [
              _c("tr", [
                _c("th", [_vm._v("실화주 변경")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.rsParams2.actShprCstEnm,
                        expression: "rsParams2.actShprCstEnm",
                      },
                    ],
                    staticClass: "col_3",
                    attrs: { type: "text" },
                    domProps: { value: _vm.rsParams2.actShprCstEnm },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.rsParams2,
                          "actShprCstEnm",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.rsParams2.bkgShprCstNo,
                        expression: "rsParams2.bkgShprCstNo",
                      },
                    ],
                    staticClass: "col_3",
                    attrs: { type: "text" },
                    domProps: { value: _vm.rsParams2.bkgShprCstNo },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.rsParams2,
                          "bkgShprCstNo",
                          $event.target.value
                        )
                      },
                    },
                  }),
                  _c(
                    "a",
                    {
                      staticClass: "button blue sm",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.fnActShprCstEnmApply()
                        },
                      },
                    },
                    [_vm._v("적용")]
                  ),
                ]),
                _c("th"),
                _c("td", [
                  _c("div", [
                    _vm._v(" " + _vm._s(_vm.cmdParams.cmdtDsc) + " "),
                  ]),
                  _c(
                    "div",
                    [
                      _c("e-auto-complete-commodity", {
                        staticClass: "col_6 auto-area position_relative",
                        attrs: {
                          "ctr-cd": _vm.cmdParams.usrCtrCd,
                          "cd-nm": _vm.cmdParams.cmdtDsc,
                          id: "cmdtDsc",
                        },
                        on: { change: _vm.changeCmdtDsc },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
              _c("tr", [
                _c("th", [_vm._v("실화주")]),
                _c("td", [
                  _c("div", [
                    _vm._v(
                      " " +
                        _vm._s(_vm.rsParams.actShprCstEnm) +
                        " / " +
                        _vm._s(_vm.rsParams.bkgShprCstNo) +
                        " / " +
                        _vm._s(_vm.rsParams.actShprCstEnmYn) +
                        " "
                    ),
                  ]),
                  _c(
                    "div",
                    [
                      _c("e-auto-complete-cst-enm-v2", {
                        staticClass: "col_6 auto-area position_relative",
                        attrs: {
                          id: "actCst",
                          "cd-nm": _vm.rsParams.actShprCstEnm,
                        },
                        on: { change: _vm.actChange },
                      }),
                      _c("span", { staticClass: "float_right" }, [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.regParams.consoleBkgYn,
                              expression: "regParams.consoleBkgYn",
                            },
                          ],
                          attrs: { type: "checkbox", id: "c1", value: "Y" },
                          domProps: {
                            checked: Array.isArray(_vm.regParams.consoleBkgYn)
                              ? _vm._i(_vm.regParams.consoleBkgYn, "Y") > -1
                              : _vm.regParams.consoleBkgYn,
                          },
                          on: {
                            change: function ($event) {
                              var $$a = _vm.regParams.consoleBkgYn,
                                $$el = $event.target,
                                $$c = $$el.checked ? true : false
                              if (Array.isArray($$a)) {
                                var $$v = "Y",
                                  $$i = _vm._i($$a, $$v)
                                if ($$el.checked) {
                                  $$i < 0 &&
                                    _vm.$set(
                                      _vm.regParams,
                                      "consoleBkgYn",
                                      $$a.concat([$$v])
                                    )
                                } else {
                                  $$i > -1 &&
                                    _vm.$set(
                                      _vm.regParams,
                                      "consoleBkgYn",
                                      $$a
                                        .slice(0, $$i)
                                        .concat($$a.slice($$i + 1))
                                    )
                                }
                              } else {
                                _vm.$set(_vm.regParams, "consoleBkgYn", $$c)
                              }
                            },
                          },
                        }),
                        _vm._m(1),
                      ]),
                    ],
                    1
                  ),
                ]),
                _c("th", [_vm._v("G. Weight")]),
                _c("td", [
                  _c("span", { staticClass: "wg" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.weightTon,
                          expression: "regParams.weightTon",
                        },
                      ],
                      staticClass: "wid80",
                      attrs: { type: "text" },
                      domProps: { value: _vm.regParams.weightTon },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.regParams,
                            "weightTon",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" ton "),
                  ]),
                  _c(
                    "span",
                    {
                      staticClass: "color_gray",
                      staticStyle: {
                        display: "inline-block",
                        width: "30px",
                        "text-align": "center",
                      },
                    },
                    [_vm._v("/")]
                  ),
                  _c("span", { staticClass: "wg" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.weightKg,
                          expression: "regParams.weightKg",
                        },
                      ],
                      staticClass: "wid80",
                      attrs: { type: "text" },
                      domProps: { value: _vm.regParams.weightKg },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(
                            _vm.regParams,
                            "weightKg",
                            $event.target.value
                          )
                        },
                      },
                    }),
                    _vm._v(" kg "),
                  ]),
                ]),
              ]),
              _c("tr", [
                _c("th", { attrs: { scope: "row" } }, [
                  _vm._v("Ocean Freight"),
                ]),
                _c("td", [
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.oceanFreight,
                          expression: "regParams.oceanFreight",
                        },
                      ],
                      staticClass: "col_6",
                      on: {
                        change: function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.regParams,
                            "oceanFreight",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        },
                      },
                    },
                    [
                      _c("option", { attrs: { value: "" } }, [_vm._v("선택")]),
                      _c("option", { attrs: { value: "TEMP_A" } }, [
                        _vm._v("PREP AID"),
                      ]),
                      _c("option", { attrs: { value: "TEMP_B" } }, [
                        _vm._v("COLLECT"),
                      ]),
                    ]
                  ),
                ]),
                _c("th", { attrs: { scope: "row" } }, [_vm._v("SC No.")]),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.regParams.scNo,
                        expression: "regParams.scNo",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.regParams.scNo },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.regParams, "scNo", $event.target.value)
                      },
                    },
                  }),
                ]),
              ]),
              _c("tr", [
                _vm._m(2),
                _c("td", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.regParams.biddingNo,
                        expression: "regParams.biddingNo",
                      },
                    ],
                    attrs: { type: "text" },
                    domProps: { value: _vm.regParams.biddingNo },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(
                          _vm.regParams,
                          "biddingNo",
                          $event.target.value
                        )
                      },
                    },
                  }),
                ]),
                _c("th", [_vm._v(" checkbox 선택 ")]),
                _c("td", [
                  _c("span", { staticClass: "position_relative" }, [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.chkbox,
                          expression: "regParams.chkbox",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "sampleCheckbox_1",
                        value: "T001",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.regParams.chkbox)
                          ? _vm._i(_vm.regParams.chkbox, "T001") > -1
                          : _vm.regParams.chkbox,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.regParams.chkbox,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "T001",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.regParams,
                                  "chkbox",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.regParams,
                                  "chkbox",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.regParams, "chkbox", $$c)
                          }
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "sampleCheckbox_1" } }, [
                      _vm._v("T0001"),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.chkbox,
                          expression: "regParams.chkbox",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "sampleCheckbox_2",
                        value: "T002",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.regParams.chkbox)
                          ? _vm._i(_vm.regParams.chkbox, "T002") > -1
                          : _vm.regParams.chkbox,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.regParams.chkbox,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "T002",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.regParams,
                                  "chkbox",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.regParams,
                                  "chkbox",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.regParams, "chkbox", $$c)
                          }
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "sampleCheckbox_2" } }, [
                      _vm._v("T0002"),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.chkbox,
                          expression: "regParams.chkbox",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "sampleCheckbox_3",
                        value: "T003",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.regParams.chkbox)
                          ? _vm._i(_vm.regParams.chkbox, "T003") > -1
                          : _vm.regParams.chkbox,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.regParams.chkbox,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "T003",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.regParams,
                                  "chkbox",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.regParams,
                                  "chkbox",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.regParams, "chkbox", $$c)
                          }
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "sampleCheckbox_3" } }, [
                      _vm._v("T0003"),
                    ]),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.regParams.chkbox,
                          expression: "regParams.chkbox",
                        },
                      ],
                      attrs: {
                        type: "checkbox",
                        id: "sampleCheckbox_4",
                        value: "T004",
                      },
                      domProps: {
                        checked: Array.isArray(_vm.regParams.chkbox)
                          ? _vm._i(_vm.regParams.chkbox, "T004") > -1
                          : _vm.regParams.chkbox,
                      },
                      on: {
                        change: function ($event) {
                          var $$a = _vm.regParams.chkbox,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = "T004",
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                _vm.$set(
                                  _vm.regParams,
                                  "chkbox",
                                  $$a.concat([$$v])
                                )
                            } else {
                              $$i > -1 &&
                                _vm.$set(
                                  _vm.regParams,
                                  "chkbox",
                                  $$a.slice(0, $$i).concat($$a.slice($$i + 1))
                                )
                            }
                          } else {
                            _vm.$set(_vm.regParams, "chkbox", $$c)
                          }
                        },
                      },
                    }),
                    _c("label", { attrs: { for: "sampleCheckbox_4" } }, [
                      _vm._v("T0004"),
                    ]),
                  ]),
                ]),
              ]),
            ]),
          ]),
          _c("table", { staticClass: "tbl_col mt20" }, [
            _vm._m(3),
            _c("thead", [
              _c("tr", [
                _c("th", [_vm._v("Col1")]),
                _c("th", [_vm._v("Col2")]),
                _c("th", [_vm._v("Col3")]),
                _c("th", [_vm._v("Col4")]),
                _c("th", [_vm._v("Col5")]),
                _c("th", [
                  _c(
                    "button",
                    {
                      staticClass: "tbl_icon plus",
                      attrs: { type: "button" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.addRows()
                        },
                      },
                    },
                    [_vm._v("plus")]
                  ),
                ]),
              ]),
            ]),
            _c(
              "tbody",
              _vm._l(_vm.regParams.list, function (vo, idx) {
                return _c("tr", { key: "tr_" + idx }, [
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: vo.col1,
                          expression: "vo.col1",
                        },
                      ],
                      attrs: { type: "text", name: "arrCol1" },
                      domProps: { value: vo.col1 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(vo, "col1", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c("td", [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: vo.col2,
                            expression: "vo.col2",
                          },
                        ],
                        attrs: { name: "arrCol2" },
                        on: {
                          change: function ($event) {
                            var $$selectedVal = Array.prototype.filter
                              .call($event.target.options, function (o) {
                                return o.selected
                              })
                              .map(function (o) {
                                var val = "_value" in o ? o._value : o.value
                                return val
                              })
                            _vm.$set(
                              vo,
                              "col2",
                              $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            )
                          },
                        },
                      },
                      [
                        _c("option", { attrs: { value: "" } }, [
                          _vm._v("선택"),
                        ]),
                        _c("option", { attrs: { value: "sel1" } }, [
                          _vm._v("SEL01"),
                        ]),
                        _c("option", { attrs: { value: "sel2" } }, [
                          _vm._v("SEL02"),
                        ]),
                        _c("option", { attrs: { value: "sel3" } }, [
                          _vm._v("SEL03"),
                        ]),
                      ]
                    ),
                  ]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: vo.col3,
                          expression: "vo.col3",
                        },
                      ],
                      attrs: { type: "text", name: "arrCol3" },
                      domProps: { value: vo.col3 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(vo, "col3", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c("td", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: vo.col4,
                          expression: "vo.col4",
                        },
                      ],
                      attrs: { type: "text", name: "arrCol4" },
                      domProps: { value: vo.col4 },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) return
                          _vm.$set(vo, "col4", $event.target.value)
                        },
                      },
                    }),
                  ]),
                  _c("td", { staticStyle: { "text-align": "left" } }, [
                    _c("div", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: vo.col5Cd,
                            expression: "vo.col5Cd",
                          },
                        ],
                        staticClass: "col_4",
                        attrs: { type: "text", name: "arrCol5Cd" },
                        domProps: { value: vo.col5Cd },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(vo, "col5Cd", $event.target.value)
                          },
                        },
                      }),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: vo.col5Nm,
                            expression: "vo.col5Nm",
                          },
                        ],
                        staticClass: "col_4",
                        attrs: { type: "text", name: "arrCol5Nm" },
                        domProps: { value: vo.col5Nm },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(vo, "col5Nm", $event.target.value)
                          },
                        },
                      }),
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addCol5(idx)
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                    _c(
                      "ul",
                      { staticClass: "ul_box" },
                      _vm._l(vo.col5, function (svo, sidx) {
                        return _c("li", { key: "li_col5_" + sidx }, [
                          _vm._v(
                            " [" +
                              _vm._s(svo.code) +
                              "] " +
                              _vm._s(svo.name) +
                              " "
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeCol5(idx, sidx)
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ])
                      }),
                      0
                    ),
                  ]),
                  _c("td", [
                    _c(
                      "button",
                      {
                        staticClass: "tbl_icon minus",
                        attrs: { type: "button" },
                        on: {
                          click: function ($event) {
                            $event.preventDefault()
                            return _vm.removeRows(idx)
                          },
                        },
                      },
                      [_vm._v("minus")]
                    ),
                  ]),
                ])
              }),
              0
            ),
          ]),
          _c("div", { staticClass: "mt10 text_right" }, [
            _c(
              "a",
              {
                staticClass: "button blue lg mr5",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.apply()
                  },
                },
              },
              [_vm._v("적용")]
            ),
            _c(
              "a",
              {
                staticClass: "button gray lg",
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.compareCheck()
                  },
                },
              },
              [_vm._v("비교")]
            ),
          ]),
        ]),
      ]),
      _c("div", { staticClass: "content_box mt5" }, [
        _c("h2", { staticClass: "content_title" }, [_vm._v("파일 업로드")]),
        _c("input", {
          attrs: { type: "file", id: "inpFile" },
          on: {
            change: function ($event) {
              return _vm.fnFileUpload()
            },
          },
        }),
        _c("p", [_vm._v("ID : " + _vm._s(_vm.tempFile.id))]),
        _c("p", [_vm._v("MSG : " + _vm._s(_vm.tempFile.msg))]),
      ]),
      _c(
        "div",
        { staticClass: "content_box mt5" },
        [
          _c("h2", { staticClass: "content_title" }, [
            _vm._v("Booking 취소 테스트"),
          ]),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.bookingCancelParams.bkgNo,
                expression: "bookingCancelParams.bkgNo",
              },
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.bookingCancelParams.bkgNo },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.bookingCancelParams, "bkgNo", $event.target.value)
              },
            },
          }),
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.bookingCancelParams.lang,
                expression: "bookingCancelParams.lang",
              },
            ],
            staticClass: "mr5",
            attrs: { type: "text" },
            domProps: { value: _vm.bookingCancelParams.lang },
            on: {
              input: function ($event) {
                if ($event.target.composing) return
                _vm.$set(_vm.bookingCancelParams, "lang", $event.target.value)
              },
            },
          }),
          _c(
            "a",
            {
              staticClass: "button blue sm mr5",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.cancelBooking()
                },
              },
            },
            [_vm._v("Booking Cancel")]
          ),
          _c(
            "a",
            {
              staticClass: "button blue sm",
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.test()
                },
              },
            },
            [_vm._v("Test")]
          ),
          _vm.commonBookingComponent
            ? _c(_vm.commonBookingComponent, {
                ref: "commonBooking",
                tag: "component",
                attrs: { "parent-info": _vm.commonBookingParams },
                on: { callback: _vm.commonBookingCallback },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("div", { staticClass: "content_box mt5" }, [
        _vm._v(" " + _vm._s(_vm.alertTest) + " : "),
        _c(
          "a",
          {
            staticClass: "button blue sm mr5",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.alertMessage()
              },
            },
          },
          [_vm._v("AlertMessage")]
        ),
      ]),
      _c("div", { staticClass: "content_box mt5" }, [
        _c(
          "a",
          {
            staticClass: "button blue sm mr5",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.goWorkingProgress("Q")
              },
            },
          },
          [_vm._v("Go -> 진행중인업무 Query")]
        ),
        _c(
          "a",
          {
            staticClass: "button blue sm mr5",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.goWorkingProgress("P")
              },
            },
          },
          [_vm._v("Go -> 진행중인업무 Params")]
        ),
        _c(
          "a",
          {
            staticClass: "button blue sm mr5",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.goFreetimeRequest("Q")
              },
            },
          },
          [_vm._v("Go -> FREETIME Request Query")]
        ),
        _c(
          "a",
          {
            staticClass: "button blue sm mr5",
            attrs: { href: "#" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                return _vm.goFreetimeRequest("P")
              },
            },
          },
          [_vm._v("Go -> FREETIME Request Params")]
        ),
      ]),
      _c("div", { staticClass: "content_box mt5" }, [
        _c(
          "table",
          { staticClass: "tbl_col mt10 tbl_cargo_tracking" },
          _vm._l(_vm.convertList, function (vo, idx) {
            return _c("tr", { key: "convertList_" + idx }, [
              idx == 0
                ? _c("td", { attrs: { rowspan: _vm.rowCnt } }, [
                    _vm._v(_vm._s(vo.blNo)),
                  ])
                : _vm._e(),
              idx == 0
                ? _c("td", { attrs: { rowspan: _vm.rowCnt } }, [
                    _vm._v(_vm._s(vo.bkgNo)),
                  ])
                : _vm._e(),
              vo.cntrIdx == 0
                ? _c(
                    "td",
                    {
                      staticClass: "td_left_line",
                      attrs: { rowspan: vo.rowCnt },
                    },
                    _vm._l(_vm.cntrList, function (cvo, cidx) {
                      return _c("p", { key: "cntrNo_" + cidx }, [
                        cvo.cntrNo == vo.cntrNo
                          ? _c("span", [_vm._v(_vm._s(cvo.cntrNo))])
                          : _c(
                              "a",
                              {
                                staticClass: "link",
                                attrs: { href: "#" },
                                on: {
                                  click: function ($event) {
                                    $event.preventDefault()
                                    return _vm.setCntrList(cvo.cntrNo)
                                  },
                                },
                              },
                              [_vm._v(_vm._s(cvo.cntrNo))]
                            ),
                      ])
                    }),
                    0
                  )
                : _vm._e(),
              _c("td", { staticClass: "td_left_line" }, [
                _vm._v(_vm._s(vo.polPortNm)),
              ]),
              _c("td", { staticClass: "td_left_line" }, [
                _vm._v(_vm._s(vo.podPortNm)),
              ]),
              _c("td", { staticClass: "td_left_line" }, [
                _vm._v(_vm._s(vo.eta)),
              ]),
              _c("td", { staticClass: "td_left_line" }, [
                _vm._v(_vm._s(vo.etd)),
              ]),
            ])
          }),
          0
        ),
      ]),
      _c(
        "win-layer-pop",
        { staticClass: "sample_compare_object" },
        [
          _vm.customComponent
            ? _c(_vm.customComponent, {
                tag: "component",
                attrs: { "parent-info": _vm.popParams },
                on: { close: _vm.closePopup, selectFunc: _vm.selectFunc },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
      _c("col", { attrs: { width: "130" } }),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("label", { attrs: { for: "c1" } }, [
      _c("span"),
      _vm._v("콘솔부킹"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("Bidding No."),
      _c("span", { staticClass: "txt" }, [_vm._v("(선택)")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "25%" } }),
      _c("col", { attrs: { width: "15%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "10%" } }),
      _c("col", { attrs: { width: "35%" } }),
      _c("col", { attrs: { width: "5%" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }