<template>
  <div>

    <h5 class="mt-5">자동완성??</h5>
    <search-place>
    </search-place>

    <h5 class="mt-5">SELECT로 구현</h5>
    <select-ctr-plc
      :parentInfo="paramInfo"
      @parentStartPlcChange="testfunction"
    >
    </select-ctr-plc>

    <hr/>

    <h5 class="mt-5">DATALIST로 구현</h5>
    <select-ctr-plc2
      :parentInfo="paramInfo"
    >
    </select-ctr-plc2>

    <hr/>

    <h5 class="mt-5">DATALIST로 구현 (복수 선택)</h5>
    <select-ctr-plc2
      :parentInfo="paramInfo2"
    >
    </select-ctr-plc2>

    <div class="ml-2 mt-2 mb-5">
      <p>* 출발지/도착지 검색 설정 방법</p>
      <p>1. 컴포넌트 import</p>
      <p>2. template 작성</p>
      <p>- parentInfo.arrList : 기본 셋팅 파라미터가 존재할 경우 작성, 없으면 작성하지 않음</p>
      <p>- parentInfo.maxRowCnt : 기본 3개로 설정되어 있음, 추가로 변경이 필요할 경우만 작성.</p>
      <p>- parentInfo.isMulti : 'Y'인 경우 출발지, 도착지 +, - 버튼으로 다중 설정 가능. 'N'인 경우 단일.</p>
      <p>- @parentStartPlcChange='부모function' 설정시 출발지 항구 selectbox change 이벤트를 부포 컴포넌트에서 설정 가능.</p>
      <p>- @parentDestPlcChange='부모function' 설정시 도착지 항구 selectbox change 이벤트를 부포 컴포넌트에서 설정 가능.</p>
    </div>

    <search-area
      :parentInfo="searchInfo"
    >
    </search-area>
    <div class="ml-2 mt-2 mb-5">
      <p>설정 방법</p>
      <p>1. 컴포넌트 import</p>
      <p>2. template 작성</p>
      <p>- parentInfo.customSelectList : 조회 조건 selectbox 정보 설정. selectbox에 보여줄 option 순서대로 작성. codeNm은 디폴트 지정되어있으며 별도로 변경 필요할시에만 작성.</p>
      <p>- parentInfo.dateTerm : 기본 당일 기준 7일 전후. 별도 설정 필요시에만 작성</p>
      <p>- parentInfo.startDate : dateTerm 값으로 전후 설정하지 않고 별도 지정이 필요할 경우 시작일 지정</p>
      <p>- parentInfo.endDate : dateTerm 값으로 전후 설정하지 않고 별도 지정이 필요할 경우 시작일 지정</p>
      <p>- @parentClickEvt : 검색 버튼 클릭시 실행 function 지정</p>
    </div>
  </div>
</template>

<script>

import SelectCtrPlc from '@/components/trans/SelectCtrPlc'
import SelectCtrPlc2 from '@/components/trans/SelectCtrPlc2'
import SearchArea from '@/components/trans/SearchArea'
import SearchPlace from '@/components/trans/SearchPlace'

export default {
  name: 'SampleSelectCtrPlc',
  components: {
    SelectCtrPlc,
    SelectCtrPlc2,
    SearchArea,
    SearchPlace
  },
  data () {
    return {
      paramInfo: {
        isMulti: 'N',
        arrList: [
          {
            startCtrCd: '',
            startPlcCd: '',
            destCtrCd: '',
            destPlcCd: ''
          }
        ]
      },
      paramInfo2: {
        isMulti: 'Y',
        maxRowCnt: 5,
        arrList: [
          {
            startCtrCd: 'KR',
            startPlcCd: 'PUS',
            destCtrCd: 'HK',
            destPlcCd: 'HKG'
          },
          {
            startCtrCd: '',
            startPlcCd: '',
            destCtrCd: '',
            destPlcCd: ''
          }
        ]
      },
      searchInfo: {
        dateTerm: 14,
        startDate: '2020-07-30',
        endDate: '2021-07-29',
        customSelectList: [
          { code: 'BL' },
          { code: 'BK' },
          { code: 'BD' },
          { code: 'VS', codeNm: 'Vessel/Voyage111' }
        ]
      }
    }
  },
  methods: {
    testfunction (e) {
      console.log(e)
      alert(e.target.value)
    }
  }
}
</script>

<style scoped>
  p {line-height: 1; font-size:13px;}
</style>
