<template>
  <div id="app">
    <DEXT5Editor :data="editorText" id="dexteditor1" runtimes="html5" width="100%" height="500px" @input="getData" />
  </div>
</template>

<script>
import DEXT5Editor from '@/components/DEXT5Editor'

export default {
  name: 'SampleDextEditor',
  components: { DEXT5Editor },
  data: function () {
    return {
      editorText: ''
    }
  },
  watch: {
    list: function () {
      console.log('watch')
    }
  },
  created () {
    this.editorText = 'test data'
  },
  mounted: function () {

  },
  methods: {
    getData: function (text) {
      console.log(text)
    }
  }
}
</script>
