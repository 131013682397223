<template>
    <div class="content resize"><!-- content : 우측quick_wrap 닫히면 resize 제거 -->
        <br>
        <h1 class="page_title">PDF Viewer</h1>
        <div class="mt-5">
            <div class="row">
                <div class="col-md-4">
                    <div class="row">
                        <div class="col-md-4 text-left">
                            <b-button type="button" pill variant="secondary" @click="movePg('prev')">이전</b-button>
                        </div>
                        <div class="col-md-4 text-center">
                            {{currentPage}} / {{pageCount}}
                        </div>
                        <div class="col-md-4 text-right">
                            <b-button type="button" pill variant="secondary" @click="movePg('next')">다음</b-button>
                        </div>
                    </div>
                </div>
                <div class="col-md-4">
                    <b-select v-model="pdfUrl" @change="changePdf()">
                        <option v-for="(u, idx) in arrPdf" :key="idx" :value="u">{{u}}</option>
                    </b-select>
                </div>
                <div class="col-md-4 text-right">
                    <b-button type="button" variant="primary" @click="$refs.pdfViewer.print()">Print1</b-button>
                    <b-button type="button" variant="primary" @click="pdfPrint()">Print2</b-button>
                    <a target="_blank" :href="pdfUrl">Print2</a>
                </div>
            </div>
            <div class="mt-5" style='width:1250px; height:1770px; border: 5px solid #0000; background-color:pink;'>
                <pdf ref="pdfViewer"
                    :src="pdfUrl"
                    :page="pg"
                    @num-pages="pageCount = $event"
                    @page-loaded="currentPage = $event"
                    ></pdf>
            </div>
        </div>
        <div style="height:20px; background-color:red;">&nbsp;</div>
        <div style="height:20px; background-color:orange;">&nbsp;</div>
        <div style="height:20px; background-color:yellow;">&nbsp;</div>
        <div style="height:20px; background-color:green;">&nbsp;</div>
        <div style="height:20px; background-color:blue;">&nbsp;</div>
        <div style="height:20px; background-color:indigo;">&nbsp;</div>
        <div style="height:20px; background-color:purple;">&nbsp;</div>
        <div style="height:20px; background-color:white;">&nbsp;</div>
        <div style="height:20px; background-color:red;">&nbsp;</div>
        <div style="height:20px; background-color:orange;">&nbsp;</div>
        <div style="height:20px; background-color:yellow;">&nbsp;</div>
        <div style="height:20px; background-color:green;">&nbsp;</div>
        <div style="height:20px; background-color:blue;">&nbsp;</div>
        <div style="height:20px; background-color:indigo;">&nbsp;</div>
        <div style="height:20px; background-color:purple;">&nbsp;</div>

        <iframe id="iframe1" :src="pdfUrl" frameborder="0" style="display:block;width:100%;height:100%"></iframe>
    </div>
</template>

<script>

import pdf from 'vue-pdf'

export default {
    name: 'SamplePdfViewer',
    components: { pdf },
    created () {
        this.pdfUrl = this.arrPdf[0]
    },
    data () {
        return {
            idx: 0,
            arrPdf: [
                'https://cdn.rawgit.com/mozilla/pdf.js/c6e8ca86/test/pdfs/freeculture.pdf',
                'https://cdn.rawgit.com/mozilla/pdf.js/c6e8ca86/test/pdfs/calrgb.pdf',
                'http://127.0.0.1:9090/AIViewer55/sample/UICSBK106R9.jsp?reportMode=PDF&asBkgNo=CN04161537&asCtrCd=CN&asPlcCd=SHA&asErrRmkYn=N&asUsrNo=SWLC041&asAgtCd=SHASHA&asGubun=4&AIReportLangScCd=en&reportParams=pdfsavename%3ACN04161537'
            ],
            pageCount: 0,
            currentPage: 0,
            pg: 1,
            pdfUrl: ''
        }
    },
    methods: {
        changePdf () {
            this.pg = 1
        },
        movePg (type) {
            if (this.pdfUrl === '') {
                alert('PDF 를 선택해 주세요')
                return
            }

            if (type === 'prev') {
                if (this.pg === 1) {
                    return
                }
                this.pg--
            } else {
                if (this.pg >= this.pageCount) {
                    return
                }
                this.pg++
            }
        },
        pdfPrint () {
            try {
                document.getElementById('iframe1').contentWindow.print()
            } catch (e) {
                console.log(e)
                document.getElementById('iframe1').src = 'http://127.0.0.1:8080/#/sample/pdf-viewer'
                setTimeout(function () {
                    document.getElementById('iframe1').contentWindow.print()
                }, 1000)
            }
        }
    }
}

</script>
