var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { staticClass: "mt-4 mb-4" },
            [
              _c("b-col", [
                _c("h2", [
                  _vm._v("다국어 Page " + _vm._s(_vm.$t("label.TEST01"))),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-4 mb-4" },
            [
              _c("b-col", [
                _c("span", [
                  _vm._v(
                    _vm._s(_vm.$t("label.TEST02", { langcd: _vm.$i18n.locale }))
                  ),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "b-row",
            [
              _c(
                "b-button",
                {
                  staticClass: "ml-2",
                  attrs: { type: "submit", variant: "success" },
                  on: {
                    click: function ($event) {
                      _vm.$i18n.locale = "ko-KR"
                    },
                  },
                },
                [_vm._v("한국어")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "ml-2",
                  attrs: { type: "submit", variant: "success" },
                  on: {
                    click: function ($event) {
                      _vm.$i18n.locale = "en-US"
                    },
                  },
                },
                [_vm._v("영어")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "ml-2",
                  attrs: { type: "submit", variant: "success" },
                  on: {
                    click: function ($event) {
                      _vm.$i18n.locale = "ja-JP"
                    },
                  },
                },
                [_vm._v("일본어")]
              ),
              _c(
                "b-button",
                {
                  staticClass: "ml-2",
                  attrs: { type: "submit", variant: "success" },
                  on: {
                    click: function ($event) {
                      _vm.$i18n.locale = "zh-CN"
                    },
                  },
                },
                [_vm._v("중국어")]
              ),
            ],
            1
          ),
          _c("hr"),
          _c("form", { staticClass: "row g-3" }, [
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "label",
                { staticClass: "form-label", attrs: { for: "first_name" } },
                [_vm._v("First name")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.params.first_name,
                    expression: "params.first_name",
                  },
                ],
                class:
                  _vm.errerMsg.first_name != undefined &&
                  _vm.errerMsg.first_name != ""
                    ? "form-control error-input"
                    : "form-control",
                attrs: { type: "text", id: "first_name" },
                domProps: { value: _vm.params.first_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.params, "first_name", $event.target.value)
                  },
                },
              }),
              _vm.errerMsg.first_name != undefined &&
              _vm.errerMsg.first_name != ""
                ? _c("div", { staticClass: "error-message" }, [
                    _vm._v(" " + _vm._s(_vm.errerMsg.first_name) + " "),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "col-md-4" }, [
              _c(
                "label",
                { staticClass: "form-label", attrs: { for: "last_name" } },
                [_vm._v("Last name")]
              ),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.params.last_name,
                    expression: "params.last_name",
                  },
                ],
                staticClass: "form-control",
                class:
                  _vm.errerMsg.last_name != undefined &&
                  _vm.errerMsg.last_name != ""
                    ? "form-control error-input"
                    : "form-control",
                attrs: { type: "text", id: "last_name" },
                domProps: { value: _vm.params.last_name },
                on: {
                  input: function ($event) {
                    if ($event.target.composing) return
                    _vm.$set(_vm.params, "last_name", $event.target.value)
                  },
                },
              }),
              _vm.errerMsg.last_name != undefined &&
              _vm.errerMsg.last_name != ""
                ? _c("div", { staticClass: "error-message" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("label." + _vm.errerMsg.last_name)) +
                        " "
                    ),
                  ])
                : _vm._e(),
            ]),
            _c("div", { staticClass: "col-12 mt-1" }, [
              _c("h2", [_vm._v(_vm._s(_vm.text_message))]),
            ]),
            _c(
              "div",
              { staticClass: "col-12 mt-1" },
              [
                _c("label", [_vm._v(" ")]),
                _c(
                  "b-button",
                  {
                    attrs: { type: "button", variant: "success" },
                    on: {
                      click: function ($event) {
                        return _vm.test()
                      },
                    },
                  },
                  [_vm._v("Test")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }