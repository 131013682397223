var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("date-range-picker", {
        attrs: {
          opens: "inline",
          "date-format": _vm.dateFormat,
          "locale-data": {
            direction: "ltr",
            firstDay: 0,
            format: "yyyy-mm-dd",
            separator: " - ",
            applyLabel: "확인",
            cancelLabel: "취소",
            weekLabel: "주",
            customRangeLabel: "기간",
            daysOfWeek: ["일", "월", "화", "수", "목", "금", "토"],
            monthNames: [
              "1월",
              "2월",
              "3월",
              "4월",
              "5월",
              "6월",
              "7월",
              "8월",
              "9월",
              "10월",
              "11월",
              "12월",
            ],
          },
        },
        model: {
          value: _vm.dateRange,
          callback: function ($$v) {
            _vm.dateRange = $$v
          },
          expression: "dateRange",
        },
      }),
      _c("p", [_vm._v("StartDate : " + _vm._s(_vm.dateRange.startDate))]),
      _c("p", [_vm._v("EndDate : " + _vm._s(_vm.dateRange.endDate))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }