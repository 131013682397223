<template>
  <div>
    <div v-for="(vo, idx) in paramList" :key="idx" style="display:inline-flex;width:100%;">
      <!-- [S] 출발국가 -->
      <input class="form-control" :list="componentId + 'startCtrOptions_'+idx" :id="componentId + 'startCtrList_'+idx"
        v-model="vo.startCtrEnm"
        @change="updatePlcList($event, idx, 'start')"
        @focus="$event.target.select()"
        placeholder="출발국가"
        autocomplete="off"
      >
      <datalist :id="componentId + 'startCtrOptions_'+idx">
        <option v-for="cvo in ctrList" :key="cvo.ctrCd" :value="cvo.ctrEnm"></option>
      </datalist>
      <!-- [E] 출발국가 -->

      <!-- [S] 출발지 -->
      <input class="form-control" :list="componentId + 'startPlcOptions_'+idx" :id="componentId + 'startPlcList_'+idx"
        v-model="vo.startPlcEnm"
        @change="changeStartPlcList"
        @focus="$event.target.select()"
        placeholder="출발지"
        autocomplete="off"
      >
      <datalist :id="componentId + 'startPlcOptions_'+idx">
        <option v-for="cvo in plcList[vo.startCtrCd]" :key="cvo.plcCd" :value="cvo.plcEnm"></option>
      </datalist>
      <!-- [E] 출발지 -->

      <!-- [S] 도착국가 -->
      <input class="form-control" :list="componentId + 'destCtrOptions_'+idx" :id="componentId + 'destCtrList_'+idx"
        v-model="vo.destCtrEnm"
        @change="updatePlcList($event, idx, 'dest')"
        @focus="$event.target.select()"
        placeholder="도착국가"
        autocomplete="off"
      >
      <datalist :id="componentId + 'destCtrOptions_'+idx">
        <option v-for="cvo in ctrList" :key="cvo.ctrCd" :value="cvo.ctrEnm"></option>
      </datalist>
      <!-- [E] 도착국가 -->

      <!-- [S] 도착지 -->
      <input class="form-control" :list="componentId + 'destPlcOptions_'+idx" :id="componentId + 'destPlcList_'+idx"
        v-model="vo.destPlcEnm"
        @change="changeDestPlcList"
        @focus="$event.target.select()"
        placeholder="도착지"
        autocomplete="off"
      >
      <datalist :id="componentId + 'destPlcOptions_'+idx">
        <option v-for="cvo in plcList[vo.destCtrCd]" :key="cvo.plcCd" :value="cvo.plcEnm"></option>
      </datalist>
      <!-- [E] 도착지 -->
      <div v-if="isMulti === 'Y'">
        <b-button size="sm" variant="secondary" @click="addCountry" v-if="idx === 0">+</b-button>
        <b-button size="sm" variant="secondary" @click="removeCountry(idx)" v-if="idx !== 0">-</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'SelectCtrPlc',
  props: {
    parentInfo: Object
  },
  data () {
    return {
      componentId: '',
      isMulti: 'N',
      maxRowCnt: 3,
      ctrList: [
        { ctrCd: 'KR', ctrEnm: 'Korea' },
        { ctrCd: 'HK', ctrEnm: 'Hong Kong' },
        { ctrCd: 'CN', ctrEnm: 'China' }
      ],
      paramList: [],
      plcList: {
        KR: [
          { plcCd: 'PUS', plcEnm: 'Busan,Korea' },
          { plcCd: 'ASN', plcEnm: 'Ansan,Korea' }
        ],
        HK: [
          { plcCd: 'HKG', plcEnm: 'Hong Kong' }
        ],
        CN: [
          { plcCd: 'ANJ', plcEnm: 'Anji' },
          { plcCd: 'AQG', plcEnm: 'Anqing' },
          { plcCd: 'BAO', plcEnm: 'Baoan' },
          { plcCd: 'BCN', plcEnm: 'Beicun (In Nanhai. Foshan)' },
          { plcCd: 'BHY', plcEnm: 'Beihai (In Guangxi)' },
          { plcCd: 'BJO', plcEnm: 'Beijiao (In Shunde. Foshan)' },
          { plcCd: 'BJS', plcEnm: 'Beijing' },
          { plcCd: 'BBU', plcEnm: 'Bengbu' },
          { plcCd: 'CGD', plcEnm: 'Changde' },
          { plcCd: 'CSX', plcEnm: 'Changsha' },
          { plcCd: 'CGS', plcEnm: 'Changshu' },
          { plcCd: 'CZX', plcEnm: 'Changzhou' },
          { plcCd: 'CWN', plcEnm: 'Chiwan (In Shenzhen)' },
          { plcCd: 'CZH', plcEnm: 'Chizhou' },
          { plcCd: 'CKQ', plcEnm: 'Chongqing (Inland)' },
          { plcCd: 'CKG', plcEnm: 'Chongqing (Port)' },
          { plcCd: 'DCB', plcEnm: 'Dachanbay' },
          { plcCd: 'DLC', plcEnm: 'Dalian' },
          { plcCd: 'DMY', plcEnm: 'Damaiyu' },
          { plcCd: 'DSH', plcEnm: 'Danshui' },
          { plcCd: 'DEQ', plcEnm: 'Deqing' },
          { plcCd: 'DGG', plcEnm: 'Dongguan' },
          { plcCd: 'DOU', plcEnm: 'Doumen (In Zhuhai)' },
          { plcCd: 'FAN', plcEnm: 'Fang Cheng (In Guangxi)' },
          { plcCd: 'FCN', plcEnm: 'Fang Cun (In Guangzhou)' },
          { plcCd: 'FLG', plcEnm: 'Fuling(In Chongqing' },
          { plcCd: 'FTN', plcEnm: 'Futian' },
          { plcCd: 'FFY', plcEnm: 'Fuyong' },
          { plcCd: 'FQG', plcEnm: 'Fuzhou (Fuqing)' },
          { plcCd: 'FOC', plcEnm: 'Fuzhou (Mawei)' },
          { plcCd: 'GGL', plcEnm: 'Gaolan (In Zhuhai)' },
          { plcCd: 'GOM', plcEnm: 'Gaoming (In Foshan)' },
          { plcCd: 'GSH', plcEnm: 'Gaosha' },
          { plcCd: 'CAN', plcEnm: 'Guang Zhou' },
          { plcCd: 'GUG', plcEnm: 'Guigang (In Guangxi)' },
          { plcCd: 'HAK', plcEnm: 'Haikou (In Hainan)' },
          { plcCd: 'HME', plcEnm: 'Haimen' },
          { plcCd: 'HGH', plcEnm: 'Hangzhou' },
          { plcCd: 'HRB', plcEnm: 'Harbin' },
          { plcCd: 'HSN', plcEnm: 'Heshan (In Jiangmen)' },
          { plcCd: 'HWN', plcEnm: 'Hongwan(In Zhuhai)' },
          { plcCd: 'HUD', plcEnm: 'Huadu (In Guangzhou)' },
          { plcCd: 'HUA', plcEnm: 'Huangpu (In Guangzhou)' },
          { plcCd: 'HSI', plcEnm: 'Huangshi' },
          { plcCd: 'HIG', plcEnm: 'Huidong' },
          { plcCd: 'HYN', plcEnm: 'Huiyang' },
          { plcCd: 'HZH', plcEnm: 'Huzhou' },
          { plcCd: 'JMN', plcEnm: 'Jiangmen (Gaosha Tmnl. Waihai Tmnl)' },
          { plcCd: 'JIA', plcEnm: 'Jiangyin (In Jiangsu)' },
          { plcCd: 'JAX', plcEnm: 'Jiaoxin (In Guangzhou)' },
          { plcCd: 'JXN', plcEnm: 'Jiaxing' },
          { plcCd: 'JNN', plcEnm: 'Jinan' },
          { plcCd: 'JIN', plcEnm: 'Jingzhou' },
          { plcCd: 'JIU', plcEnm: 'Jiujiang (In Nanhai. Foshan)' },
          { plcCd: 'JJG', plcEnm: 'Jiujiang (Jiangxi)' },
          { plcCd: 'KPG', plcEnm: 'Kaiping' },
          { plcCd: 'KMN', plcEnm: 'Kemen' },
          { plcCd: 'KUS', plcEnm: 'Kunshan' },
          { plcCd: 'LSI', plcEnm: 'Lanshi (In Foshan)' },
          { plcCd: 'LUU', plcEnm: 'Leliu (In Shunde. Foshan)' },
          { plcCd: 'LIH', plcEnm: 'Lianhuashan (In Panyu. Guangzhou)' },
          { plcCd: 'LYG', plcEnm: 'Lianyungang' },
          { plcCd: 'LZH', plcEnm: 'Liuzhou' },
          { plcCd: 'LTC', plcEnm: 'Long Tong Tmnl (In Dongguan)' },
          { plcCd: 'LGG', plcEnm: 'Longgang' },
          { plcCd: 'LKU', plcEnm: 'Longkou' },
          { plcCd: 'LUZ', plcEnm: 'Luzhou' },
          { plcCd: 'MAC', plcEnm: 'Macau' },
          { plcCd: 'MCG', plcEnm: 'Machong (In Dongguan)' },
          { plcCd: 'MFG', plcEnm: 'Mafang (In Zhaoqing)' },
          { plcCd: 'MWN', plcEnm: 'Mawan (In Shenzhen)' },
          { plcCd: 'MYG', plcEnm: 'Mayong' },
          { plcCd: 'NCH', plcEnm: 'Nanchang' },
          { plcCd: 'NGG', plcEnm: 'Nangang (In Nanhai. Foshan)' },
          { plcCd: 'NKG', plcEnm: 'Nanjing' },
          { plcCd: 'NNN', plcEnm: 'Nanning' },
          { plcCd: 'NSN', plcEnm: 'Nansha (Nanwei Tmnl. Dong Fat Tmnl)' },
          { plcCd: 'NNS', plcEnm: 'Nansha New Port' },
          { plcCd: 'NTG', plcEnm: 'Nantong' },
          { plcCd: 'NTU', plcEnm: 'Nantou' },
          { plcCd: 'NBO', plcEnm: 'Ningbo' },
          { plcCd: 'PUT', plcEnm: 'Putian' },
          { plcCd: 'TAO', plcEnm: 'Qingdao' },
          { plcCd: 'QYN', plcEnm: 'Qingyuan' },
          { plcCd: 'SHP', plcEnm: 'Qinhuangdao' },
          { plcCd: 'QZH', plcEnm: 'Qinzhou (In Guangxi)' },
          { plcCd: 'QZJ', plcEnm: 'Quanzhou (Shihu Tmnl)' },
          { plcCd: 'ROQ', plcEnm: 'Rongqi (In Shunde. Foshan)' },
          { plcCd: 'SBU', plcEnm: 'Sanbu (In Jiangmen)' },
          { plcCd: 'SNR', plcEnm: 'Sanrong (In Zhaoqing)' },
          { plcCd: 'SSN', plcEnm: 'Sanshan (In Nanhai. Foshan)' },
          { plcCd: 'SJQ', plcEnm: 'Sanshui (In Foshan)' },
          { plcCd: 'SHA', plcEnm: 'Shanghai' },
          { plcCd: 'SWA', plcEnm: 'Shantou (In Guangdong)' },
          { plcCd: 'STN', plcEnm: 'Shatian (In Dongguan)' },
          { plcCd: 'SHK', plcEnm: 'Shekou' },
          { plcCd: 'SNW', plcEnm: 'Shenwan (In Zhongshan)' },
          { plcCd: 'SHE', plcEnm: 'Shenyang' },
          { plcCd: 'SZP', plcEnm: 'Shenzhen' },
          { plcCd: 'SDA', plcEnm: 'Shidao' },
          { plcCd: 'SIL', plcEnm: 'Shilong' },
          { plcCd: 'SZH', plcEnm: 'Suzhou' },
          { plcCd: 'TCG', plcEnm: 'Taicang' },
          { plcCd: 'TPN', plcEnm: 'Taiping (In Dongguan)' },
          { plcCd: 'THN', plcEnm: 'Taishan (In Jiangmen)' },
          { plcCd: 'TAZ', plcEnm: 'Taizhou' },
          { plcCd: 'TGU', plcEnm: 'Tanggu' },
          { plcCd: 'TSN', plcEnm: 'Tianjin' },
          { plcCd: 'TOL', plcEnm: 'Tongling' },
          { plcCd: 'WAZ', plcEnm: 'Wanzai (In Zhuhai)' },
          { plcCd: 'WZH', plcEnm: 'Wanzhou(In Chongqing' },
          { plcCd: 'WEI', plcEnm: 'Weihai' },
          { plcCd: 'WNZ', plcEnm: 'Wenzhou' },
          { plcCd: 'WUC', plcEnm: 'Wu Chong Kou' },
          { plcCd: 'WUH', plcEnm: 'Wuhan' },
          { plcCd: 'WHI', plcEnm: 'Wuhu' },
          { plcCd: 'WJG', plcEnm: 'Wujiang' },
          { plcCd: 'WUX', plcEnm: 'Wuxi' },
          { plcCd: 'WUZ', plcEnm: 'Wuzhou (In Guangxi)' },
          { plcCd: 'SIA', plcEnm: 'Xi An' },
          { plcCd: 'XAN', plcEnm: 'Xi\'An' },
          { plcCd: 'XMN', plcEnm: 'Xiamen' },
          { plcCd: 'XGZ', plcEnm: 'Xiangzhou (Xiangzhou Tmnl In Zhuhai)' },
          { plcCd: 'XON', plcEnm: 'Xiaolan (In Zhongshan)' },
          { plcCd: 'XGG', plcEnm: 'Xingang' },
          { plcCd: 'XIN', plcEnm: 'Xinhui (In Jiangmen)' },
          { plcCd: 'XTG', plcEnm: 'Xintang (In Guangzhou)' },
          { plcCd: 'XIY', plcEnm: 'Xiyu(In Zhuhai)' },
          { plcCd: 'YPG', plcEnm: 'Yangpu (In Hainan)' },
          { plcCd: 'YZH', plcEnm: 'Yangzhou' },
          { plcCd: 'YAN', plcEnm: 'Yantai' },
          { plcCd: 'YTN', plcEnm: 'Yantian' },
          { plcCd: 'YIB', plcEnm: 'Yibin' },
          { plcCd: 'YCH', plcEnm: 'Yichang' },
          { plcCd: 'YIK', plcEnm: 'Yingkou' },
          { plcCd: 'YYA', plcEnm: 'Yueyang' },
          { plcCd: 'ZJG', plcEnm: 'Zhangjiagang' },
          { plcCd: 'ZZU', plcEnm: 'Zhangzhou' },
          { plcCd: 'ZHA', plcEnm: 'Zhanjiang (In Guangdong)' },
          { plcCd: 'ZQG', plcEnm: 'Zhaoqing (Xia Shan (Jisi) Tmnl)' },
          { plcCd: 'ZAP', plcEnm: 'Zhapu' },
          { plcCd: 'ZHE', plcEnm: 'Zhenjiang' },
          { plcCd: 'ZSN', plcEnm: 'Zhongshan' },
          { plcCd: 'ZOS', plcEnm: 'Zhoushan' },
          { plcCd: 'ZHH', plcEnm: 'Zhuhai' }

        ]
      },
      searchParam: {
        arrCtrCd: []
      }
    }
  },
  created () {
    this.componentId = (new Date().getTime()) + '_' + Math.floor(Math.random() * 10000)
    //this.commCountryList()
    this.initParams()
  },
  methods: {
    isEmpty (objValue) {
      if (objValue === undefined || objValue === null) {
        return true
      } else {
        return ('' + objValue).length === 0
      }
    },
    isNotEmpty (objValue) {
      return !this.isEmpty(objValue)
    },
    async commCountryList () {
      await axios
        .get('http://localhost:9080/api/VOSD100/countries')
        .then(res => {
          const result = res.data
          const list = result.data
          this.ctrList = list

          this.initParams()
        })
        .catch(err => {
          console.log(err)
        })
    },
    initParams () {
      const parentInfo = this.parentInfo
      const paramList = this.paramList || []
      if (parentInfo !== null) {
        this.maxRowCnt = this.isNotEmpty(parentInfo.maxRowCnt) ? parentInfo.maxRowCnt : this.maxRowCnt
        this.isMulti = parentInfo.isMulti
        const def = {
          startCtrCd: '',
          startPlcCd: '',
          destCtrCd: '',
          destPlcCd: '',
          startCtrEnm: '',
          destCtrEnm: ''
        }

        if (parentInfo.arrList !== null && parentInfo.arrList !== undefined && parentInfo.arrList.length > 0) {
          const arrList = parentInfo.arrList
          const ctrList = this.ctrList

          arrList.forEach((c, idx) => {
            let ctrInfo, plcInfo
            if (this.isNotEmpty(c.startCtrCd)) {
              ctrInfo = ctrList.find(v => v.ctrCd === c.startCtrCd)

              if (ctrInfo !== undefined || ctrInfo !== null) {
                let { ctrEnm } = ctrInfo
                c.startCtrEnm = ctrEnm
              }

              if (this.isNotEmpty(c.startPlcCd) && this.plcList[c.startCtrCd] !== undefined) {
                plcInfo = this.plcList[c.startCtrCd].find(v => v.plcCd === c.startPlcCd)

                if (plcInfo !== undefined || plcInfo !== null) {
                  c.startPlcEnm = plcInfo.plcEnm
                }
              }
            }

            if (this.isNotEmpty(c.destCtrCd)) {
              ctrInfo = ctrList.find(v => v.ctrCd === c.destCtrCd)
              if (ctrInfo !== undefined || ctrInfo !== null) {
                let { ctrEnm } = ctrInfo
                c.destCtrEnm = ctrEnm
              }

              if (this.isNotEmpty(c.destPlcCd) && this.plcList[c.destCtrCd] !== undefined) {
                plcInfo = this.plcList[c.destCtrCd].find(v => v.plcCd === c.destPlcCd)

                if (plcInfo !== undefined || plcInfo !== null) {
                  c.destPlcEnm = plcInfo.plcEnm
                }
              }
            }

            paramList.push({ ...def, ...c })
          })
        } else {
          paramList.push({ ...def })
        }
      } else {
        paramList.push({ ...def })
      }

      this.initPlcList()
    },
    initPlcList () {
      const paramList = this.paramList || []
      const searchParam = this.searchParam
      paramList.forEach((c, idx) => {
        if (this.isNotEmpty(c.startCtrCd) && searchParam.arrCtrCd.indexOf(c.startCtrCd) === -1) {
            searchParam.arrCtrCd.push(c.startCtrCd)
        }

        if (this.isNotEmpty(c.destCtrCd) && searchParam.arrCtrCd.indexOf(c.destCtrCd) === -1) {
          searchParam.arrCtrCd.push(c.destCtrCd)
        }
      })
      const arrCtrCd = searchParam.arrCtrCd

      if (arrCtrCd.length > 0) {
        //this.getPlcList('init')
      }
    },
    updatePlcList (e, idx, flag) {
      const ctrList = this.ctrList
      const ctrEnm = e.target.value
      const ctrInfo = ctrList.find(c => c.ctrEnm === ctrEnm)

      if (ctrInfo === undefined || ctrInfo === null) {
        alert('국가를 다시 입력해주세요.')
        e.target.value = ''
        return
      }
      const ctrCd = ctrInfo.ctrCd
      const paramInfo = this.paramList[idx]

      if (flag === 'start') {
        paramInfo.startCtrCd = ctrCd
        paramInfo.startPlcCd = ''
        paramInfo.startPlcEnm = ''
      } else {
        paramInfo.destCtrCd = ctrCd
        paramInfo.destPlcCd = ''
        paramInfo.destPlcEnm = ''
      }

      if (this.isNotEmpty(ctrCd) && (this.plcList[ctrCd] === null || this.plcList[ctrCd] === undefined)) {
        const searchParam = this.searchParam
        searchParam.arrCtrCd = []
        searchParam.arrCtrCd.push(ctrCd)

        this.getPlcList()
      }
    },
    async getPlcList (initFlag) {
      await axios
        .get('http://localhost:9080/api/VOSD100/plcs', { params: { arrCtrCd: this.searchParam.arrCtrCd.join(',') } })
        .then(res => {
          const result = res.data
          const list = result.data

          this.plcList = { ...this.plcList, ...list }

          if (initFlag === 'init') {
            const paramList = this.paramList
            paramList.forEach(c => {
              if (this.isNotEmpty(c.startCtrCd) && this.isNotEmpty(c.startPlcCd)) {
                const ctrPlcList = this.plcList[c.startCtrCd]

                if (ctrPlcList !== null && ctrPlcList !== undefined) {
                  const ctrPlcInfo = ctrPlcList.find(v => v.plcCd === c.startPlcCd)
                  if (ctrPlcInfo !== undefined || ctrPlcInfo !== null) {
                    let { plcEnm } = ctrPlcInfo
                    c.startPlcEnm = plcEnm
                  }
                }
              }

              if (this.isNotEmpty(c.destCtrCd) && this.isNotEmpty(c.destPlcCd)) {
                const ctrPlcList = this.plcList[c.destCtrCd]

                if (ctrPlcList !== null && ctrPlcList !== undefined) {
                  const ctrPlcInfo = ctrPlcList.find(v => v.plcCd === c.destPlcCd)
                  if (ctrPlcInfo !== undefined || ctrPlcInfo !== null) {
                    let { plcEnm } = ctrPlcInfo
                    c.destPlcEnm = plcEnm
                  }
                }
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    addCountry () {
      const paramList = this.paramList
      const length = paramList.length

      if (this.maxRowCnt <= length) {
        alert('최대' + this.maxRowCnt + '까지 추가 가능합니다.')
        return
      }

      paramList.push(
        {
          startCtrCd: '',
          startPlcCd: '',
          destCtrCd: '',
          destPlcCd: '',
          startCtrEnm: '',
          destCtrEnm: '',
          startPlcEnm: '',
          destPlcEnm: ''
        }
      )
    },
    removeCountry (idx) {
      const paramList = this.paramList
      paramList.splice(idx, 1)
    },
    changeStartPlcList (e) {
      if (this._events.parentStartPlcChange !== undefined && this.isNotEmpty(e.target.value)) {
        this.$emit('parentStartPlcChange', e)
      }
    },
    changeDestPlcList (e) {
      if (this._events.parentDestPlcChange !== undefined && this.isNotEmpty(e.target.value)) {
        this.$emit('parentDestPlcChange', e)
      }
    }
  }
}
</script>
