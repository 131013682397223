import Send from '../../trans.client.js'

export default {
    //DEM/DET 계산기 FREEDAY 리스트
    getFreeDay (formData) {
      return Send({
        method: 'get',
        url: '/trans/info/dem-det-freeday',
        params: {
          ctrCd: formData.ctrCd,
          portCd: formData.portCd,
          cntrTypCd: formData.cntrTypCd,
          cgoTypCd: formData.cgoTypCd
        }
      }).catch((err) => {
        console.log(err)
      })
    },

    //DEM/DET TARIFF 리스트
    getDemDetTariffInfoList (formData) {
        return Send({
          method: 'get',
          url: '/trans/info/dem-det-tariff-cal',
          params: {
            ctrCd: formData.ctrCd,
            portCd: formData.portCd,
            eiCatCd: formData.eiCatCd,
            demDetCatCd: formData.demDetCatCd
          }
        }).catch((err) => {
          console.log(err)
        })
    },

    // 고객센터 > 정보조회 > DEM/DET Tariff 조회
    popupfreetimeinquiry (formData) {
      return Send({
        method: 'get',
        url: '/trans/info/dem-det-tariff',
        params: formData
      }).catch((err) => {
        console.log(err)
      })
    }
}
