var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-container",
        { attrs: { fluid: "" } },
        [
          _c(
            "b-row",
            { staticClass: "mt-4 mb-4" },
            [_c("b-col", [_c("h2", [_vm._v("Main Page")])])],
            1
          ),
          _c(
            "b-row",
            { staticClass: "mt-4 mb-4" },
            [_c("b-table", { attrs: { hover: "", items: _vm.list } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }