<template>
  <div>
    <b-container fluid>

      <b-row class="mt-4 mb-4">
        <b-col>
          <h2>다국어 Page {{$t('label.TEST01')}}</h2>
        </b-col>
      </b-row>
      <b-row class="mt-4 mb-4">
        <b-col>
          <span>{{$t('label.TEST02', {langcd: $i18n.locale})}}</span>
        </b-col>
      </b-row>
      <b-row>
        <b-button
          class="ml-2"
          type="submit"
          variant="success"
          @click="$i18n.locale='ko-KR'"
        >한국어</b-button>
        <b-button
          class="ml-2"
          type="submit"
          variant="success"
          @click="$i18n.locale='en-US'"
        >영어</b-button>
        <b-button
          class="ml-2"
          type="submit"
          variant="success"
          @click="$i18n.locale='ja-JP'"
        >일본어</b-button>
        <b-button
          class="ml-2"
          type="submit"
          variant="success"
          @click="$i18n.locale='zh-CN'"
        >중국어</b-button>
      </b-row>
      <hr/>
      <form class="row g-3">
        <div class="col-md-4">
          <label for="first_name" class="form-label">First name</label>
          <input type="text" id="first_name" v-model="params.first_name"
              :class="errerMsg.first_name != undefined && errerMsg.first_name != '' ? 'form-control error-input' : 'form-control'"
              >
          <div class="error-message" v-if="errerMsg.first_name != undefined && errerMsg.first_name != ''">
            {{errerMsg.first_name}}
          </div>
        </div>
        <div class="col-md-4">
          <label for="last_name" class="form-label">Last name</label>
          <input type="text" class="form-control" id="last_name" v-model="params.last_name"
              :class="errerMsg.last_name != undefined && errerMsg.last_name != '' ? 'form-control error-input' : 'form-control'"
              >
          <div class="error-message" v-if="errerMsg.last_name != undefined && errerMsg.last_name != ''">
            {{$t('label.' + errerMsg.last_name)}}
          </div>
        </div>

        <div class="col-12 mt-1">
          <h2>{{text_message}}</h2>
        </div>

        <div class="col-12 mt-1">
          <label>&nbsp;</label>
          <b-button
            type="button"
            variant="success"
            @click="test()"
          >Test</b-button>
        </div>
      </form>
    </b-container>
  </div>
</template>

<script>
import { rootComputed, rootMethods } from '@/store/helpers'
export default {
  created () {
  },
  computed: {
    ...rootComputed
  },
  data () {
    return {
      errerMsg: {
        first_name: '',
        last_name: ''
      },
      text_message: '',
      params: {
        first_name: '',
        last_name: ''
      }
    }
  },
  methods: {
    ...rootMethods,
    test () {
      let isValidation = true
      if (this.params.first_name === '') {
        this.errerMsg.first_name = this.$t('label.TEST03', { filed: 'first name' })
        isValidation = false
      } else {
        this.errerMsg.first_name = ''
      }

      if (this.params.last_name === '') {
        this.errerMsg.last_name = 'TEST04'
        isValidation = false
      } else {
        this.errerMsg.last_name = ''
      }

      if (!isValidation) {
        return
      }

      this.text_message = this.$t('label.TEST05', this.params)
    }
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
