<template>
  <div class="popup_wrap" style="width:900px;">
    <button class="layer_close" @click.prevent="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('cp.CTRL020P080.001') }}</h1>
      <div class="content_box">
        <div id="realgrid" style="width: 100%; height: 600px" />
      </div>
    </div>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import cpCommonInfo from '@/api/rest/cp/cpCommonInfo'
let gridView = GridView
let provider = LocalDataProvider

const fields = [
  { fieldName: 'actionCd', dataType: 'text' },
  { fieldName: 'actionNm', dataType: 'text' },
  { fieldName: 'shootPoint', dataType: 'text' },
  { fieldName: 'shootResult', dataType: 'text' },
  { fieldName: 'category', dataType: 'text' },
  { fieldName: 'apiCd', dataType: 'text' },
  { fieldName: 'apiFunction', dataType: 'text' },
  { fieldName: 'ckPeriodDev', dataType: 'text' },
  { fieldName: 'ckUpdateApplyDate', dataType: 'text' },
  { fieldName: 'inEtdDate', dataType: 'text' },
  { fieldName: 'inEtaDate', dataType: 'text' },
  { fieldName: 'ckPorCtr', dataType: 'text' },
  { fieldName: 'inPor', dataType: 'text' },
  { fieldName: 'inTs1', dataType: 'text' },
  { fieldName: 'inTs2', dataType: 'text' },
  { fieldName: 'inTs3', dataType: 'text' },
  { fieldName: 'ckDlyCtr', dataType: 'text' },
  { fieldName: 'inDly', dataType: 'text' },
  { fieldName: 'inRtVesVoyage', dataType: 'text' },
  { fieldName: 'inCntrSizeTypeDgoog', dataType: 'text' },
  { fieldName: 'ckBsaRate', dataType: 'text' },
  { fieldName: 'ckBsaSign', dataType: 'text' }
]

const columns = [
  { name: 'actionCd', fieldName: 'actionCd', header: { text: app.$t('cp.CTRL020P080.002') }, editable: false, width: 10 },
  { name: 'actionNm', fieldName: 'actionNm', header: { text: app.$t('cp.CTRL020P080.003') }, editable: false, width: 25, styleName: 'text_left' },
  { name: 'shootPoint', fieldName: 'shootPoint', header: { text: app.$t('cp.CTRL020P080.004') }, editable: false, width: 35, styleName: 'text_left', renderer: { showTooltip: true } },
  { name: 'shootResult', fieldName: 'shootResult', header: { text: app.$t('cp.CTRL020P080.005') }, editable: false, width: 35, styleName: 'text_left', renderer: { showTooltip: true } }//,
]

export default {
  name: 'ActionListPopup',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          actionCd: ''
        }
      }
    }
  },
  data: function () {
    return {
      list: []
    }
  },
  computed: {
  },
  watch: {
  },
  created () {
  },
  mounted: function () {
    const $vm = this
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    gridView.setDataSource(provider)
    gridView.header.heights = [30]
    gridView.displayOptions.rowHeight = 34
    gridView.displayOptions.fitStyle = 'even'
    gridView.footers.visible = false
    gridView.setStateBar({
      visible: false
    })
    gridView.setCheckBar({
      visible: false
    })
    gridView.footers.visible = false
    provider.setFields(fields)
    gridView.setColumns(columns)
    gridView.displayOptions.selectionStyle = 'rows'
    gridView.setSortingOptions({
      keepFocusedRow: true,
      style: 'inclusive',
      showSortOrder: true
    })

    // 액션코드 중복 판별 변수
    // const actionCdNow = this.parentInfo.actionCd
    // const usedActionCdList = this.parentInfo.usedActionCdList

    gridView.onCellDblClicked = function (grid, clickData) {
      if (clickData.cellType === 'data') {
        $vm.$emit('close', $vm.list[clickData.dataRow])
        $vm.layerClose()

        // 액션코드 중복 체크
        // const actionCdClicked = $vm.list[clickData.dataRow].actionCd
        // if (actionCdNow !== actionCdClicked && usedActionCdList.indexOf(actionCdClicked) > -1) {
        //   $vm.openAlert(app.$t('cp.CTRL020P080.008'))
        // } else {
        //   $vm.$emit('close', $vm.list[clickData.dataRow])
        //   $vm.layerClose()
        // }
      }
    }
    $vm.searchData()
  },
  methods: {
    searchData () {
      cpCommonInfo.actionList().then(response => {
        this.list = response.data
        provider.setRows(this.list)
        if (this.parentInfo.actionCd !== '') {
          this.list.forEach((item, index) => {
            if (this.parentInfo.actionCd === item.actionCd) {
              gridView.setCurrent({ itemIndex: index }, true)
            }
          })
        }
      }).catch(err => {
        console.log(err)
      })
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('cp.COMMON.005'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    layerClose () {
      $('.popup_dim').fadeOut()
      $('body,html').removeAttr('style')
      return false
    }
  }
}
</script>
