<template>
  <div>
    <b-container
      fluid
      class="pt-3 pb-3 content_box mt10">
      <b-row class="mt-4 mb-4">
        <b-col>
          <h2>Grid Sample </h2>
        </b-col>
      </b-row>
      <b-row class="mt-4 mb-4">
        <b-col class="text-right">
          <b-button
            type="submit"
            variant="info"
            @click="searchData()">조회</b-button>
          <b-button
            v-if="!isFocus"
            class="ml-2"
            type="submit"
            variant="info"
            @click="focusOption()">포커스 데이터 가져오기 버튼 열기</b-button>
          <b-button
            v-if="isFocus"
            class="ml-2"
            type="submit"
            variant="info"
            @click="focusOption()">포커스 데이터 가져오기 버튼 닫기</b-button>
          <b-button
            v-if="!isEdit"
            class="ml-2"
            type="submit"
            variant="info"
            @click="editOption()">수정가능 모드</b-button>
          <b-button
            v-if="isEdit"
            class="ml-2"
            type="submit"
            variant="info"
            @click="editOption()">수정가능 모드 해제</b-button>
          <b-button
            class="ml-2"
            type="submit"
            variant="info"
            @click="registryData()">등록</b-button>
          <b-button
            v-if="!isDelete"
            class="ml-2"
            type="submit"
            variant="info"
            @click="deleteOption()">삭제가능 모드</b-button>
          <b-button
            v-if="isDelete"
            class="ml-2"
            type="submit"
            variant="info"
            @click="deleteOption()">삭제가능 모드 해제</b-button>
          <b-button
            class="ml-2"
            type="submit"
            variant="info"
            @click="modifyData()">그리드 비우기</b-button>
        </b-col>
      </b-row>
      <b-row class="mt-4 mb-4">
        <b-col>
          <b-button
            v-if="isEdit"
            class="ml-2"
            type="submit"
            variant="info"
            @click="btnBeginInsertRow()">행삽입 ( 지정행 이전 )</b-button>
          <b-button
            v-if="isEdit"
            class="ml-2"
            type="submit"
            variant="info"
            @click="btnBeginInsertRowShift()">행삽입 ( 지정행 이후 )</b-button>
          <b-button
            v-if="isEdit"
            class="ml-2"
            type="submit"
            variant="info"
            @click="btnBeginAppendRow()">행추가</b-button>
          <b-button
            v-if="isDelete"
            class="ml-2"
            type="submit"
            variant="info"
            @click="softDeleting()">SoftDelete 설정</b-button>
          <b-button
            v-if="isDelete"
            class="ml-2"
            type="submit"
            variant="info"
            @click="deleteCreated()">DeleteCreated 설정</b-button>
          <b-button
            v-if="isDelete"
            class="ml-2"
            type="submit"
            variant="info"
            @click="hideDeletedRows()">HideDeletedRows 설정</b-button>
          <b-button
            v-if="isDelete"
            class="ml-2"
            type="submit"
            variant="info"
            @click="btnDeleteSelection()">Delete Section</b-button>
          <b-button
            v-if="isDelete"
            class="ml-2"
            type="submit"
            variant="info"
            @click="btnRemoveRow()">Delete Row</b-button>
          <b-button
            v-if="isFocus"
            class="ml-2"
            type="submit"
            variant="info"
            @click="getValue()">getValue</b-button>
          <b-button
            v-if="isFocus"
            class="ml-2"
            type="submit"
            variant="info"
            @click="getValues()">getValues</b-button>
          <b-button
            v-if="isFocus"
            class="ml-2"
            type="submit"
            variant="info"
            @click="getJsonRow()">getJsonRow()</b-button>
          <b-button
            v-if="isFocus"
            class="ml-2"
            type="submit"
            variant="info"
            @click="getRows()">getRows()</b-button>
          <b-button
            v-if="isFocus"
            class="ml-2"
            type="submit"
            variant="info"
            @click="getJsonRows()">getJsonRows()</b-button>
          <b-button
            v-if="isFocus"
            class="ml-2"
            type="submit"
            variant="info"
            @click="getFieldValues()">getFieldValues</b-button>
        </b-col>
      </b-row>

      <hr/>

      <div class="row">
        <div class="col-md-4">
          <label for="filter" class="form-label">Filter : FirstName</label>
          <input type="text" id="filter" class="form-control" v-model="txtFilter" @keyup="changeFilter()" autocomplete="off">
        </div>
      </div>

      <hr/>

      <div id="realgrid" style="width: 100%; height: 600px"></div>

      <b-row class="mt-4 mb-4">
        <b-col v-if="isSearch" class="text-left">
          <b-button
            type="submit"
            class="ml-2"
            variant="info"
            @click="setPaging()">페이징1</b-button>
        </b-col>
        <b-col v-if="isPaging1" class="text-left">
          <b-button
            v-if="isPaging1"
            type="submit"
            class="ml-2"
            variant="info"
            @click="setPrevPage()">이전 페이지</b-button>
          <span >{{currentPage + 1}}</span>
          /
          <span >{{totalPage}}</span>
          <b-button
            type="submit"
            class="ml-2"
            variant="info"
            @click="setNextPage()">다음 페이지</b-button>
        </b-col>
      </b-row>

      <hr/>

      <textarea v-model="this.eventLog" style="width:100%; height:60px; border: 2px solid #5d8cc9"></textarea>
    </b-container>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import { rootComputed, rootMethods } from '@/store/helpers'
import mockData from '@/api/mock-data'
let gridView = GridView
let provider = LocalDataProvider

export const fields = [
  { fieldName: 'id', dataType: 'number' },
  { fieldName: 'first_name', dataType: 'text' },
  { fieldName: 'last_name', dataType: 'text' },
  { fieldName: 'email', dataType: 'text' },
  { fieldName: 'gender', dataType: 'text' },
  { fieldName: 'ip_address', dataType: 'text' },
  { fieldName: 'Monetary', dataType: 'text' },
  { fieldName: 'KorCountry', dataType: 'text' },
  { fieldName: 'dateTime2' },
  { fieldName: 'dateTime3' }
]

export const columns = [
  { name: 'id', fieldName: 'id', type: 'number', header: { text: '아이디' } },
  { name: 'first_name', fieldName: 'first_name', type: 'text', header: { text: 'FirstName' } },
  { name: 'last_name', fieldName: 'last_name', type: 'text', header: { text: 'LastName' } },
  // { name: 'email', fieldName: 'email', type: 'text', header: { text: '이메일' } },
  {
    name: 'email2',
    fieldName: 'email',
    type: 'text',
    header: { text: '이메일' },
    renderer: {
      type: 'html',
      callback: (grid, cell, w, h) => {
        return cell.value
      }
    }
  },
  { name: 'gender', fieldName: 'gender', type: 'text', header: { text: '성별' } },
  {
    name: 'Monetary',
    fieldName: 'Monetary',
    type: 'data',
    width: '200',
    values: ['EUR', 'USD', 'HKD', 'KRW'],
    labels: ['EUR', 'USD', 'HKD', 'KRW'],
    renderer: {
      type: 'html',
      callback: function (grid, cell, w, h) {
        let str = ''
        const data = (cell.value && cell.value.split(',')) || []
        const labels = cell.dataColumn.labels
        const values = cell.dataColumn.values
        for (let i = 0; i < labels.length; i++) {
          const checked = data.indexOf(values[i]) >= 0 ? 'checked' : ''
          str = str + "<input type='checkbox' value = '" + values[i] + "'" + checked + " onclick='app.valuecheck(" + cell.index.dataRow + ", event)' />" + labels[i]
        }
        return str
      }
    }
  },
  { name: 'KorCountry', fieldName: 'KorCountry', type: 'data', width: '150', renderer: 'renderer_imgbtn', styleName: 'left-column custom_renderer', header: { text: '투자국가', styleName: 'orange-column' } },
  {
    name: 'dateTime2',
    fieldName: 'dateTime2',
    width: '150',
    height: '50',
    header: {
      text: 'bootstrap datepicker'
    },
    editor: {
      type: 'btdate',
      btOptions: {
        startView: 0,
        minViewMode: 0,
        todayBtn: 'linked',
        language: 'ko',
        todayHighlight: true,
        inline: true,
        sideBySide: true
      },
      dropDownWhenClick: true,
      viewGridInside: true,
      textReadOnly: true,
      datetimeFormat: 'yyyy-MM-dd',
      yearNavigation: true,
      mask: {
        editMask: '9999-99-99',
        includedFormat: true
      }
    }
  },
  {
    name: 'dateTime3',
    fieldName: 'dateTime3',
    width: '110',
    header: {
      text: 'datepicker'
    },
    editor: {
      type: 'date', maxLength: 6, yearNavigation: true
    }

  }
]

export default {
  name: 'RealGrid',
  created () {
    // this.getList()
    this.eventLog = ''
    window.functionOutsideVue(this)
  },
  data: function () {
    return {
      txtFilter: '',
      isEdit: false,
      isDelete: false,
      isFocus: false,
      isSearch: false,
      isPaging1: false,
      currentPage: 0,
      totalPage: 0,
      pageCount: 0,
      eventLog: '',
      list: mockData.USER_LIST,
      gridList: []
    }
  },
  computed: {
    ...rootComputed
  },

  methods: {
    ...rootMethods,
    btnBeginInsertRow: function btnBeginInsertRow () {
      const curr = gridView.getCurrent()
      gridView.beginInsertRow(Math.max(0, curr.itemIndex))
      gridView.showEditor()
      gridView.setFocus()
    },

    btnBeginInsertRowShift: function btnBeginInsertRowShift () {
      const curr = gridView.getCurrent()
      gridView.beginInsertRow(Math.max(0, curr.itemIndex), true)
      gridView.showEditor()
      gridView.setFocus()
    },

    btnBeginAppendRow: function btnBeginAppendRow () {
      gridView.beginAppendRow()
      gridView.showEditor()
      gridView.setFocus()
    },
    focusOption: function () {
      this.isFocus = !this.isFocus
    },
    editOption: function () {
      if (!this.isEdit) {
        this.isEdit = true

        gridView.setEditOptions({
          insertable: true,
          appendable: true
        })
      } else {
        this.isEdit = false
        gridView.setEditOptions({
          insertable: false,
          appendable: false
        })
      }
    },
    deleteOption: function () {
      if (!this.isDelete) {
        this.isDelete = true

        gridView.setEditOptions({
          deletable: true
        })
      } else {
        this.isDelete = false
        gridView.setEditOptions({
          deletable: false
        })
      }
    },
    searchData: function () {
      this.isSearch = true
      this.gridList = this.list
      this.txtFilter = ''
      console.log('searchData')
      provider.setRows(this.gridList)
    },
    deleteData: function () {

    },
    modifyData: function () {
      provider.clearRows()
    },
    registryData: function () {
      console.log('test')
      const commitResult = gridView.commit()
      if (commitResult) {
        alert('변경되었습니다.')
      } else {
        alert('변경된 내용이 없습니다.')
      }
    },
    addLog: function (log) {
      this.eventLog = this.eventLog + '\n' + log
      console.log('log = ' + this.eventLog)
    },
    softDeleting: function () {
      provider.setOptions({
        softDeleting: true
      })
    },
    deleteCreated: function () {
      provider.setOptions({
        deleteCreated: true
      })
    },
    hideDeletedRows: function () {
      gridView.setOptions({
        hideDeletedRows: true
      })
    },
    btnDeleteSelection: function () {
      gridView.deleteSelection(true)
    },
    btnRemoveRow: function () {
      var curr = gridView.getCurrent()
      provider.removeRow(curr.dataRow)
    },
    getValue: function () {
      var current = gridView.getCurrent()
      var value = provider.getValue(current.dataRow, current.fieldName)
      alert(
        'row: ' +
        current.dataRow +
        '\n' +
        'field: ' +
        current.fieldName +
        '\n' +
        'value: ' +
        value
      )
    },
    getValues: function () {
      var current = gridView.getCurrent()
      alert(
        'row: ' + current.dataRow + '\n' + provider.getValues(current.dataRow)
      )
    },
    getJsonRow: function () {
      var current = gridView.getCurrent()
      var jsonData = provider.getJsonRow(current.dataRow)
      alert('row: ' + current.dataRow + '\n' + JSON.stringify(jsonData))
    },

    getRows: function () {
      alert(provider.getRows(0, -1))
    },
    getJsonRows: function () {
      alert(JSON.stringify(provider.getJsonRows(0, -1)))
    },
    getFieldValues: function () {
      var current = gridView.getCurrent()
      alert(
        'field: ' +
        current.fieldName +
        '\n' +
        'fieldValues: ' +
        provider.getFieldValues(current.fieldName, 0, -1)
      )
    },
    valuecheck: function (dataRow, event) {
      console.log('value  check')
      console.log(JSON.stringify(dataRow))
      console.log(JSON.stringify(event))
      const target = event.target
      const value = target.value
      const dataValue2 = provider.getValue(dataRow, 'Monetary')
      console.log('dataValue2' + JSON.stringify(dataValue2))
      const dataValue = provider.getValue(dataRow, 'Monetary').split(',')
      const idx = dataValue.indexOf(value)
      if (target.checked) {
        if (idx < 0) {
          dataValue.push(value)
        }
      } else {
        if (idx >= 0) { dataValue.splice(idx, 1) }
      }
      console.log(JSON.stringify(dataRow))
      provider.setValue(dataRow, 'Monetary', dataValue.join(','))
    },
    test: function () {
      alert('test')
    },
    gridLogging: function () {
    },
    gridCellStyle: function () {
      gridView.displayOptions.useFocusClass = true
      gridView.setCellStyleCallback(function (grid, dataCell) {
        var ret = {}
        if (dataCell.value === 'Non-binary') {
          ret.styleName = 'orange-color'
          ret.editable = false
        }
        return ret
      })
    },
    customRender: function () {
      gridView.registerCustomRenderer('renderer_imgbtn', {
        initContent: function (parent) {
          var span = this._span = document.createElement('span')
          span.className = 'custom_render_span'
          parent.appendChild(span)
          parent.appendChild(this._button1 = document.createElement('span'))
          parent.appendChild(this._button2 = document.createElement('span'))
        },
        canClick: function () {
          console.log('can click')
          return true
        },
        clearContent: function (parent) {
          console.log('DISPOSED......')
          parent.innerHTML = ''
        },
        render: function (grid, model, width, height, info) {
          info = info || {}
          var span = this._span
          // text설정.
          span.textContent = model.value

          this._value = model.value
          this._button1.className = 'custom_search custom-hover' + (info.focused ? ' custom-focused' : '')
          this._button2.className = 'custom_popup custom-hover' + (info.focused ? ' custom-focused' : '') + (info.focused ? ' custom-focused' : '') + (info.focused ? ' custom-focused' : '') + (info.focused ? ' custom-focused' : '') + (info.focused ? ' custom-focused' : '') + (info.focused ? ' custom-focused' : '')
        },
        click: function (event) {
          if (event.target === this._button1) {
            alert('조회버튼: ' + this._value)
          } else if (event.target === this._button2) {
            var i = i + 1
            alert('팝업버튼: ' + this._value)
          }
          event.preventDefault()
        }
      })
    },
    setPaging: function () {
      gridView.setPaging(true, 10)
      this.currntPage = gridView.getPage()
      this.totalPage = gridView.getPageCount()
      this.isPaging1 = !this.isPaging1
    },
    setPrevPage: function () {
      this.currentPage = gridView.getPage()
      gridView.setPage(this.currentPage - 1)
    },
    setNextPage: function () {
      this.currentPage = gridView.getPage()
      gridView.setPage(this.currentPage + 1)
    },
    changeFilter () {
      if (this.list === undefined || this.list.length === 0) {
        return
      }

      let arrFilter

      if (this.txtFilter.indexOf(',') !== -1) {
        arrFilter = this.txtFilter.split(',')
      } else {
        arrFilter = [this.txtFilter]
      }

      if (this.txtFilter === '') {
        this.gridList = this.list
      } else {
        this.gridList = this.list.filter(vo => {
          const firstName = vo.first_name.toLowerCase()
          let txtFilter
          for (let i in arrFilter) {
            txtFilter = arrFilter[i].toLowerCase().replace(/^\s+|\s+$/g, '')
            if (txtFilter === '') {
              continue
            }
            if (firstName.indexOf(txtFilter) !== -1) {
              return true
            }
          }
          return false
        })
      }

      provider.setRows(this.gridList)
    }
  },
  watch: {
    list: function () {
      console.log('watch')
    }
  },
  mounted: function () {
    const $vm = this
    // {"id":291,"first_name":"Burlie","last_name":"Pharo","email":"bpharo82@senate.gov","gender":"Female","ip_address":"247.206.113.77"},

    // this.$nextTick(function () {
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    gridView.setDataSource(provider)
    // 그리드 필드 생성
    provider.setFields(fields)
    console.log('mounted')
    // columns 명
    gridView.setColumns(columns)
    $vm.gridLogging()
    $vm.gridCellStyle()
    $vm.customRender()
  }
}
</script>
<style>
/*https://docs.realgrid.com/guides/renderer/custom-renderer-img-buttons*/
.custom_none {
  display:none;
}

.custom_render_span {
  flex: 1;
  text-align: left;
  overflow: hidden;
}

.custom_renderer > div {
  display: flex;
  align-items: center;
}

.custom_renderer .rg-renderer:hover .custom-hover {
  visibility: visible;
}

.custom_renderer .rg-renderer .custom-hover {
  visibility: visible;
}

.custom_renderer .rg-renderer .custom-focused {
  visibility: visible;
}

.custom_search {
  height: 26px;
  margin-left: 2px;
  background: url("https://docs.realgrid.com/public/images/btnImages/search_normal.png") center center no-repeat;
  flex: 0 0 45px;
}
.custom_search:hover {
  background: url("https://docs.realgrid.com/public/images/btnImages/search_hover.png") center center no-repeat;
}
.custom_search:active {
  background: url("https://docs.realgrid.com/public/images/btnImages/search_click.png") center center no-repeat;
}

.custom_popup {
  height: 26px;
  margin-left: 2px;
  background: url("https://docs.realgrid.com/public/images/btnImages/popup_normal.png") center center no-repeat;
  flex: 0 0 45px;
}
.custom_popup:hover {
  background: url("https://docs.realgrid.com/public/images/btnImages/popup_hover.png") center center no-repeat;
}
.custom_popup:active {
  background: url("https://docs.realgrid.com/public/images/btnImages/popup_click.png") center center no-repeat;
}

/*https://docs.realgrid.com/guides/grid-style/realgrid-2-custom-styles-3-data*/
.rg-focus {
  /* 포커스 셀의 색깔 변경 */
  border: 2px solid #00f;
}

/*https://docs.realgrid.com/guides/grid-style/realgrid-2-custom-styles-3-data*/
.rg-selection-cell {
  /* 선택 영역의 색깔 변경 */
  background: #0000ff40 !important;
  color: inherit;
}

.rg-selection {
  background: rgba(21, 139, 229, 0.2) !important;
  border: 1px solid rgba(21, 66, 229, 0.67);
}
/*https://docs.realgrid.com/guides/grid-style/grid-style*/
.orange-column {
  background: #ffff00
}

.cursor {
  cursor: crosshair;
}

/*https://docs.realgrid.com/guides/grid-style/cell-dynamic-style*/
.orange-color{
  background: #ff8b00;
}

/*focus https://docs.realgrid.com/guides/grid-style/focus-class */
.rg-focused-cell{
  background: #FF00ff;
}

.rg-focused-row{
  background: #FFFF00;
}
/*https://forum.realgrid.com/topics/pkpSNXphwB44bHPvg*/
.datepicker-inline {
  width: 200px;
}
</style>
