<template>
  <div class="container_ekmtc">
    <div class="content">
      <h1 class="page_title">팝업 리스트</h1>

      <win-layer-pop class="sample_popup_list">
        <component
          v-if="customComponent"
          :is="customComponent"
          @close="closePopup"
          :parent-info="parentInfo"
        />
      </win-layer-pop>

      <div class="content_box">
        <h1 class="page_title">COMMON =&gt; {{ urlInfo.commonUrl }}</h1>
        <h1 class="page_title">TRANS =&gt; {{ urlInfo.transUrl }}</h1>
        <h1 class="page_title">REPORT =&gt; {{ urlInfo.reportUrl }}</h1>
        <h1 class="page_title">CTR_CD =&gt; {{ memberDetail.userCtrCd }}</h1>
        <h1 class="page_title">PLC_CD =&gt; {{ memberDetail.bkgPlcCd }}</h1>
      </div>

      <div class="content_box">
        <h1 class="page_title">Arrival Notice</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="200">
                <col width="200">
                <col width="80">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>BKG NO</th>
                  <th>BL NO</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addArrivalNotice()">plus</button></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.ArrivalNoticePop.items" :key="'ArrivalNoticePop_' + idx">
                  <td><input type="text" v-model="vo.bkgNo" /></td>
                  <td><input type="text" v-model="vo.blNo" /></td>
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeArrivalNotice(idx)">minus</button></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180">
                <col width="180">
                <col width="180">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USR_NO</th>
                  <th>CTR_CD</th>
                  <th>BKG_PLC_CD</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.ArrivalNoticePop.testUsrNo" /></td>
                  <td><input type="text" v-model="popupParams.ArrivalNoticePop.testCtrCd" /></td>
                  <td><input type="text" v-model="popupParams.ArrivalNoticePop.testBkgPlcCd" /></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('ArrivalNoticePop')">A/N 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>

      <div class="content_box mt5">
        <h1 class="page_title">Delivery Order</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="200">
                <col width="200">
                <col width="80">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>B/L No.</th>
                  <th>D/O No.</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addDeliveryOrder()">plus</button></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.DeliveryOrderPop.items" :key="'DeliveryOrderPop_' + idx">
                  <td><input type="text" v-model="vo.blNo" /></td>
                  <td><input type="text" v-model="vo.doRno" /></td>
                  <td style="text-align:center"><button type="button" class="tbl_icon minus" @click.prevent="removeDeliveryOrder(idx)">minus</button></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <p class="comment" style="font-weight: bold;">※나라가 KR, ID 에서만 정상 작동합니다.</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180">
                <col width="180">
                <col width="180">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USR_NO</th>
                  <th>CTR_CD</th>
                  <th>AGT_CD</th>
                  <th>BKG_PLC_CD<span style="color:red;font-weight:bold;">(ID)</span></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.DeliveryOrderPop.testUsrNo" /></td>
                  <td><input type="text" v-model="popupParams.DeliveryOrderPop.testCtrCd" /></td>
                  <td><input type="text" v-model="popupParams.DeliveryOrderPop.testAgtCd" /></td>
                  <td><input type="text" v-model="popupParams.DeliveryOrderPop.testBkgPlcCd" /></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('DeliveryOrderPop')">D/O 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>

      <div class="content_box mt5">
        <h1 class="page_title">BL Invoice Freight</h1>
        <ul class="ul_box" style="overflow-x: auto;">
          <li class="fl" style="width:70%">
            <table class="tbl_type">
              <colgroup>
                <col width="140">
                <col width="140">
                <col width="140">
                <col width="140">
                <col width="140">
                <col width="140">
                <col width="80">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>Invoice No.</th>
                  <th>TO</th>
                  <th>Remark</th>
                  <th>Filter</th>
                  <th>B/L No</th>
                  <th>Esign (PH 에서만 사용)</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addBlInvoiceFreight()">plus</button></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.BlInvoiceFreightPop.items" :key="'BlInvoiceFreightPop_' + idx">
                  <td><input type="text" v-model="vo.invIssNo" /></td>
                  <td><input type="text" v-model="vo.to" /></td>
                  <td><input type="text" v-model="vo.remark" /></td>
                  <td><input type="text" v-model="vo.filter" /></td>
                  <td><input type="text" v-model="vo.blNo" /></td>
                  <td><input type="text" v-model="vo.esign" /></td>
                  <td style="text-align:center"><button type="button" class="tbl_icon minus" @click.prevent="removeBlInvoiceFreight(idx)">minus</button></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180">
                <col width="180">
                <col width="180">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>CTR_CD</th>
                  <th>AGT_CD</th>
                  <th>BKG_PLC_CD</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.BlInvoiceFreightPop.testCtrCd" /></td>
                  <td><input type="text" v-model="popupParams.BlInvoiceFreightPop.testAgtCd" /></td>
                  <td><input type="text" v-model="popupParams.BlInvoiceFreightPop.testBkgPlcCd" /></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('BlInvoiceFreightPop')">BL Invoice Freight 팝업</button>
            </div>
          </li>
          <li class="fr" style="width:29%">
          </li>
        </ul>
      </div>

      <div class="content_box mt5">
        <h1 class="page_title">Certificate</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="200">
                <col width="80">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>Certi No.</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addCertificate()">plus</button></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.CertificatePop.items" :key="'CertificatePop_' + idx">
                  <td><input type="text" v-model="vo.crtfNo" /></td>
                  <td style="text-align:center"><button type="button" class="tbl_icon minus" @click.prevent="removeCertificate(idx)">minus</button></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180">
                <col width="180">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>CTR_CD</th>
                  <th>BKG_PLC_CD</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.CertificatePop.testCtrCd" /></td>
                  <td><input type="text" v-model="popupParams.CertificatePop.testBkgPlcCd" /></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('CertificatePop')">Certificate 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>

      <div class="content_box mt5">
        <h1 class="page_title">B/L Copy</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="200">
                <!-- <col width="100"> -->
                <col width="100">
                <col width="100">
                <col width="100">
                <col width="80">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>B/L No.</th>
                  <!-- <th>Rider(Y/N)</th> -->
                  <th>Por Dly(Y/N)</th>
                  <th>No Cnee(Y/N)</th>
                  <th>No Notify(Y/N)</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addBlCopy()">plus</button></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.BlCopyPop.items" :key="'BlCopyPop_' + idx">
                  <td><input type="text" v-model="vo.blNo" /></td>
                  <!-- <td><input type="text" v-model="vo.riderYn" /></td> -->
                  <td><input type="text" v-model="vo.porDlyYn" /></td>
                  <td><input type="text" v-model="vo.noCnee" /></td>
                  <td><input type="text" v-model="vo.noNotify" /></td>
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeBlCopy(idx)">minus</button></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180">
                <col width="180">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>UsER_ID</th>
                  <th>CTR_CD</th>
                  <th>BKG_PLC_CD</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.BlCopyPop.testUserId" /></td>
                  <td><input type="text" v-model="popupParams.BlCopyPop.testCtrCd" /></td>
                  <td><input type="text" v-model="popupParams.BlCopyPop.testBkgPlcCd" /></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('BlCopyPop')">B/L Copy 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[S]Booking Confirmation -->
      <div class="content_box mt5">
        <h1 class="page_title">Booking Confirmation</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="200">
                <col width="*">
                <col width="80">
              </colgroup>
              <thead>
                <tr>
                  <th>BKG NO</th>
                  <th>GUBUN</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addBookingConfirm()">plus</button></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.BookingConfirmPop.items" :key="'BookingConfirmPop_' + idx">
                  <td><input type="text" v-model="vo.bkgNo" /></td>
                  <td><input type="text" v-model="vo.gubun" /></td>
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeBookingConfirm(idx)">minus</button></td>
                </tr>
              </tbody>
            </table>

            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180">
                <col width="180">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USR_NO</th>
                  <th>CTR_CD</th>
                  <th>BKG_PLC_CD</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.BookingConfirmPop.testUsrNo" /></td>
                  <td><input type="text" v-model="popupParams.BookingConfirmPop.testCtrCd" /></td>
                  <td><input type="text" v-model="popupParams.BookingConfirmPop.testBkgPlcCd" /></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('BookingConfirmPop')">Booking Confirmation 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]Booking Confirmation -->
      <!--[S]Delay Notice Out-->
      <div class="content_box mt5">
        <h1 class="page_title">Delay Notice(수출)</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="80px">
              </colgroup>
              <thead>
                <tr>
                  <th>DOC NO</th>
                  <th>PRT GUBUN</th>
                  <th>PARM1</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addDelayNotice('out')">plus</button></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.DelayNoticeOutPop.items" :key="'DelayNoticeOutPop_' + idx">
                  <td><input type="text" v-model="vo.docNo" /></td>
                  <td><input type="text" v-model="vo.prtGubun" /></td>
                  <td><input type="text" v-model="vo.parm1" /></td>
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeDelayNotice(idx, 'out')">minus</button></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('DelayNoticeOutPop')">Deplay Notice(수출) 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]Delay Notice Out-->
      <!--[S]Delay Notice In-->
      <div class="content_box mt5">
        <h1 class="page_title">Delay Notice(수입)</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="80px">
              </colgroup>
              <thead>
                <tr>
                  <th>DOC NO</th>
                  <th>PRT GUBUN</th>
                  <th>PARM1</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addDelayNotice('in')">plus</button></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.DelayNoticeInPop.items" :key="'DelayNoticeInPop_' + idx">
                  <td><input type="text" v-model="vo.docNo" /></td>
                  <td><input type="text" v-model="vo.prtGubun" /></td>
                  <td><input type="text" v-model="vo.parm1" /></td>
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeDelayNotice(idx, 'in')">minus</button></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('DelayNoticeInPop')">Deplay Notice(수입) 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]Delay Notice In-->
      <!--[S]Vessel Change Notice -->
      <div class="content_box mt5">
        <h1 class="page_title">Vessel Change Notice</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="80px">
              </colgroup>
              <thead>
                <tr>
                  <th>DOC NO</th>
                  <th>PRT GUBUN</th>
                  <th>PARM1</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addDelayNotice('in')">plus</button></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.VesselChangePop.items" :key="'DelayNoticeInPop_' + idx">
                  <td><input type="text" v-model="vo.docNo" /></td>
                  <td><input type="text" v-model="vo.prtGubun" /></td>
                  <td><input type="text" v-model="vo.parm1" /></td>
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeDelayNotice(idx, 'in')">minus</button></td>
                </tr>
              </tbody>
            </table>
            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180">
                <col width="180">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USR_NO</th>
                  <th>CTR_CD</th>
                  <th>BKG_PLC_CD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.VesselChangePop.testUsrNo" /></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('VesselChangePop')">Vessel Change 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]Vessel Change Notice -->
      <!--[S]Proforma Freight Invoice -->
      <div class="content_box mt5">
        <h1 class="page_title">Proforma Freight Invoice</h1>
        <ul class="ul_box" style="overflow-x: auto;">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="80px">
              </colgroup>
              <thead>
                <tr>
                  <th>BL_NO</th>
                  <th>PORT_CD</th>
                  <th>PAY_AGTORG_NO</th>
                  <th>CST_NO</th>
                  <th>TO</th>
                  <th>REMARK</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addProformaFreight()">plus</button></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.ProformaFreightPop.items" :key="'DelayNoticeInPop_' + idx">
                  <td><input type="text" v-model="vo.blNo" style="width:100px;" /></td>
                  <td><input type="text" v-model="vo.portCd" style="width:100px;" /></td>
                  <td><input type="text" v-model="vo.payAgtorgNo" style="width:100px;" /></td>
                  <td><input type="text" v-model="vo.cstNo" style="width:100px;" /></td>
                  <td><input type="text" v-model="vo.to" style="width:100px;" /></td>
                  <td><input type="text" v-model="vo.remark" style="width:100px;" /></td>
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeProformaFreight(idx)">minus</button></td>
                </tr>
              </tbody>
            </table>

            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180">
                <col width="180">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USR_NO</th>
                  <th>CTR_CD</th>
                  <th>AGT_CD</th>
                  <th>BKG_PLC_CD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.ProformaFreightPop.testUsrNo" /></td>
                  <td><input type="text" v-model="popupParams.ProformaFreightPop.testCtrCd" /></td>
                  <td><input type="text" v-model="popupParams.ProformaFreightPop.testAgtCd" /></td>
                  <td><input type="text" v-model="popupParams.ProformaFreightPop.testBkgPlcCd" /></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('ProformaFreightPop')">Proforma Freight 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]Proforma Freight Invoice -->
      <!--[S]S/O Download -->
      <div class="content_box mt5">
        <h1 class="page_title">S/O Download</h1>
        <ul class="ul_box" style="overflow-x: auto;">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="*">
                <col width="*">
                <col width="80px">
              </colgroup>
              <thead>
                <tr>
                  <th>BKG_NO</th>
                  <th>GUBUN</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addShippingOrder()">plus</button></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.ShippingOrderPop.items" :key="'DelayNoticeInPop_' + idx">
                  <td><input type="text" v-model="vo.bkgNo" /></td>
                  <td><input type="text" v-model="vo.gubun" /></td>
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeShippingOrder(idx)">minus</button></td>
                </tr>
              </tbody>
            </table>
            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <p class="comment" style="font-size:12px;font-weight:bold;">#1-[CN(HKCSZP)], #2-[CN(SZP,HUA,NSN)], #3-[CN(TAO)], #4-[CN(그외)] 가 같이 포함되어 있습니다.</p>
            <p class="comment" style="font-weight: bold;">※나라가 CN 에서만 정상 작동합니다.</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180">
                <col width="180">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USR_NO</th>
                  <th>CTR_CD</th>
                  <th>AGT_CD</th>
                  <th>BKG_PLC_CD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.ShippingOrderPop.testUsrNo" /></td>
                  <td><input type="text" v-model="popupParams.ShippingOrderPop.testCtrCd" /></td>
                  <td><input type="text" v-model="popupParams.ShippingOrderPop.testAgtCd" /></td>
                  <td><input type="text" v-model="popupParams.ShippingOrderPop.testBkgPlcCd" /></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('ShippingOrderPop')">Shipping Order 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]S/O Download -->
      <!--[S]Free Time 승인 -->
      <div class="content_box mt5">
        <h1 class="page_title">Free Time 승인</h1>
        <ul class="ul_box" style="overflow-x: auto;">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="*">
                <!-- <col width="*">
                <col width="*">
                <col width="*"> -->
                <col width="80px">
              </colgroup>
              <thead>
                <tr>
                  <th>BL_NO</th>
                  <!-- <th>ORG_CD</th>
                  <th>LANG_CTR_CD</th>
                  <th>CTR_CUR_CD</th> -->
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addFreeTime()">plus</button></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.FreeTimePop.items" :key="'FreeTimePop_' + idx">
                  <td><input type="text" v-model="vo.blNo" /></td>
                  <!-- <td><input type="text" v-model="vo.orgCd" /></td>
                  <td><input type="text" v-model="vo.langCtrCd" /></td>
                  <td><input type="text" v-model="vo.ctrCurCd" /></td> -->
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeFreeTime(idx)">minus</button></td>
                </tr>
              </tbody>
            </table>
            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180">
                <col width="180">
                <col width="*">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USR_NO</th>
                  <th>CTR_CD</th>
                  <th>AGT_CD</th>
                  <th>BKG_PLC_CD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.FreeTimePop.testUsrNo" /></td>
                  <td><input type="text" v-model="popupParams.FreeTimePop.testCtrCd" /></td>
                  <td><input type="text" v-model="popupParams.FreeTimePop.testAgtCd" /></td>
                  <td><input type="text" v-model="popupParams.FreeTimePop.testBkgPlcCd" /></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('FreeTimePop')">Free Time 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]Free Time 승인 -->
      <!--[S]JP CLP -->
      <div class="content_box mt5">
        <h1 class="page_title">JP CLP</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="100px">
                <col width="80px">
                <col width="*">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>BL_NO</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addJpClp()">plus</button></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.JpClpPop.items" :key="'JpClpPop_' + idx">
                  <td><input type="text" v-model="vo.blNo" /></td>
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeJpClp(idx)">minus</button></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <p class="comment" style="font-weight: bold;">※나라가 JP 에서만 정상 작동합니다.</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180">
                <col width="180">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USR_NO</th>
                  <th>CTR_CD</th>
                  <th>BKG_PLC_CD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td><input type="text" v-model="popupParams.JpClpPop.testCtrCd" /></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('JpClpPop')">JP CLP 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]JP CLP -->
      <!--[S]선적마감안내문 -->
      <div class="content_box mt5">
        <h1 class="page_title">선적 마감 안내문</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="100px">
                <col width="80px">
                <col width="*">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>BKG_NO</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addShipmentFinish()">plus</button></th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.ShipmentFinishPop.items" :key="'ShipmentFinishPop_' + idx">
                  <td><input type="text" v-model="vo.bkgNo" /></td>
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeShipmentFinish(idx)">minus</button></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <p class="comment" style="font-weight: bold;">※나라가 KR 에서만 정상 작동합니다.</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180">
                <col width="180">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USR_NO</th>
                  <th>CTR_CD</th>
                  <th>BKG_PLC_CD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td><input type="text" v-model="popupParams.ShipmentFinishPop.testCtrCd" /></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('ShipmentFinishPop')">선적 마감 안내문 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]선적마감안내문 -->
      <!--[S]Receipt Print -->
      <div class="content_box mt5">
        <h1 class="page_title">Receipt Print</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="80px">
              </colgroup>
              <thead>
                <tr>
                  <th>RCV_RNO</th>
                  <th>PRT_RVST_NO</th>
                  <th>CST_NO</th>
                  <th>CST_NM</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addReceiptPrint()">plus</button></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.ReceiptPrintPop.items" :key="'ReceiptPrintPop_' + idx">
                  <td><input type="text" v-model="vo.rcvRno" style="width:140px;" /></td>
                  <td><input type="text" v-model="vo.prtRvstNo" style="width:140px;" /></td>
                  <td><input type="text" v-model="vo.cstNo" style="width:140px;" /></td>
                  <td><input type="text" v-model="vo.cstNm" style="width:140px;" /></td>
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeReceiptPrint(idx)">minus</button></td>
                </tr>
              </tbody>
            </table>
            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <p class="comment" style="font-weight: bold;">※나라가 ID 에서만 정상 작동합니다.</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180px">
                <col width="180px">
                <col width="180px">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USR_NO</th>
                  <th>CTR_CD</th>
                  <th>AGT_CD</th>
                  <th>BKG_PLC_CD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.ReceiptPrintPop.testUsrNo" /></td>
                  <td><input type="text" v-model="popupParams.ReceiptPrintPop.testCtrCd" /></td>
                  <td><input type="text" v-model="popupParams.ReceiptPrintPop.testAgtCd" /></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('ReceiptPrintPop')">Receipt Print 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]Receipt Print -->
      <!--[S]적하목록 (B/L 단위) -->
      <div class="content_box mt5">
        <h1 class="page_title">적하목록 (B/L 단위)</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="80px">
              </colgroup>
              <thead>
                <tr>
                  <th>BL_NO</th>
                  <th>VSL_CD(입항선박 코드)</th>
                  <th>VOY_NO(입항선박 항차)</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addCargoMainFest()">plus</button></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.CargoMainFestPop.items" :key="'CargoMainFestPop_' + idx">
                  <td><input type="text" v-model="vo.blNo" style="width:140px;" /></td>
                  <td><input type="text" v-model="vo.vslCd" style="width:140px;" /></td>
                  <td><input type="text" v-model="vo.voyNo" style="width:140px;" /></td>
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeCargoMainFest(idx)">minus</button></td>
                </tr>
              </tbody>
            </table>
            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <p class="comment" style="font-weight: bold;">※나라가 KR 에서만 정상 작동합니다.</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180px">
                <col width="180px">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USR_NO</th>
                  <th>CTR_CD</th>
                  <th>BKG_PLC_CD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td></td>
                  <td><input type="text" v-model="popupParams.CargoMainFestPop.testCtrCd" /></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('CargoMainFestPop')">적하목록 (B/L 단위) 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]적하목록 (B/L 단위) -->
      <!--[S]수출업무위임장 #1-->
      <div class="content_box mt5">
        <h1 class="page_title">수출 업무 위임장 #1</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="*">
                <col width="*">
                <col width="80px">
              </colgroup>
              <thead>
                <tr>
                  <th>GRP_CAT_CD</th>
                  <th>REQ_NO</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addOutCommission1()">plus</button></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.OutCommission1Pop.items" :key="'OutCommission1Pop_' + idx">
                  <td><input type="text" v-model="vo.grpCatCd" style="width:140px;" /></td>
                  <td><input type="text" v-model="vo.reqNo" style="width:140px;" /></td>
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeOutCommission1(idx)">minus</button></td>
                </tr>
              </tbody>
            </table>
            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <p class="comment" style="font-weight: bold;">※나라가 KR 에서만 정상 작동합니다.</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180px">
                <col width="180px">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USR_NO</th>
                  <th>CTR_CD</th>
                  <th>BKG_PLC_CD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.OutCommission1Pop.testUsrNo" /></td>
                  <td><input type="text" v-model="popupParams.OutCommission1Pop.testCtrCd" /></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('OutCommission1Pop')">수출 업무 위임장 #1 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]수출업무위임장 #1-->
      <!--[S]수출업무위임장 #2-->
      <div class="content_box mt5">
        <h1 class="page_title">수출 업무 위임장 #2</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="*">
                <col width="*">
                <col width="80px">
              </colgroup>
              <thead>
                <tr>
                  <th>PORT_CD</th>
                  <th>PARM1</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addOutCommission2()">plus</button></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.OutCommission2Pop.items" :key="'OutCommission2Pop_' + idx">
                  <td><input type="text" v-model="vo.portCd" style="width:140px;" /></td>
                  <td><input type="text" v-model="vo.parm1" style="width:140px;" /></td>
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeOutCommission2(idx)">minus</button></td>
                </tr>
              </tbody>
            </table>
            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <p class="comment" style="font-weight: bold;">※나라가 KR 에서만 정상 작동합니다.</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180px">
                <col width="180px">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USR_NO</th>
                  <th>CTR_CD</th>
                  <th>BKG_PLC_CD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.OutCommission2Pop.testUsrNo" /></td>
                  <td><input type="text" v-model="popupParams.OutCommission2Pop.testCtrCd" /></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('OutCommission2Pop')">수출 업무 위임장 #2 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]수출업무위임장 #2-->
      <!--[S]긴급양하 요청-->
      <div class="content_box mt5">
        <h1 class="page_title">긴급양하 요청</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="*">
                <col width="*">
                <col width="80px">
              </colgroup>
              <thead>
                <tr>
                  <th>PORT_CD</th>
                  <th>PARM1</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addUrgentGetOff()">plus</button></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.UrgentGetOffPop.items" :key="'UrgentGetOffPop_' + idx">
                  <td><input type="text" v-model="vo.portCd" style="width:140px;" /></td>
                  <td><input type="text" v-model="vo.parm1" style="width:140px;" /></td>
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeUrgentGetOff(idx)">minus</button></td>
                </tr>
              </tbody>
            </table>
            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <p class="comment" style="font-weight: bold;">※나라가 KR 에서만 정상 작동합니다.</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180px">
                <col width="180px">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USR_NO</th>
                  <th>CTR_CD</th>
                  <th>BKG_PLC_CD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.UrgentGetOffPop.testUsrNo" /></td>
                  <td><input type="text" v-model="popupParams.UrgentGetOffPop.testCtrCd" /></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('UrgentGetOffPop')">긴급양하 요청 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]긴급양하 요청-->
      <!--[S]Shpping Request-->
      <div class="content_box mt5">
        <h1 class="page_title">Shpping Request</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="80">
              </colgroup>
              <thead>
                <tr>
                  <th>B/L No.</th>
                  <th>Rider(Y/N)</th>
                  <th>Por Dly(Y/N)</th>
                  <th>No Cnee(Y/N)</th>
                  <th>No Notify(Y/N)</th>
                  <th>ORG_CD</th>
                  <th>LANG_CTR_CD</th>
                  <th>CTR_CUR_CD</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addShippingRequest()">plus</button></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.ShippingRequestPop.items" :key="'ShippingRequestPop_' + idx">
                  <td><input type="text" v-model="vo.blNo" style="width:100px" /></td>
                  <td><input type="text" v-model="vo.riderYn" style="width:100px" /></td>
                  <td><input type="text" v-model="vo.porDlyYn" style="width:100px" /></td>
                  <td><input type="text" v-model="vo.noCnee" style="width:100px" /></td>
                  <td><input type="text" v-model="vo.noNotify" style="width:100px" /></td>
                  <td><input type="text" v-model="vo.orgCd" style="width:100px" /></td>
                  <td><input type="text" v-model="vo.langCtrCd" style="width:100px" /></td>
                  <td><input type="text" v-model="vo.ctrCurCd" style="width:100px" /></td>
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeShippingRequest(idx)">minus</button></td>
                </tr>
              </tbody>
            </table>

            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180">
                <col width="180">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USER_ID</th>
                  <th>CTR_CD</th>
                  <th>AGT_CD</th>
                  <th>BKG_PLC_CD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.ShippingRequestPop.testUserId" /></td>
                  <td><input type="text" v-model="popupParams.ShippingRequestPop.testCtrCd" /></td>
                  <td><input type="text" v-model="popupParams.ShippingRequestPop.testAgtCd" /></td>
                  <td><input type="text" v-model="popupParams.ShippingRequestPop.testBkgPlcCd" /></td>
                </tr>
              </tbody>
            </table>

            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('ShippingRequestPop')">Shipping Request 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]Shpping Request-->
      <!--[S]Invoice -->
      <div class="content_box mt5">
        <h1 class="page_title">Invoice</h1>
        <ul class="ul_box" style="overflow-x: auto;">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="80px">
              </colgroup>
              <thead>
                <tr>
                  <th>BL_NO</th>
                  <th>PORT_CD</th>
                  <th>PAY_AGTORG_NO</th>
                  <th>CST_NO</th>
                  <th>TO</th>
                  <th>REMARK</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addInvoicePop()">plus</button></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.InvoicePop.items" :key="'DelayNoticeInPop_' + idx">
                  <td><input type="text" v-model="vo.blNo" style="width:100px;" /></td>
                  <td><input type="text" v-model="vo.portCd" style="width:100px;" /></td>
                  <td><input type="text" v-model="vo.payAgtorgNo" style="width:100px;" /></td>
                  <td><input type="text" v-model="vo.cstNo" style="width:100px;" /></td>
                  <td><input type="text" v-model="vo.to" style="width:100px;" /></td>
                  <td><input type="text" v-model="vo.remark" style="width:100px;" /></td>
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeInvoicePop(idx)">minus</button></td>
                </tr>
              </tbody>
            </table>

            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <p class="comment" style="font-weight: bold;">※나라가 ID 에서만 정상 작동합니다.</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180">
                <col width="180">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USR_NO</th>
                  <th>CTR_CD</th>
                  <th>AGT_CD</th>
                  <th>BKG_PLC_CD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.InvoicePop.testUsrNo" /></td>
                  <td><input type="text" v-model="popupParams.InvoicePop.testCtrCd" /></td>
                  <td><input type="text" v-model="popupParams.InvoicePop.testAgtCd" /></td>
                  <td><input type="text" v-model="popupParams.InvoicePop.testBkgPlcCd" /></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('InvoicePop')">InvoicePop 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]Invoice -->
      <!--[S]combine Invoice -->
      <div class="content_box mt5">
        <h1 class="page_title">통합 Invocie</h1>
        <ul class="ul_box" style="overflow-x: auto;">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="100">
                <!-- <col width="100"> -->
                <col width="100">
                <col width="100">
                <col width="100">
                <col width="100">
                <col width="100">
                <!-- <col width="100"> -->
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>B/L No</th>
                  <!-- <th>Invoice No.</th> -->
                  <th>To</th>
                  <th>Remark</th>
                  <th>Filter</th>
                  <th>Esign <span style="color:red;font-weight:bold;">(PH)</span></th>
                  <!-- <th>Cst No</th> -->
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addCombineInvoice()">plus</button></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.CombineInvoicePop.items" :key="'combineInvoicePop_' + idx">
                  <td><input type="text" style="width:100px;" v-model="vo.blNo" /></td>
                  <!-- <td><input type="text" style="width:100px;" v-model="vo.invIssNo" /></td> -->
                  <td><input type="text" style="width:100px;" v-model="vo.to" /></td>
                  <td><input type="text" style="width:100px;" v-model="vo.remark" /></td>
                  <td><input type="text" style="width:100px;" v-model="vo.filter" /></td>
                  <td><input type="text" style="width:100px;" v-model="vo.esign" /></td>
                  <!-- <td><input type="text" style="width:100px;" v-model="vo.cstNo" /></td> -->
                  <td style="text-align:center"><button type="button" class="tbl_icon minus" @click.prevent="removeCombineInvoice(idx)">minus</button></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <p class="comment" style="font-weight: bold;">
              ※나라가 CN인 경우에는 무조건 Proforma Freight Invoice만 출력되기 때문에 Freight Invoice
              <br>
              &nbsp;&nbsp;&nbsp;섞여 있을 시 정상 노출 되지 않을 수 있다.
            </p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180">
                <col width="180">
                <col width="180">
                <col width="180">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USER_NO</th>
                  <th>CTR_CD</th>
                  <th>AGT_CD</th>
                  <th>BKG_PLC_CD</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.CombineInvoicePop.testUserNo" /></td>
                  <td><input type="text" v-model="popupParams.CombineInvoicePop.testCtrCd" /></td>
                  <td><input type="text" v-model="popupParams.CombineInvoicePop.testAgtCd" /></td>
                  <td><input type="text" v-model="popupParams.CombineInvoicePop.testBkgPlcCd" /></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('CombineInvoicePop')">Combine Invoice 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]combine Invoice -->
      <!--[S]DEM DET Invoice -->
      <div class="content_box mt5">
        <h1 class="page_title">DEMDET Invocie</h1>
        <ul class="ul_box" style="overflow-x: auto;">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="100">
                <col width="100">
                <col width="100">
                <col width="100">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>B/L No</th>
                  <th>DEM/DET</th>
                  <th>Out/In</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addDemDetInvoice()">plus</button></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.DemInvoiceMultiPop.items" :key="'combineInvoicePop_' + idx">
                  <td><input type="text" style="width:100px;" v-model="vo.blNo" /></td>
                  <td>
                    <select v-if="idx === 0" v-model="popupParams.DemInvoiceMultiPop.demDetCatCd">
                      <option value="01">DEM</option>
                      <option value="02">DET</option>
                    </select>
                  </td>
                  <td>
                    <select v-if="idx === 0" v-model="popupParams.DemInvoiceMultiPop.eiCatCd">
                      <option value="O">EXPORT</option>
                      <option value="I">IMPORT</option>
                    </select>
                  </td>
                  <td style="text-align:center"><button type="button" class="tbl_icon minus" @click.prevent="removeDemDetInvoice(idx)">minus</button></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('DemInvoiceMultiPop')">Combine Invoice 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]combine Invoice -->
      <!--[S]Schedule Change Notice -->
      <div class="content_box mt5">
        <h1 class="page_title">Schedule Change Notice (선명/항차)</h1>
        <ul class="ul_box" style="overflow-x: auto;">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="*">
                <col width="80px">
              </colgroup>
              <thead>
                <tr>
                  <th>VSL_CD</th>
                  <th>VOY_NO</th>
                  <th>POL_PORT</th>
                  <th>POD_PORT</th>
                  <th>GUBUN</th>
                  <th>DOC_NO</th>
                  <th>EDIT_TABLE</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addChangeNotice()">plus</button></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.ChangeNoticePop.items" :key="'changeNoticePop_' + idx">
                  <td><input type="text" v-model="vo.vslCd" style="width:100px;" /></td>
                  <td><input type="text" v-model="vo.voyNo" style="width:100px;" /></td>
                  <td><input type="text" v-model="vo.polPortCd" style="width:100px;" /></td>
                  <td><input type="text" v-model="vo.podPortCd" style="width:100px;" /></td>
                  <td><input type="text" v-model="vo.gubun" style="width:100px;" /></td>
                  <td><input type="text" v-model="vo.docNo" style="width:100px;" /></td>
                  <td><input type="text" v-model="vo.editTable" style="width:100px;" /></td>
                  <td><button type="button" class="tbl_icon minus" @click.prevent="removeChangeNotice(idx)">minus</button></td>
                </tr>
              </tbody>
            </table>

            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <p class="comment" style="font-weight: bold;">※나라가 KR 에서만 정상 작동합니다.</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180">
                <col width="180">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USR_NO</th>
                  <th>CTR_CD</th>
                  <th>BKG_PLC_CD</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.ChangeNoticePop.testUsrNo" /></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('ChangeNoticePop')">InvoicePop 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]Schedule Change Notice -->
      <!--[S]Surrender Notice -->
      <div class="content_box mt5">
        <h1 class="page_title">Surrender Notice</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="200">
                <col width="80">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>B/L No.</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addSurrenderNotice()">plus</button></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.SurrenderNoticePop.items" :key="'SurrenderNoticePop_' + idx">
                  <td><input type="text" v-model="vo.blNo" /></td>
                  <td style="text-align:center"><button type="button" class="tbl_icon minus" @click.prevent="removeSurrenderNotice(idx)">minus</button></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180">
                <col width="180">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USR_NO</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.SurrenderNoticePop.testUsrNo" /></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('SurrenderNoticePop')">Surrender Notice 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]Surrender Notice -->
      <!--[S]Seawaybill Notice -->
      <div class="content_box mt5">
        <h1 class="page_title">Seawaybill Notice</h1>
        <ul class="ul_box">
          <li class="fl">
            <table class="tbl_type">
              <colgroup>
                <col width="200">
                <col width="80">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>B/L No.</th>
                  <th><button type="button" class="tbl_icon plus" @click.prevent="addSeawaybillNotice()">plus</button></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(vo, idx) in popupParams.SeawaybillNoticePop.items" :key="'SeawaybillNoticePop_' + idx">
                  <td><input type="text" v-model="vo.blNo" /></td>
                  <td style="text-align:center"><button type="button" class="tbl_icon minus" @click.prevent="removeSeawaybillNotice(idx)">minus</button></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <p class="comment">아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)</p>
            <table class="tbl_type mt5">
              <colgroup>
                <col width="180">
                <col width="180">
                <col width="*">
              </colgroup>
              <thead>
                <tr>
                  <th>USR_NO</th>
                  <th></th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td><input type="text" v-model="popupParams.SeawaybillNoticePop.testUsrNo" /></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>

            <div class="btn_wrap">
              <button class="button blue lg mt10 layer_open" @click="openPopup('SeawaybillNoticePop')">Surrender Notice 팝업</button>
            </div>
          </li>
          <li class="fr">
          </li>
        </ul>
      </div>
      <!--[E]Seawaybill Notice -->
    </div>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'

export default {
    name: 'SamplePopupPdfList',
    components: {
      ArrivalNoticePop: () => import('@/pages/pdf/popup/ArrivalNoticePop'),
      DeliveryOrderPop: () => import('@/pages/pdf/popup/DeliveryOrderPop'),
      BlInvoiceFreightPop: () => import('@/pages/pdf/popup/BlInvoiceFreightPop'),
      CertificatePop: () => import('@/pages/pdf/popup/CertificatePop'),
      BlCopyPop: () => import('@/pages/pdf/popup/BlCopyPop'),
      WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
      BookingConfirmPop: () => import('@/pages/pdf/popup/BookingConfirmPop'),
      DelayNoticeOutPop: () => import('@/pages/pdf/popup/DelayNoticePop'),
      DelayNoticeInPop: () => import('@/pages/pdf/popup/DelayNoticePop'),
      VesselChangePop: () => import('@/pages/pdf/popup/VesselChangePop'),
      ProformaFreightPop: () => import('@/pages/pdf/popup/ProformaFreightPop'),
      ShippingOrderPop: () => import('@/pages/pdf/popup/ShippingOrderPop'),
      FreeTimePop: () => import('@/pages/pdf/popup/FreeTimePop'),
      JpClpPop: () => import('@/pages/pdf/popup/JpClpPop'),
      ShipmentFinishPop: () => import('@/pages/pdf/popup/ShipmentFinishPop'),
      ReceiptPrintPop: () => import('@/pages/pdf/popup/ReceiptPrintPop'),
      CargoMainFestPop: () => import('@/pages/pdf/popup/CargoMainFestPop'),
      OutCommission1Pop: () => import('@/pages/pdf/popup/OutCommission1Pop'),
      OutCommission2Pop: () => import('@/pages/pdf/popup/OutCommission2Pop'),
      UrgentGetOffPop: () => import('@/pages/pdf/popup/UrgentGetOffPop'),
      ShippingRequestPop: () => import('@/pages/pdf/popup/ShippingRequestPop'),
      CombineInvoicePop: () => import('@/pages/pdf/popup/CombineInvoicePop'),
      ChangeNoticePop: () => import('@/pages/pdf/popup/ChangeNoticePop'),
      InvoicePop: () => import('@/pages/pdf/popup/InvoicePop'),
      SurrenderNoticePop: () => import('@/pages/pdf/popup/SurrenderNoticePop'),
      DemInvoiceMultiPop: () => import('@/pages/settle/popup/DemInvoiceMultiPop'),
      SeawaybillNoticePop: () => import('@/pages/pdf/popup/SeawaybillNoticePop')
    },
    data () {
      return {
        urlInfo: {
          commonUrl: '',
          reportUrl: '',
          transUrl: ''
        },
        parentInfo: {},
        customComponent: null,
        popupParams: {
          ArrivalNoticePop: {
            testUsrNo: '',
            testCtrCd: '',
            testBkgPlcCd: '',
            items: [
              {
                bkgNo: 'MY00428655',
                blNo: 'PKW0629505'
              },
              {
                bkgNo: 'KR03128610',
                blNo: 'PUS707700'
              }
            ]
          },
          DeliveryOrderPop: {
            testUsrNo: '',
            testCtrCd: '',
            testAgtCd: '',
            items: [
              {
                blNo: 'BLW0135772',
                doRno: 'BLW0135772'
              },
              {
                blNo: 'HKG4116940',
                doRno: 'HKG4116940'
              }
            ]
          },
          BlInvoiceFreightPop: {
            testCtrCd: '',
            testAgtCd: '',
            testBkgPlcCd: '',
            items: [
              {
                invIssNo: '000000249841',
                to: '',
                remark: '',
                filter: 'BL',
                blNo: 'PUSD739696',
                esign: ''
              }
            ]
          },
          CertificatePop: {
            testCtrCd: '',
            testBkgPlcCd: '',
            items: [
              {
                crtfNo: '194177'
              }
            ]
          },
          BlCopyPop: {
            testCtrCd: '',
            testBkgPlcCd: '',
            testUserId: '',
            items: [
              {
                blNo: 'PUSD739696',
                porDlyYn: 'N',
                noCnee: 'N',
                noNotify: 'N'
              }
            ]
          },
          BookingConfirmPop: {
            testUsrNo: '',
            testCtrCd: '',
            testBkgPlcCd: '',
            items: [
              {
                bkgNo: 'TH00000145',
                gubun: ''
              },
              {
                bkgNo: '0000018',
                gubun: ''
              },
              {
                bkgNo: 'TH00560258',
                gubun: '4'
              }
            ]
          },
          DelayNoticeOutPop: {
            inOut: 'O',
            items: [
              {
                docNo: '01',
                prtGubun: '28',
                parm1: 'KR02892645'
              }
            ]
          },
          DelayNoticeInPop: {
            inOut: 'I',
            items: [
              {
                docNo: '01',
                prtGubun: '03BL',
                parm1: 'TYO0436548'
              }
            ]
          },
          VesselChangePop: {
            testUsrNo: '',
            inOut: 'O',
            items: [
              {
                docNo: '19',
                prtGubun: '29',
                parm1: 'KR02892002'
              }
            ]
          },
          ProformaFreightPop: {
            testCtrCd: '',
            testUsrNo: '',
            testBkgPlcCd: '',
            testAgtCd: '',
            items: [
              {
                portCd: 'SEL',
                payAgtorgNo: 'KORKOR',
                blNo: 'HKG4153942',
                cstNo: 'FLY056',
                to: '',
                remark: ''
              },
              {
                portCd: 'SHA',
                payAgtorgNo: 'SHASHA',
                blNo: 'SHAG688102',
                cstNo: 'PSRC01',
                to: '',
                remark: ''
              },
              {
                portCd: 'PKG',
                payAgtorgNo: 'MSSMSS',
                blNo: 'PGU0480854',
                cstNo: 'FFSM16',
                to: '',
                remark: ''
              },
              {
                portCd: 'HPH',
                payAgtorgNo: 'VNCVNC',
                blNo: 'HPH1022289',
                cstNo: 'KL0500',
                to: '',
                remark: ''
              },
              {
                portCd: 'JKT',
                payAgtorgNo: 'PSIPSI',
                blNo: 'HKG4116940',
                cstNo: 'LT0019',
                to: 'LT0019',
                remark: ''
              }
            ]
          },
          ShippingOrderPop: {
            testCtrCd: '',
            testUsrNo: '',
            testBkgPlcCd: '',
            testAgtCd: '',
            items: [
              {
                bkgNo: 'CN03744439',
                gubun: 3
              },
              {
                bkgNo: 'CN03543861',
                gubun: 3
              },
              {
                bkgNo: 'CN03539018',
                gubun: 4
              },
              {
                bkgNo: 'CN03536444',
                gubun: 4
              }
            ]
          },
          FreeTimePop: {
            testUsrNo: '',
            testCtrCd: '',
            testBkgPlcCd: '',
            testAgtCd: '',
            items: [
              {
                blNo: 'PUS705844'
              },
              {
                blNo: 'AHD000896'
              }
              // {
              //   blNo: 'PUS705844',
              //   orgCd: '000460',
              //   langCtrCd: 'KR',
              //   ctrCurCd: 'KRW'
              // },
              // {
              //   blNo: 'AHD000896',
              //   orgCd: '000460',
              //   langCtrCd: 'KR',
              //   ctrCurCd: 'KRW'
              // }
            ]
          },
          JpClpPop: {
            testCtrCd: '',
            items: [
              {
                blNo: 'KIJ0442976'
              }
            ]
          },
          ShipmentFinishPop: {
            testCtrCd: '',
            items: [
              {
                bkgNo: 'KR02892645'
              }
            ]
          },
          ReceiptPrintPop: {
            testUsrNo: '',
            testCtrCd: '',
            items: [
              {
                rcvRno: '000010589786',
                prtRvstNo: 'PSIJKT20210113304',
                cstNo: '',
                cstNm: 'PT LONG TENG'
              }
            ]
          },
          CargoMainFestPop: {
            testCtrCd: '',
            items: [
              {
                blNo: 'XON0077891',
                vslCd: 'JHKB',
                voyNo: '0042N'
              }
            ]
          },
          OutCommission1Pop: {
            testUsrNo: '',
            testCtrCd: '',
            items: [
              {
                grpCatCd: '01',
                reqNo: '202111010001'
              }
            ]
          },
          OutCommission2Pop: {
            testUsrNo: '',
            testCtrCd: '',
            items: [
              {
                portCd: 'INC',
                parm1: 'SGN3685211'
              }
            ]
          },
          UrgentGetOffPop: {
            testUsrNo: '',
            testCtrCd: '',
            items: [
              {
                portCd: 'INC',
                parm1: 'SGN3685211'
              }
            ]
          },
          ShippingRequestPop: {
            testUserId: '',
            testCtrCd: '',
            testAgtCd: '',
            testBkgPlcCd: '',
            items: [
              {
                blNo: 'PUSE013814',
                riderYn: 'N',
                porDlyYn: 'N',
                noCnee: 'N',
                noNotify: 'N',
                orgCd: '000460',
                langCtrCd: 'KR',
                ctrCurCd: 'KRW'
              },
              {
                blNo: 'KIJ0452232',
                riderYn: 'N',
                porDlyYn: 'N',
                noCnee: 'N',
                noNotify: 'N',
                orgCd: 'CMCCMC',
                langCtrCd: 'JA',
                ctrCurCd: 'JPY'
              }
            ]
          },
          InvoicePop: {
            testCtrCd: '',
            testUsrNo: '',
            testBkgPlcCd: '',
            testAgtCd: '',
            items: [
              {
                portCd: 'JKT',
                payAgtorgNo: 'PSIPSI',
                blNo: 'HKG3792901',
                cstNo: 'LT0019',
                to: 'LT0019',
                remark: ''
              }
            ]
          },
          CombineInvoicePop: {
            testCtrCd: '',
            testUsrNo: '',
            testBkgPlcCd: '',
            testAgtCd: '',
            items: [
              {
                blNo: 'PUSD739696',
                invIssNo: '000000249841',
                to: '',
                remark: '',
                filter: 'BL',
                esign: '',
                cstNo: ''
              },
              {
                blNo: 'HKG4153942',
                invIssNo: '',
                to: '',
                remark: '',
                filter: '',
                esign: '',
                cstNo: 'FLY056'
              },
              {
                blNo: 'SHAG688102',
                invIssNo: '',
                to: '',
                remark: '',
                filter: '',
                esign: '',
                cstNo: 'PSRC01'
              },
              {
                blNo: 'PGU0480854',
                invIssNo: '',
                to: '',
                remark: '',
                filter: '',
                esign: '',
                cstNo: 'FFSM16'
              },
              {
                blNo: 'HPH1022289',
                invIssNo: '',
                to: '',
                remark: '',
                filter: '',
                esign: '',
                cstNo: 'KL0500'
              },
              {
                blNo: 'HKG4116940',
                invIssNo: '',
                to: 'LT0019',
                remark: '',
                filter: '',
                esign: '',
                cstNo: 'LT0019'
              }
            ]
          },
          ChangeNoticePop: {
            testUsrNo: '',
            items: [
              {
                vslCd: 'JBAS',
                voyNo: '2106E',
                polPortCd: 'INC',
                podPortCd: 'NGO',
                gubun: '99Vs',
                docNo: '',
                editTable: ''
              }
            ]
          },
          SurrenderNoticePop: {
            testUsrNo: '',
            items: [
              {
                blNo: 'PUSD888508'
              }
            ]
          },
          SeawaybillNoticePop: {
            testUsrNo: '',
            items: [
              {
                blNo: 'INC3363970'
              }
            ]
          },
          DemInvoiceMultiPop: {
            items: [
              { blNo: 'PUSE033370' }
            ],
            demDetCatCd: '02',
            eiCatCd: 'O'
          }
        }
      }
    },
    computed: {
      ...rootComputed
    },
    created () {
      this.urlInfo.commonUrl = process.env.VUE_APP_COMMON_URL
      this.urlInfo.transUrl = process.env.VUE_APP_TRANS_URL
      this.urlInfo.reportUrl = process.env.VUE_APP_REPORT_URL
    },
    methods: {
      openPopup (compNm) {
        if (this.customComponent !== null) {
          this.customComponent = null
          this.$ekmtcCommon.layerClose()
        }

        this.customComponent = compNm
        this.parentInfo = this.popupParams[compNm]
        this.$ekmtcCommon.layerOpen('.sample_popup_list')
      },
      closePopup (obj) {
        this.customComponent = null
        this.$ekmtcCommon.layerClose('.sample_popup_list')
      },
      addArrivalNotice () {
        this.popupParams.ArrivalNoticePop.items.push({
          bkgNo: '',
          blNo: ''
        })
      },
      removeArrivalNotice (idx) {
        this.popupParams.ArrivalNoticePop.items.splice(idx, 1)
        if (this.popupParams.ArrivalNoticePop.items.length === 0) {
          this.addArrivalNotice()
        }
      },
      addDeliveryOrder () {
        this.popupParams.DeliveryOrderPop.items.push({
          blNo: '',
          doRno: ''
        })
      },
      removeDeliveryOrder (idx) {
        this.popupParams.DeliveryOrderPop.items.splice(idx, 1)
        if (this.popupParams.DeliveryOrderPop.items.length === 0) {
          this.addDeliveryOrder()
        }
      },
      addBlInvoiceFreight () {
        this.popupParams.BlInvoiceFreightPop.items.push({
          invIssNo: '',
          to: '',
          remark: '',
          filter: '',
          blNo: '',
          esign: ''
        })
      },
      removeBlInvoiceFreight (idx) {
        this.popupParams.BlInvoiceFreightPop.items.splice(idx, 1)
        if (this.popupParams.BlInvoiceFreightPop.items.length === 0) {
          this.addBlInvoiceFreight()
        }
      },
      addCertificate () {
        this.popupParams.CertificatePop.items.push({
          crtfNo: ''
        })
      },
      removeCertificate (idx) {
        this.popupParams.CertificatePop.items.splice(idx, 1)
        if (this.popupParams.CertificatePop.items.length === 0) {
          this.addCertificate()
        }
      },
      addBlCopy () {
        this.popupParams.BlCopyPop.items.push({
          blNo: '',
          porDlyYn: 'N',
          noCnee: 'N',
          noNotify: 'N'
        })
      },
      removeBlCopy (idx) {
        this.popupParams.BlCopyPop.items.splice(idx, 1)
        if (this.popupParams.BlCopyPop.items.length === 0) {
          this.addBlCopy()
        }
      },
      addBookingConfirm () {
        this.popupParams.BookingConfirmPop.items.push({
          bkgNo: '',
          gubun: ''
        })
      },
      removeBookingConfirm (idx) {
        this.popupParams.BookingConfirmPop.items.splice(idx, 1)
        if (this.popupParams.BookingConfirmPop.items.length === 0) {
          this.addBookingConfirm()
        }
      },
      addDelayNotice (type) {
        const initObj = {
            docNo: '',
            prtGubun: '',
            parm1: ''
        }

        if (type === 'out') {
          this.popupParams.DelayNoticeOutPop.items.push(initObj)
        } else {
          this.popupParams.DelayNoticeInPop.items.push(initObj)
        }
      },
      removeDelayNotice (idx, type) {
        if (type === 'out') {
          this.popupParams.DelayNoticeOutPop.items.splice(idx, 1)
          if (this.popupParams.DelayNoticeOutPop.items.length === 0) {
            this.addDelayNotice(type)
          }
        } else {
          this.popupParams.DelayNoticeInPop.items.splice(idx, 1)
          if (this.popupParams.DelayNoticeInPop.items.length === 0) {
            this.addDelayNotice(type)
          }
        }
      },
      addProformaFreight () {
        this.popupParams.ProformaFreightPop.items.push({
          portCd: '',
          payAgtorgNo: '',
          blNo: '',
          cstNo: '',
          to: '',
          remark: ''
        })
      },
      removeProformaFreight (idx) {
        this.popupParams.ProformaFreightPop.items.splice(idx, 1)
        if (this.popupParams.ProformaFreightPop.items.length === 0) {
          this.addProformaFreight()
        }
      },
      addShippingOrder () {
        this.popupParams.ShippingOrderPop.items.push({
          bkgNo: '',
          gubun: ''
        })
      },
      removeShippingOrder (idx) {
        this.popupParams.ShippingOrderPop.items.splice(idx, 1)
        if (this.popupParams.ShippingOrderPop.items.length === 0) {
          this.addShippingOrder()
        }
      },
      addFreeTime () {
        this.popupParams.FreeTimePop.items.push({
          blNo: ''
        })
      },
      removeFreeTime (idx) {
        this.popupParams.FreeTimePop.items.splice(idx, 1)
        if (this.popupParams.FreeTimePop.items.length === 0) {
          this.addFreeTime()
        }
      },
      addJpClp () {
        this.popupParams.JpClpPop.items.push({
          blNo: ''
        })
      },
      removeJpClp (idx) {
        this.popupParams.JpClpPop.items.splice(idx, 1)
        if (this.popupParams.JpClpPop.items.length === 0) {
          this.addJpClp()
        }
      },
      addShipmentFinish () {
        this.popupParams.ShipmentFinishPop.items.push({
          blNo: ''
        })
      },
      removeShipmentFinish (idx) {
        this.popupParams.ShipmentFinishPop.items.splice(idx, 1)
        if (this.popupParams.ShipmentFinishPop.items.length === 0) {
          this.addShipmentFinish()
        }
      },
      addReceiptPrint () {
        this.popupParams.ReceiptPrintPop.items.push({
          rcvRno: '',
          prtRvstNo: '',
          uno: '',
          cstNo: '',
          cstNm: ''
        })
      },
      removeReceiptPrint (idx) {
        this.popupParams.ReceiptPrintPop.items.splice(idx, 1)
        if (this.popupParams.ReceiptPrintPop.items.length === 0) {
          this.addReceiptPrint()
        }
      },
      addCargoMainFest () {
        this.popupParams.CargoMainFestPop.items.push({
          blNo: '',
          vslCd: '',
          voyNo: ''
        })
      },
      removeCargoMainFest (idx) {
        this.popupParams.CargoMainFestPop.items.splice(idx, 1)
        if (this.popupParams.CargoMainFestPop.items.length === 0) {
          this.addCargoMainFest()
        }
      },
      addOutCommission1 () {
        this.popupParams.OutCommission1Pop.items.push({
          grpCatCd: '',
          reqNo: ''
        })
      },
      removeOutCommission1 (idx) {
        this.popupParams.OutCommission1Pop.items.splice(idx, 1)
        if (this.popupParams.OutCommission1Pop.items.length === 0) {
          this.addOutCommission1()
        }
      },
      addOutCommission2 () {
        this.popupParams.OutCommission2Pop.items.push({
          portCd: '',
          parm1: ''
        })
      },
      removeOutCommission2 (idx) {
        this.popupParams.OutCommission2Pop.items.splice(idx, 1)
        if (this.popupParams.OutCommission2Pop.items.length === 0) {
          this.addOutCommission2()
        }
      },
      addUrgentGetOff () {
        this.popupParams.UrgentGetOffPop.items.push({
          portCd: '',
          parm1: ''
        })
      },
      removeUrgentGetOff (idx) {
        this.popupParams.UrgentGetOffPop.items.splice(idx, 1)
        if (this.popupParams.UrgentGetOffPop.items.length === 0) {
          this.addUrgentGetOff()
        }
      },
      addShippingRequest () {
        this.popupParams.ShippingRequestPop.items.push({
          blNo: '',
          riderYn: 'N',
          porDlyYn: 'N',
          noCnee: 'N',
          noNotify: 'N',
          orgCd: '',
          langCtrCd: '',
          ctrCurCd: ''
        })
      },
      removeShippingRequest (idx) {
        this.popupParams.ShippingRequestPop.items.splice(idx, 1)
        if (this.popupParams.ShippingRequestPop.items.length === 0) {
          this.addShippingRequest()
        }
      },
      addInvoice () {
        this.popupParams.InvoicePop.items.push({
          portCd: '',
          payAgtorgNo: '',
          blNo: '',
          cstNo: '',
          to: '',
          remark: ''
        })
      },
      removeInvoice (idx) {
        this.popupParams.InvoicePop.items.splice(idx, 1)
        if (this.popupParams.InvoicePop.items.length === 0) {
          this.addInvoice()
        }
      },
      addCombineInvoice () {
        this.popupParams.CombineInvoicePop.items.push({
          blNo: '',
          // invIssNo: '',
          to: '',
          remark: '',
          filter: '',
          // cstNo: '',
          esign: ''
        })
      },
      removeCombineInvoice (idx) {
        this.popupParams.CombineInvoicePop.items.splice(idx, 1)
        if (this.popupParams.CombineInvoicePop.items.length === 0) {
          this.addCombineInvoice()
        }
      },
      addDemDetInvoice () {
        this.popupParams.DemInvoiceMultiPop.items.push({
          blNo: ''
        })
      },
      removeDemDetInvoice (idx) {
        this.popupParams.DemInvoiceMultiPop.items.splice(idx, 1)
        if (this.popupParams.DemInvoiceMultiPop.items.length === 0) {
          this.addDemDetInvoice()
        }
      },
      addChangeNotice () {
        this.popupParams.ChangeNoticePop.items.push({
          vslCd: '',
          voyNo: '',
          polPortCd: '',
          podPortCd: '',
          gubun: '',
          docNo: '',
          editTable: ''
        })
      },
      removeChangeNotice (idx) {
        this.popupParams.SurrenderNoticePop.items.splice(idx, 1)
        if (this.popupParams.SurrenderNoticePop.items.length === 0) {
          this.addChangeNotice()
        }
      },
      addSurrenderNotice () {
        this.popupParams.SurrenderNoticePop.items.push({
          blNo: ''
        })
      },
      removeSurrenderNotice (idx) {
        this.popupParams.SurrenderNoticePop.items.splice(idx, 1)
        if (this.popupParams.SurrenderNoticePop.items.length === 0) {
          this.addSurrenderNotice()
        }
      },
      addSeawaybillNotice () {
        this.popupParams.SeawaybillNoticePop.items.push({
          blNo: ''
        })
      },
      removeSeawaybillNotice (idx) {
        this.popupParams.SeawaybillNoticePop.items.splice(idx, 1)
        if (this.popupParams.SeawaybillNoticePop.items.length === 0) {
          this.addSeawaybillNotice()
        }
      }
    }
}
</script>

<style>
  .temp_name {
    font-weight: bold;
    font-size: 1.1em;
  }
  .tbl_type,.tbl_type th,.tbl_type td { border:0;}
  .tbl_type { width:100%; border-bottom:1px solid #e1e3eb; font-family:'돋움',dotum; font-size:12px; text-align:center; border-collapse:collapse }
  .tbl_type caption { display:none }
  .tbl_type tfoot { background-color:#f5f7f9; font-weight:bold }
  .tbl_type th { padding:7px 0 4px; border-top:1px solid #e1e3eb; border-right:1px solid #e1e3eb; border-left:1px solid #e1e3eb; background-color:#f6f9ff; color:#075bb9; font-family:'돋움',dotum; font-size:12px; font-weight:bold; }

  .tbl_type td { padding:3px 10px 2px; border:1px solid #e1e3eb; color:#4c4c4c; text-align:center; }
  .tbl_type td.ranking { font-weight:bold }
  .tbl_type td a { color:#F2084F; }
  .tbl_type td span.keep::after   { content: '유지';  color:#fff; background-color:#FFD168; }
  .tbl_type td span.change::after { content: '변경';  color:#fff; background-color:#73BDEE; }
  .tbl_type td span.new::after    { content: '신규';  color:#fff; background-color:#ABF3B3; }
  .tbl_type td span.yellow::after { margin-left:5px; width:45px; letter-spacing:-1px; content: 'SB없이'; color:#777; background-color:#F9F900; }

  .tbl_type td.ok span { width:20px; }
  .tbl_type td.ok span.ok::after   { content: '○';  color:#F1084F; width:20px; text-align:center; background-color:#fff; }
  .tbl_type td.del a { text-decoration: line-through; }
  .tbl_type td input.input_txt { width: 100px; float:right; position: relative;}
  .tbl_type td span.txt_fr { width: 100px; position: relative; display: inline-block;}
  .tbl_type td .p_params {height: 30px; line-height: 30px;}

  .ul_box {
    overflow: hidden;
  }

  .ul_box .fl {
    float: left;
    width: 49%;
  }
  .ul_box .fr {
    float: right;
    width: 49%;
  }

  .btn_wrap {
    text-align: right;
  }

  .comment {
    margin-top: 10px;
    color: red;
  }
</style>
