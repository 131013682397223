var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "app" } },
    [
      _c("DEXT5Editor", {
        attrs: {
          data: _vm.editorText,
          id: "dexteditor1",
          runtimes: "html5",
          width: "100%",
          height: "500px",
        },
        on: { input: _vm.getData },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }