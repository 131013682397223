var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "header" }, [
    _c(
      "div",
      { staticClass: "inner_header" },
      [
        _c("h1", { staticClass: "logo" }, [
          _c(
            "a",
            {
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  _vm.$router.push("/sample/home").catch(() => {})
                  _vm.setMenuOn(0)
                },
              },
            },
            [_vm._v("e-kmtc")]
          ),
        ]),
        _c("div", { staticClass: "wrap_gnb" }, [
          _c("ul", { staticClass: "gnb" }, [
            _c("li", [
              _c(
                "a",
                {
                  class: { on: _vm.menus[0].on },
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.$router.push("/sample/home").catch(() => {})
                      _vm.setMenuOn(0)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.menus[0].text))]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  class: { on: _vm.menus[1].on },
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.$router.push("/sample/popup").catch(() => {})
                      _vm.setMenuOn(1)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.menus[1].text))]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  class: { on: _vm.menus[2].on },
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.$router.push("/sample/process-work").catch(() => {})
                      _vm.setMenuOn(2)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.menus[2].text))]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  class: { on: _vm.menus[3].on },
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.$router.push("/sample/grid").catch(() => {})
                      _vm.setMenuOn(3)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.menus[3].text))]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  class: { on: _vm.menus[4].on },
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.$router.push("/sample/uploader").catch(() => {})
                      _vm.setMenuOn(4)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.menus[4].text))]
              ),
            ]),
            _c("li", [
              _c(
                "a",
                {
                  class: { on: _vm.menus[5].on },
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.$router.push("/sample/editor").catch(() => {})
                      _vm.setMenuOn(5)
                    },
                  },
                },
                [_vm._v(_vm._s(_vm.menus[5].text))]
              ),
            ]),
          ]),
        ]),
        _c("e-header-util"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }