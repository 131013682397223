<template>
  <div>
    <b-container fluid>
      <b-row class="mt-4 mb-4">
        <div class="col-md-2">
          <select
            v-model="searchParams.dtKnd"
            class="custom-select"
          >
            <option v-for="vo in parentInfo.customSelectList" :key="vo.code" :value="vo.code">{{ vo.codeNm }}</option>
          </select>
        </div>
        <div class="col-md-8" v-if="searchParams.dtKnd === 'BL' || searchParams.dtKnd === 'BK' || searchParams.dtKnd === 'SR' || searchParams.dtKnd === 'CN'">
          <input type="text" class="form-control" v-model="searchParams.keyword">
        </div>
        <div class="col-md-8" v-if="searchParams.dtKnd === 'BD' || searchParams.dtKnd === 'OD' || searchParams.dtKnd === 'ID' || searchParams.dtKnd === 'RD'">
          <date-range-picker
            class="w100"
            opens="right"
            v-model="dateRange"
            :locale-data="{
              direction: 'ltr',
              firstDay: 0,
              format: 'yyyy-mm-dd',
              separator: ' - ',
            }"
          >
          </date-range-picker>
        </div>
        <div class="col-md-8 align-flex" v-if="searchParams.dtKnd === 'VS'">
          <div class="col-md-1">
            <label for="vessel" class="form-label">Vessel</label>
          </div>
          <div class="col-md-5">
            <input type="text" class="form-control" id="vessel" v-model="searchParams.vslCd"/>
          </div>
          <div class="col-md-1">
            <label for="voyage" class="form-label">Vessel</label>
          </div>
          <div class="col-md-5">
            <input type="text" class="form-control" id="voyage" v-model="searchParams.voyNo"/>
          </div>
        </div>
        <div class="col-md-2">
          <b-button
            @click="searchClick"
          >Search</b-button>
        </div>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

export default {
  name: 'SearchArea',
  components: { DateRangePicker },
  props: {
    parentInfo: Object
  },
  data () {
    return {
      defaultInfo: {
        BK: { codeNm: 'Booking No.' },
        BL: { codeNm: 'B/L No.' },
        SR: { codeNm: 'S/R No.' },
        BD: { codeNm: 'Booking Date' },
        ID: { codeNm: 'S/R Date' },
        VS: { codeNm: 'Vessel/Voyage' },
        OD: { codeNm: 'On Board Date' },
        CN: { codeNm: 'Container No.' },
        RD: { codeNm: 'Request Date' }
      },
      dateTerm: 7,
      searchParams: {
        dtKnd: '',
        keyword: '',
        vslCd: '',
        voyNo: '',
        startDate: '',
        endDate: ''
      },
      dateRange: {
        startDate: '',
        endDate: ''
      }
    }
  },
  created () {
    this.initSelect()
  },
  methods: {
    isEmpty (objValue) {
      if (objValue === undefined || objValue === null) {
        return true
      } else {
        return ('' + objValue).length === 0
      }
    },
    isNotEmpty (objValue) {
      return !this.isEmpty(objValue)
    },
    getCalculateDate (day) {
      let date = new Date()

      date.setDate(date.getDate() + day)
      return date
    },
    getDateToStrDelim (dt, delim) {
      if (this.isEmpty(delim)) {
        delim = ''
      }

      const year = dt.getFullYear()
      const month = dt.getMonth() + 1
      const date = dt.getDate()

      return year + delim + (month < 10 ? '0' + month : month) + delim + (date < 10 ? '0' + date : date)
    },
    initSelect () {
      const parentInfo = this.parentInfo
      if (parentInfo !== null && parentInfo !== undefined) {
        const selectList = this.parentInfo.customSelectList

        if (selectList !== null && selectList !== undefined && selectList.length > 0) {
          this.searchParams.dtKnd = selectList[0].code

          selectList.forEach(c => {
            if (this.isEmpty(c.codeNm)) {
              c.codeNm = this.defaultInfo[c.code].codeNm
            }
          })
        }

        const dateTerm = parentInfo.dateTerm
        if (this.isNotEmpty(dateTerm)) {
          this.dateTerm = dateTerm
        }

        const dateRange = this.dateRange
        const day = Number(this.dateTerm)

        let startDate = ''
        let endDate = ''

        if (this.isEmpty(parentInfo.startDate)) {
          startDate = this.getDateToStrDelim(this.getCalculateDate(day * -1), '-')
        } else {
          startDate = parentInfo.startDate
        }

        if (this.isEmpty(parentInfo.endDate)) {
          endDate = this.getDateToStrDelim(this.getCalculateDate(day), '-')
        } else {
          endDate = parentInfo.endDate
        }

        dateRange.startDate = startDate
        dateRange.endDate = endDate
      }
    },
    searchClick () {
      if (this._events.parentClickEvt !== undefined) {
        this.$emit('parentClickEvt')
      }
    }
  }
}
</script>

<style scoped>
  .w100 {width: 100%;}
  .align-flex {display: flex; justify-content: center; align-items: baseline;}
</style>
