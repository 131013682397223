<template>
  <div>
    <!--button @click="GetData">{{ $t('msg.ADMN030G010.035') }}</button>
    <button @click="SetData(data)">{{ $t('msg.ADMN030G010.036') }}</button-->
    <div :id="`deHolder_${Id}`"></div>
  </div>
</template>
<script>
import 'babel-polyfill'
import 'es6-promise/auto'

export default {
  name: 'DEXT5Editor',
  props: {
    Id: String,
    Runtimes: String,
    Width: String,
    Height: String,
    data: String
  },
  data: function () {
    return {
      editorData: ''
    }
  },
  watch: {

  },
  created () {
    console.log('dext editor data = ' + this.data)
  },

  mounted () {
    let handlerUrl = ''
    if (process.env.VUE_APP_COMMON_URL.startsWith('http')) {
      handlerUrl = process.env.VUE_APP_COMMON_URL
    } else if (process.env.VUE_APP_COMMON_URL.startsWith('//')) {
      handlerUrl = location.protocol + process.env.VUE_APP_COMMON_URL
    }
    handlerUrl += '/commons/dext5/editor/handler'

    let deOptions = {
        Id: this.Id,
        Width: this.Width || '100%',
        Height: this.Height || '500px',
        EditorHolder: 'deHolder_' + this.Id,
        HandlerUrl: handlerUrl,
        Runtimes: this.Runtimes || 'html5',
        Lang: this.$i18n.locale.toLowerCase(),
        Event: {
            LoadedEvent: this.onLoadedEvent
        }
    }

    if (typeof (DEXT5) === 'undefined') {
        this.$loadScript('/dext5editor/js/dext5editor.js')
            .then(() => {
                this.MakeEditor(deOptions)
            })
            .catch(() => {
                // Failed to fetch script
            })
    } else {
        this.MakeEditor(deOptions)
    }
  },
  methods: {
    MakeEditor: function (options) {
        if (DEXT5.GetEditorByName(options.Id) !== null) {
            DEXT5.Destroy(options.Id)
        }

        DEXT5.config.Width = options.Width
        DEXT5.config.Height = options.Height
        DEXT5.config.Runtimes = options.Runtimes
        DEXT5.config.EditorHolder = options.EditorHolder
        DEXT5.config.Event = options.Event
        DEXT5.config.Lang = options.Lang
        if (options.HandlerUrl !== '') {
            DEXT5.config.HandlerUrl = options.HandlerUrl
        }

        new Dext5editor(options.Id)
    },
    GetData: function () {
      this.$emit('input', DEXT5.GetBodyValue(this.Id))
    },
    SetData: function (htmlString) {
        DEXT5.SetBodyValue(htmlString)
    },
    // DEXT5 Editor Event
    onLoadedEvent: function (editor) {
        console.log('onLoadedEvent ' + editor.ID)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
