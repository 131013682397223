var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "content resize" },
    [
      _c("e-breadcrumbs"),
      _vm._m(0),
      _c("div", { staticClass: "content_box" }, [
        _c("div", [
          _c(
            "button",
            {
              staticClass: "button blue lg mt10 layer_open",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.screenToPdfDownload()
                },
              },
            },
            [_vm._v("PDF 다운로드")]
          ),
          _vm._v("   "),
          _c(
            "button",
            {
              staticClass: "button blue lg mt10 layer_open",
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.screenToPdfUpload()
                },
              },
            },
            [_vm._v("PDF 서버에 저장")]
          ),
        ]),
        _vm.pdfUploadMessage != ""
          ? _c("div", { staticClass: "mt-3" }, [
              _vm._v(" " + _vm._s(_vm.pdfUploadMessage) + " "),
            ])
          : _vm._e(),
        _vm.pdfUploadMessage != ""
          ? _c("div", { staticClass: "mt-3" }, [
              _c(
                "a",
                {
                  attrs: {
                    href:
                      "http://dev-api.ekmtc.com/common/commons/tempfile?fileId=" +
                      _vm.tempFileId,
                    target: "_blank",
                  },
                },
                [_vm._v("다운로드")]
              ),
            ])
          : _vm._e(),
        _vm._m(1),
      ]),
      _vm._m(2),
      _vm._m(3),
      _vm._m(4),
      _c(
        "div",
        { staticClass: "content_box" },
        [_c("SampleAutoComplete"), _vm._m(5)],
        1
      ),
      _vm._m(6),
      _c(
        "div",
        { staticClass: "content_box" },
        [_c("SampleAutoCompletePlace"), _vm._m(7)],
        1
      ),
      _vm._m(8),
      _c(
        "div",
        { staticClass: "content_box" },
        [_c("SampleAutoCompleteCommodity"), _vm._m(9)],
        1
      ),
      _vm._m(10),
      _c(
        "div",
        { staticClass: "content_box" },
        [_c("SampleAutoCompleteCstEnm"), _vm._m(11)],
        1
      ),
      _vm._m(12),
      _c(
        "div",
        { staticClass: "content_box" },
        [_c("SampleMultiSelectBox"), _vm._m(13)],
        1
      ),
      _vm._m(14),
      _c(
        "div",
        { staticClass: "content_box" },
        [_c("SamplePopupCall"), _vm._m(15)],
        1
      ),
      _vm._m(16),
      _c(
        "div",
        { staticClass: "content_box" },
        [_c("SampleFormError"), _vm._m(17)],
        1
      ),
      _vm._m(18),
      _c(
        "div",
        { staticClass: "content_box" },
        [_c("SampleAddress"), _vm._m(19)],
        1
      ),
      _vm._m(20),
      _c(
        "div",
        { staticClass: "content_box" },
        [
          _c("SampleUploader"),
          _vm._m(21),
          _c("SampleUploaderEmbed"),
          _vm._m(22),
        ],
        1
      ),
      _vm._m(23),
      _c(
        "div",
        { staticClass: "content_box" },
        [_c("sample-date-picker"), _vm._m(24)],
        1
      ),
      _vm._m(25),
      _c(
        "div",
        { staticClass: "content_box" },
        [_c("SampleInputNumber"), _vm._m(26)],
        1
      ),
      _vm._m(27),
      _c(
        "div",
        { staticClass: "content_box" },
        [_c("sample-login"), _vm._m(28)],
        1
      ),
      _vm._m(29),
      _c("div", { staticClass: "content_box" }, [
        _vm._m(30),
        _c("p", [_vm._v(" obj.alertType : simple or normal")]),
        _c("p", [_vm._v(" obj.customCloseBtnClass : 닫기버튼 css class")]),
        _c("p", [_vm._v(" obj.customCloseBtnText: 닫기버튼 문구")]),
        _c("p", [_vm._v(" obj.customConfirmBtnClass: 확인버튼 css class")]),
        _c("p", [_vm._v(" obj.customConfirmBtnText: 확인버튼 문구")]),
        _c("p", [
          _vm._v(" obj.useConfirmBtn: 확인버튼 쓸지 말지 (true/false)"),
        ]),
        _c("p", [_vm._v(" obj.tile: normal 모드 알랏에서 타이틀 설정")]),
        _c("p", [_vm._v(" obj.message: 본문내용")]),
        _c("p", [
          _vm._v(" obj.type: info(체크아이콘) or success(느낌표아이콘)"),
        ]),
        _c("p", [_vm._v(" obj.showXclose: x버튼 노출할지 말지 (true/false) ")]),
        _c(
          "button",
          {
            staticClass: "button blue lg ml10",
            on: { click: _vm.openCheckAlert },
          },
          [_vm._v("체크알럿")]
        ),
        _c(
          "button",
          {
            staticClass: "button blue lg ml10",
            on: { click: _vm.openNoticeAlert },
          },
          [_vm._v("느낌표알럿")]
        ),
        _c(
          "button",
          { staticClass: "button blue lg ml10", on: { click: _vm.openAlert1 } },
          [_vm._v("alert1 확인")]
        ),
        _c(
          "button",
          { staticClass: "button blue lg ml10", on: { click: _vm.openAlert2 } },
          [_vm._v("alert2 확인닫기")]
        ),
        _c(
          "button",
          { staticClass: "button blue lg ml10", on: { click: _vm.asyncAlert } },
          [_vm._v("async alert")]
        ),
      ]),
      _vm._m(31),
      _c("div", { staticClass: "content_box" }, [
        _c("p", [_vm._v(" " + _vm._s(this.$i18n.t("label.TEST01")))]),
      ]),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex_box" }, [
      _c("h1", { staticClass: "page_title" }, [_vm._v("화면 출력")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "mt-3" }, [
      _c("span", { staticStyle: { color: "blue", "font-weight": "bold" } }, [
        _vm._v("SampleHome.vue"),
      ]),
      _vm._v(" 파일 참조"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex_box" }, [
      _c("h1", { staticClass: "page_title" }, [
        _vm._v("페이지 상단 Navigation"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "content_box" }, [
      _c("p", { staticStyle: { color: "blue", "font-weight": "bold" } }, [
        _vm._v("<Breadcrumbs />"),
      ]),
      _c("p", { staticClass: "ic_txt mt-3" }, [
        _vm._v("- 해당 페이지에 위 태그 삽입"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex_box" }, [
      _c("h1", { staticClass: "page_title" }, [_vm._v("자동완성")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "ic_txt mt-3" }, [
      _c("span", { staticStyle: { color: "blue", "font-weight": "bold" } }, [
        _vm._v("SampleAutoComplete.vue"),
      ]),
      _vm._v(" 파일 참조"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex_box" }, [
      _c("h1", { staticClass: "page_title" }, [
        _vm._v("자동완성 - 국가, 지역"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "ic_txt mt-3" }, [
      _c("span", { staticStyle: { color: "blue", "font-weight": "bold" } }, [
        _vm._v("SampleAutoCompletePlace.vue"),
      ]),
      _vm._v(" 파일 참조"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex_box" }, [
      _c("h1", { staticClass: "page_title" }, [_vm._v("자동완성 - Commodity")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "ic_txt mt-3" }, [
      _c("span", { staticStyle: { color: "blue", "font-weight": "bold" } }, [
        _vm._v("SampleAutoCompleteCommodity.vue"),
      ]),
      _vm._v(" 파일 참조"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex_box" }, [
      _c("h1", { staticClass: "page_title" }, [_vm._v("자동완성 - 실화주")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "ic_txt mt-3" }, [
      _c("span", { staticStyle: { color: "blue", "font-weight": "bold" } }, [
        _vm._v("SampleAutoCompleteCstEnm.vue"),
      ]),
      _vm._v(" 파일 참조"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex_box" }, [
      _c("h1", { staticClass: "page_title" }, [_vm._v("Multi select box")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "ic_txt mt-3" }, [
      _c("span", { staticStyle: { color: "blue", "font-weight": "bold" } }, [
        _vm._v("SampleMultiSelectBox.vue"),
      ]),
      _vm._v(" 파일 참조"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex_box" }, [
      _c("h1", { staticClass: "page_title" }, [_vm._v("팝업 호출")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "ic_txt mt-3" }, [
      _c("span", { staticStyle: { color: "blue", "font-weight": "bold" } }, [
        _vm._v("SamplePopupCall.vue"),
      ]),
      _vm._v(" 파일 참조"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex_box" }, [
      _c("h1", { staticClass: "page_title" }, [_vm._v("Form Error")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "ic_txt mt-3" }, [
      _c("span", { staticStyle: { color: "blue", "font-weight": "bold" } }, [
        _vm._v("SampleFormError.vue"),
      ]),
      _vm._v(" 파일 참조"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex_box" }, [
      _c("h1", { staticClass: "page_title" }, [_vm._v("주소 검색")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "ic_txt mt-3" }, [
      _c("span", { staticStyle: { color: "blue", "font-weight": "bold" } }, [
        _vm._v("SampleAddress.vue"),
      ]),
      _vm._v(" 파일 참조"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex_box" }, [
      _c("h1", { staticClass: "page_title" }, [_vm._v("파일 업로드")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "ic_txt mt-3" }, [
      _c("span", { staticStyle: { color: "blue", "font-weight": "bold" } }, [
        _vm._v("SampleUploader.vue"),
      ]),
      _vm._v(" 파일 참조"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "ic_txt mt-3" }, [
      _c("span", { staticStyle: { color: "blue", "font-weight": "bold" } }, [
        _vm._v("SampleUploaderEmbed.vue"),
      ]),
      _vm._v(" 파일 참조"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex_box" }, [
      _c("h1", { staticClass: "page_title" }, [_vm._v("Date Picker")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "ic_txt mt-3" }, [
      _c("span", { staticStyle: { color: "blue", "font-weight": "bold" } }, [
        _vm._v("SampleDatePicker.vue"),
      ]),
      _vm._v(" 파일 참조"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex_box" }, [
      _c("h1", { staticClass: "page_title" }, [_vm._v("숫자 입력")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "ic_txt mt-3" }, [
      _c("span", { staticStyle: { color: "blue", "font-weight": "bold" } }, [
        _vm._v("SampleInputNumber.vue"),
      ]),
      _vm._v(" 파일 참조"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex_box" }, [
      _c("h1", { staticClass: "page_title" }, [_vm._v("로그인")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "ic_txt mt-3" }, [
      _c("span", { staticStyle: { color: "blue", "font-weight": "bold" } }, [
        _vm._v("SampleLogin.vue"),
      ]),
      _vm._v(" 파일 참조"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex_box" }, [
      _c("h1", { staticClass: "page_title" }, [_vm._v("alert ")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", { staticClass: "ic_txt mt-3" }, [
      _c("span", { staticStyle: { color: "blue", "font-weight": "bold" } }, [
        _vm._v("this.$ekmtcCommon.alert(obj)"),
      ]),
      _vm._v("호출"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "flex_box" }, [
      _c("h1", { staticClass: "page_title" }, [_vm._v("다국어확인 ")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }