var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "container_ekmtc" }, [
    _c(
      "div",
      { staticClass: "content" },
      [
        _c("h1", { staticClass: "page_title" }, [_vm._v("ITEXT 팝업 리스트")]),
        _c(
          "win-layer-pop",
          { staticClass: "sample_popup_list" },
          [
            _vm.customComponent
              ? _c(_vm.customComponent, {
                  tag: "component",
                  attrs: { "parent-info": _vm.parentInfo },
                  on: { close: _vm.closePopup },
                })
              : _vm._e(),
          ],
          1
        ),
        _c("div", { staticClass: "content_box" }, [
          _c("h1", { staticClass: "page_title" }, [_vm._v("B/L Copy")]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(0),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("B/L No.")]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addBlCopy()
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                    _c("th"),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(
                    _vm.popupParams.BlCopyItextPop.items,
                    function (vo, idx) {
                      return _c("tr", { key: "BlCopyItextPop_" + idx }, [
                        _c("td", [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: vo.blNo,
                                expression: "vo.blNo",
                              },
                            ],
                            attrs: { type: "text" },
                            domProps: { value: vo.blNo },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.$set(vo, "blNo", $event.target.value)
                              },
                            },
                          }),
                        ]),
                        _c("td", [
                          _c(
                            "button",
                            {
                              staticClass: "tbl_icon minus",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  $event.preventDefault()
                                  return _vm.removeBlCopy(idx)
                                },
                              },
                            },
                            [_vm._v("minus")]
                          ),
                        ]),
                        _c("td"),
                      ])
                    }
                  ),
                  0
                ),
              ]),
              _c("p", { staticClass: "comment" }, [
                _vm._v(
                  "아래값은 페이지 연동시 사용하지 말것!! (세션값이 셋팅됨)"
                ),
              ]),
              _c("table", { staticClass: "tbl_type mt5" }, [
                _vm._m(1),
                _vm._m(2),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.BlCopyItextPop.testUserId,
                            expression: "popupParams.BlCopyItextPop.testUserId",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.BlCopyItextPop.testUserId,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.BlCopyItextPop,
                              "testUserId",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.BlCopyItextPop.testCtrCd,
                            expression: "popupParams.BlCopyItextPop.testCtrCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.BlCopyItextPop.testCtrCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.BlCopyItextPop,
                              "testCtrCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.BlCopyItextPop.testBkgPlcCd,
                            expression:
                              "popupParams.BlCopyItextPop.testBkgPlcCd",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.BlCopyItextPop.testBkgPlcCd,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.BlCopyItextPop,
                              "testBkgPlcCd",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("BlCopyItextPop")
                      },
                    },
                  },
                  [_vm._v("B/L Copy 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("h1", { staticClass: "page_title" }, [
            _vm._v("DG Declaration Download"),
          ]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(3),
                _c("thead", [
                  _c("tr", [
                    _c("th", [_vm._v("Booking No.")]),
                    _c("th", [
                      _c(
                        "button",
                        {
                          staticClass: "tbl_icon plus",
                          attrs: { type: "button" },
                          on: {
                            click: function ($event) {
                              $event.preventDefault()
                              return _vm.addDg()
                            },
                          },
                        },
                        [_vm._v("plus")]
                      ),
                    ]),
                    _c("th"),
                  ]),
                ]),
                _c(
                  "tbody",
                  _vm._l(_vm.popupParams.DgItextPop.items, function (vo, idx) {
                    return _c("tr", { key: "DgItextPop_" + idx }, [
                      _c("td", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: vo.bkgNo,
                              expression: "vo.bkgNo",
                            },
                          ],
                          attrs: { type: "text" },
                          domProps: { value: vo.bkgNo },
                          on: {
                            input: function ($event) {
                              if ($event.target.composing) return
                              _vm.$set(vo, "bkgNo", $event.target.value)
                            },
                          },
                        }),
                      ]),
                      _c("td", [
                        _c(
                          "button",
                          {
                            staticClass: "tbl_icon minus",
                            attrs: { type: "button" },
                            on: {
                              click: function ($event) {
                                $event.preventDefault()
                                return _vm.removeDg(idx)
                              },
                            },
                          },
                          [_vm._v("minus")]
                        ),
                      ]),
                      _c("td"),
                    ])
                  }),
                  0
                ),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("DgItextPop")
                      },
                    },
                  },
                  [_vm._v("DG Declaration Download 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
        _c("div", { staticClass: "content_box" }, [
          _c("h1", { staticClass: "page_title" }, [_vm._v("Drop Off Charge")]),
          _c("ul", { staticClass: "ul_box" }, [
            _c("li", { staticClass: "fl" }, [
              _c("table", { staticClass: "tbl_type" }, [
                _vm._m(4),
                _vm._m(5),
                _c("tbody", [
                  _c("tr", [
                    _c("td", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.popupParams.DropItextPop.reqRno,
                            expression: "popupParams.DropItextPop.reqRno",
                          },
                        ],
                        attrs: { type: "text" },
                        domProps: {
                          value: _vm.popupParams.DropItextPop.reqRno,
                        },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) return
                            _vm.$set(
                              _vm.popupParams.DropItextPop,
                              "reqRno",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("td"),
                    _c("td"),
                  ]),
                ]),
              ]),
              _c("div", { staticClass: "btn_wrap" }, [
                _c(
                  "button",
                  {
                    staticClass: "button blue lg mt10 layer_open",
                    on: {
                      click: function ($event) {
                        return _vm.openPopup("DropItextPop")
                      },
                    },
                  },
                  [_vm._v("Drop Off Charge 팝업")]
                ),
              ]),
            ]),
            _c("li", { staticClass: "fr" }),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "200" } }),
      _c("col", { attrs: { width: "80" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "180" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("USER_ID")]),
        _c("th", [_vm._v("CTR_CD")]),
        _c("th", [_vm._v("BKG_PLC_CD")]),
        _c("th"),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "200" } }),
      _c("col", { attrs: { width: "80" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { attrs: { width: "200" } }),
      _c("col", { attrs: { width: "80" } }),
      _c("col", { attrs: { width: "*" } }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [_c("th", [_vm._v("Req Rno.")]), _c("th"), _c("th")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }