<template>
    <div>
        <div class="row">
            <div class="col-md-4">
                <label for="input-with-dynamic-list">Dynamic</label>
                <b-form-input list="input-dynamic-list" id="input-with-dynamic-list" @keyup="dynamicSearchPlace()" v-model="dynamicKeyword"></b-form-input>
                <b-form-datalist id="input-dynamic-list" :options="dynamicitems"></b-form-datalist>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <label for="input-with-static-list">Static</label>
                <b-form-input list="input-static-list" id="input-with-static-list" v-model="staticKeyword"></b-form-input>
                <b-form-datalist id="input-static-list" :options="staticItems"></b-form-datalist>
            </div>
            <div class="col-md-4">
                <label for="input-with-static2-list">Static</label>
                <b-form-input list="input-static2-list" id="input-with-static2-list"></b-form-input>
                <b-form-datalist id="input-static2-list" :options="static2Items" value-field="plcEnm" text-field="plcEnm"></b-form-datalist>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4">
                <label for="input-with-self">Dynamic</label>
                <input id="input-with-self" @focus="searchPlaceSelf()" @keyup="searchPlaceSelf()" @keyup.down="keydown()" v-model="keyword" class="form-control" autocomplete="off"/>
                <select class="custom-select" id="input-self-list" @keyup.enter="selectSearchPlaceEnter()" v-show="fromSelBox.show" :size="fromSelBox.size">
                    <option v-for="cvo in items" :key="cvo.plcCd" :value="cvo.plcCd">{{cvo.plcEnm}}</option>
                </select>
            </div>
            <!-- <ul>
                <li v-for="cvo in items" :key="cvo.plcCd"><a href="#">{{cvo.plcEnm}}</a></li>
            </ul> -->
        </div>
    </div>
</template>

<script>

import axios from 'axios'

export default {
    name: 'SearchPlace',
    data () {
        return {
            dynamicitems: [],
            staticItems: [],
            static2Items: [],
            dynamicKeyword: '',
            staticKeyword: '',
            isLoading: false,
            items: [],
            model: null,
            search: null,
            tab: null,
            keyword: '',
            prevKeyword: '',
            fromSelBox: {
                show: false,
                size: 4
            }
        }
    },
    created () {
        this.staticPlace()
    },
    methods: {
        staticPlace () {
            const THIS = this

            axios
                .get('http://172.17.213.67:9005/commons/places?allYn=Y')
                .then(res => {
                    const list = res.data
                    if (list === undefined) {
                        THIS.staticItems = []
                        THIS.static2Items = []
                    } else {
                        THIS.staticItems = list.map(vo => vo.plcEnm)
                        THIS.static2Items = list
                    }
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                })
        },
        keydown () {
            if (this.items.length === 0) {
                return
            }
            document.querySelector('#input-self-list').focus()
            document.querySelector('#input-self-list > option').selected = true
        },
        searchPlaceSelf () {
            if (this.items.length > 0) {
                this.fromSelBox.show = true
            }

            if (this.prevKeyword === this.keyword) {
                console.log(this.prevKeyword + '/' + this.keyword)
                return
            }
            this.searchPlace()
            this.prevKeyword = this.keyword
        },
        selectSearchPlaceEnter () {
            const val = document.querySelector('#input-self-list').value
            const data = this.items.find(vo => vo.plcCd === val)

            this.keyword = data.plcEnm
            this.prevKeyword = data.plcEnm
            this.fromSelBox.show = false
        },
        searchPlace (type) {
            const THIS = this
            const keyword = this.keyword

            axios
                .get('http://172.17.213.67:9005/commons/places?plcNm=' + keyword)
                .then(res => {
                    const list = res.data
                    if (list === undefined) {
                        THIS.items = []
                    } else {
                        THIS.items = list
                        THIS.fromSelBox.show = true
                        // THIS.items = list.map(vo => vo.plcEnm)
                        // THIS.items = list.map(vo => {
                        //     vo.value = vo.plcEnm
                        //     vo.text = vo.plcEnm
                        //     return vo
                        // })
                    }
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    THIS.isLoading = false
                })
        },
        fnChange () {
            console.log('--->' + this.keyword)
        },
        dynamicSearchPlace () {
            const THIS = this
            const keyword = this.dynamicKeyword
            THIS.isLoading = true

            axios
                .get('http://172.17.213.67:9005/commons/places?plcNm=' + keyword)
                .then(res => {
                    const list = res.data
                    if (list === undefined) {
                        THIS.dynamicitems = []
                    } else {
                        THIS.dynamicitems = list.map(vo => vo.plcEnm)
                    }
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    THIS.isLoading = false
                })
        }
    },
    watch: {
        model (val) {
            if (val != null) this.tab = 0
            else this.tab = null
        },
        search (val) {
            // Items have already been loaded
            // if (this.items.length > 0) return
            // if (this.isLoading) {
            //     return
            // }

            console.log(val)

            this.isLoading = true
            const THIS = this

            axios
                .get('http://172.17.213.67:9005/commons/places?plcNm=' + val)
                .then(res => {
                    const list = res.data
                    if (list === undefined) {
                        THIS.items = []
                    } else {
                        // THIS.items = list.map(vo => vo.plcEnm)
                        THIS.items = list
                    }
                })
                .catch(err => {
                    console.log(err)
                })
                .finally(() => {
                    THIS.isLoading = false
                })

            // Lazily load input items
            // fetch('https://api.coingecko.com/api/v3/coins/list')
            // fetch('http://127.0.0.1:9005/commons/places?plcNm=' + val)
            //     .then(res => res.clone().json())
            //     .then(res => {
            //         console.log(res)
            //         this.items = res.map(vo => vo.plcEnm)
            //         console.log(this.items)
            //     })
            //     .catch(err => {
            //         console.log(err)
            //     })
            //     .finally(() => (this.isLoading = false))
        }
    }
}
</script>

<style>
.v-menu {
    display: block !important;
}
</style>
