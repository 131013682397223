<template>
  <select
    v-model='value'
    class='custom-select'
    v-bind:id='input_id'
    v-on:input='updateValue($event.target.value)'>
    <option v-for='(item, index) in items' :value='index' v-bind:key="item">{{ item }}</option>
  </select>
</template>

<script>
export default {
  name: 'SelectBox',

  data () {
    return {

    }
  },

  props: {
    value: String,
    items: Object,
    input_id: String
  },

  methods: {
    updateValue: function (value) {
      console.log('value = ' + JSON.stringify(value))
      console.log('input_id = ' + this.input_id)

      this.$emit('input', value)
    }
  }

}
</script>

<style scoped>

</style>
