<template>
  <div style="min-height:600px;">
    <div class="content log_admin">
      <div class="login_box">
        <div class="log_form">
          <div class="tit_wrap">
            <h2 class="tit">임시 Login</h2>
            <div class="kmtc_logo">
              <span></span>
            </div>
          </div>
          <div class="log_page_form">
            <ul>
              <li>
                <span class="tit">ID</span>
                <input type="text" v-model="form.userId" placeholder="ID">
              </li>
              <li>
                <span class="tit">Password</span>
                <input type="text" v-model="form.userPwd" placeholder="Password">
              </li>
              <li>
                <select v-model="form.logCatCd">
                  <option value="05">Customer</option>
                  <option value="01">KMTC</option>
                </select>
              </li>
              <li class="log_btn">
                <a href="#" @click.prevent="submit">
                  <i></i>
                  <span>로그인</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div class="copyright">임시 로그인 페이지 입니다.!!!!!</div>
      </div>
    </div>
    <!--
    <b-container fluid>
      <b-row class="mt-4 mb-4">
        <b-col>
          <h2>Login Page</h2>
        </b-col>
      </b-row>
      <b-form v-if="show" @submit.prevent="submit" :novalidate="true">
        <b-form-group label-for="input-1">
          <b-form-input
            id="input-1"
            v-model="form.userId"
            type="text"
            placeholder="아이디"
            required
          ></b-form-input>
        </b-form-group>

        <b-form-group label-for="input-2">
          <b-form-input
            id="input-2"
            v-model="form.userPwd"
            placeholder="비밀번호"
            required></b-form-input>
        </b-form-group>
        <b-row>
          <b-col>
            <b-button class="mr-2" type="submit" variant="outline-primary"
              >Submit</b-button>
          </b-col>
        </b-row>
      </b-form>
    </b-container>
    -->
  </div>
</template>

<script>
import { rootComputed, rootMethods } from '@/store/helpers'

export default {
  data () {
    return {
      form: {
        logCatCd: '05',
        userId: '',
        userPwd: ''
      },
      show: true
    }
  },
  computed: {
    ...rootComputed
  },
  methods: {
    ...rootMethods,
    submit: function () {
      // console.log(this.form.userId)
      // console.log(this.form.userPwd)
      if (this.form.userId === '' && this.form.userPwd === '') {
        this.makeToast()
      } else {
        this.login(this.form)
          .then(() => {
            console.log('view success')
            this.$router.push('/sample/home')
          })
          .catch(err => {
            console.log('view error')
            throw err
          })
      }
    },
    makeToast: function (variant = null) {
      this.$bvToast.toast('아이디/비밀번호를 입력하세요', {
        title: 'Notice',
        variant: 'danger',
        solid: true
      })
    }
  }
}
</script>

<style>
</style>
