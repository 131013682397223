var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("table", { staticClass: "tbl_row" }, [
      _vm._m(0),
      _c("tbody", [
        _c("tr", [
          _vm._m(1),
          _c(
            "td",
            [
              _c("select-box", {
                attrs: { items: _vm.items, input_id: "selectbox_id" },
                on: {
                  input: (value) => {
                    _vm.data = value
                  },
                },
                model: {
                  value: _vm.data,
                  callback: function ($$v) {
                    _vm.data = $$v
                  },
                  expression: "data",
                },
              }),
              _c("select-box", {
                attrs: { items: _vm.items2, input_id: "selectbox2_id" },
                on: {
                  input: (value) => {
                    _vm.data2 = value
                  },
                },
                model: {
                  value: _vm.data2,
                  callback: function ($$v) {
                    _vm.data2 = $$v
                  },
                  expression: "data2",
                },
              }),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col", { attrs: { width: "140" } }), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("th", [
      _vm._v("Select Box"),
      _c("p", { staticStyle: { color: "red" } }, [
        _vm._v("(퍼블 관련 확인필요)"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }