<template>
  <div class="content resize">
    <div class="content_box">
      <form id="frm">
        <h2 class="content_title">화물정보</h2>
        <table class="tbl_row">
          <colgroup>
            <col width="130">
            <col>
            <col width="130">
            <col>
          </colgroup>
          <tbody>
            <tr>
              <th>실화주 변경</th>
              <td>
                <input type="text" v-model="rsParams2.actShprCstEnm" class="col_3"/>
                <input type="text" v-model="rsParams2.bkgShprCstNo" class="col_3"/>
                <a class="button blue sm" href="#" @click.prevent="fnActShprCstEnmApply()">적용</a>
              </td>
              <th></th>
              <td>
                <div>
                  {{ cmdParams.cmdtDsc }}
                </div>
                <div>
                  <e-auto-complete-commodity
                    :ctr-cd="cmdParams.usrCtrCd"
                    :cd-nm="cmdParams.cmdtDsc"
                    class="col_6 auto-area position_relative"
                    id="cmdtDsc"
                    @change="changeCmdtDsc"
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>실화주</th>
              <td>
                <div>
                  {{ rsParams.actShprCstEnm }} / {{ rsParams.bkgShprCstNo }} / {{ rsParams.actShprCstEnmYn }}
                </div>
                <div>
                  <e-auto-complete-cst-enm-v2
                    class="col_6 auto-area position_relative"
                    id="actCst"
                    @change="actChange"
                    :cd-nm="rsParams.actShprCstEnm"
                  />
                  <span class="float_right">
                    <input type="checkbox" id="c1" v-model="regParams.consoleBkgYn" value="Y" />
                    <label for="c1"><span></span>콘솔부킹</label>
                  </span>
                </div>
              </td>
              <th>G. Weight</th>
              <td>
                <span class="wg">
                  <input type="text" v-model="regParams.weightTon" class="wid80"> ton
                </span>
                <span style=" display:inline-block; width:30px; text-align:center" class="color_gray">/</span>
                <span class="wg">
                  <input type="text" v-model="regParams.weightKg" class="wid80"> kg
                </span>
              </td>
            </tr>
            <tr>
              <th scope="row">Ocean Freight</th>
              <td>
                <select class="col_6" v-model="regParams.oceanFreight">
                  <option value="">선택</option>
                  <option value="TEMP_A">PREP AID</option>
                  <option value="TEMP_B">COLLECT</option>
                </select>
              </td>
              <th scope="row">SC No.</th>
              <td><input type="text" v-model="regParams.scNo"></td>
            </tr>
            <tr>
              <th>Bidding No.<span class="txt">(선택)</span></th>
              <td>
                <input type="text" v-model="regParams.biddingNo">
              </td>
              <th>
                checkbox 선택
              </th>
              <td>
                <span class="position_relative">
                  <input type="checkbox" id="sampleCheckbox_1" v-model="regParams.chkbox" value="T001">
                  <label for="sampleCheckbox_1">T0001</label>
                  <input type="checkbox" id="sampleCheckbox_2" v-model="regParams.chkbox" value="T002">
                  <label for="sampleCheckbox_2">T0002</label>
                  <input type="checkbox" id="sampleCheckbox_3" v-model="regParams.chkbox" value="T003">
                  <label for="sampleCheckbox_3">T0003</label>
                  <input type="checkbox" id="sampleCheckbox_4" v-model="regParams.chkbox" value="T004">
                  <label for="sampleCheckbox_4">T0004</label>
                </span>
              </td>
            </tr>
          </tbody>
        </table>

        <table class="tbl_col mt20">
          <colgroup>
            <col width="25%" />
            <col width="15%" />
            <col width="10%" />
            <col width="10%" />
            <col width="35%" />
            <col width="5%" />
          </colgroup>
          <thead>
            <tr>
              <th>Col1</th>
              <th>Col2</th>
              <th>Col3</th>
              <th>Col4</th>
              <th>Col5</th>
              <th>
                <button class="tbl_icon plus" type="button" @click.prevent="addRows()">plus</button>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(vo, idx) in regParams.list" :key="'tr_' + idx">
              <td>
                <input type="text" name="arrCol1" v-model="vo.col1">
              </td>
              <td>
                <select name="arrCol2" v-model="vo.col2">
                  <option value="">선택</option>
                  <option value="sel1">SEL01</option>
                  <option value="sel2">SEL02</option>
                  <option value="sel3">SEL03</option>
                </select>
              </td>
              <td><input type="text" name="arrCol3" v-model="vo.col3"></td>
              <td><input type="text" name="arrCol4" v-model="vo.col4"></td>
              <td style="text-align:left;">
                <div>
                  <input type="text" name="arrCol5Cd" v-model="vo.col5Cd" class="col_4">
                  <input type="text" name="arrCol5Nm" v-model="vo.col5Nm" class="col_4">
                  <button class="tbl_icon plus" type="button" @click.prevent="addCol5(idx)">plus</button>
                </div>
                <ul class="ul_box">
                  <li v-for="(svo, sidx) in vo.col5" :key="'li_col5_' + sidx">
                    [{{ svo.code }}] {{ svo.name }} <button class="tbl_icon minus" type="button" @click.prevent="removeCol5(idx, sidx)">minus</button>
                  </li>
                </ul>
              </td>
              <td>
                <button class="tbl_icon minus" type="button" @click.prevent="removeRows(idx)">minus</button>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="mt10 text_right">
          <a class="button blue lg mr5" href="#" @click.prevent="apply()">적용</a>
          <a class="button gray lg" href="#" @click.prevent="compareCheck()">비교</a>
        </div>
      </form>
    </div>

    <div class="content_box mt5">
      <h2 class="content_title">파일 업로드</h2>
      <input @change="fnFileUpload()" type="file" id="inpFile" />

      <p>ID : {{ tempFile.id }}</p>
      <p>MSG : {{ tempFile.msg }}</p>
    </div>

    <div class="content_box mt5">
      <h2 class="content_title">Booking 취소 테스트</h2>
      <input type="text" v-model="bookingCancelParams.bkgNo" />
      <input type="text" v-model="bookingCancelParams.lang" class="mr5" />

      <a class="button blue sm mr5" href="#" @click.prevent="cancelBooking()">Booking Cancel</a>
      <a class="button blue sm" href="#" @click.prevent="test()">Test</a>
      <component
        v-if="commonBookingComponent"
        :is="commonBookingComponent"
        :parent-info="commonBookingParams"
        ref="commonBooking"
        @callback="commonBookingCallback"
      />
    </div>

    <div class="content_box mt5">
      {{ alertTest }} : <a class="button blue sm mr5" href="#" @click.prevent="alertMessage()">AlertMessage</a>
    </div>

    <div class="content_box mt5">
      <a class="button blue sm mr5" href="#" @click.prevent="goWorkingProgress('Q')">Go -&gt; 진행중인업무 Query</a>
      <a class="button blue sm mr5" href="#" @click.prevent="goWorkingProgress('P')">Go -&gt; 진행중인업무 Params</a>
      <a class="button blue sm mr5" href="#" @click.prevent="goFreetimeRequest('Q')">Go -&gt; FREETIME Request Query</a>
      <a class="button blue sm mr5" href="#" @click.prevent="goFreetimeRequest('P')">Go -&gt; FREETIME Request Params</a>
    </div>
    <div class="content_box mt5">
      <table class="tbl_col mt10 tbl_cargo_tracking">
      <tr v-for="(vo, idx) in convertList" :key="'convertList_' + idx">
        <td v-if="idx == 0" :rowspan="rowCnt">{{ vo.blNo }}</td>
        <td v-if="idx == 0" :rowspan="rowCnt">{{ vo.bkgNo }}</td>
        <td v-if="vo.cntrIdx == 0" :rowspan="vo.rowCnt" class="td_left_line">
          <p v-for="(cvo, cidx) in cntrList" :key="'cntrNo_' + cidx">
            <span v-if="cvo.cntrNo == vo.cntrNo">{{ cvo.cntrNo }}</span>
            <a v-else href="#" class="link" @click.prevent="setCntrList(cvo.cntrNo)">{{ cvo.cntrNo }}</a>
          </p>
        </td>
        <td class="td_left_line">{{ vo.polPortNm }}</td>
        <td class="td_left_line">{{ vo.podPortNm }}</td>
        <td class="td_left_line">{{ vo.eta }}</td>
        <td class="td_left_line">{{ vo.etd }}</td>
      </tr>
      </table>
    </div>

    <win-layer-pop class="sample_compare_object">
      <component
        :is="customComponent"
        v-if="customComponent"
        :parent-info="popParams"
        @close="closePopup"
        @selectFunc="selectFunc"
      />
    </win-layer-pop>
  </div>
</template>

<script>

import commonsApi from '@/api/services/commons'

export default {
  name: 'SampleCompareObject',
  components: {
    WinLayerPop: () => import('@/pages/trans/popup/WinLayerPop'),
    CommonBooking: () => import('@/pages/trans/CommonBooking'),
    EAutoCompleteCstEnmV2: () => import('@/components/common/EAutoCompleteCstEnmV2'),
    EAutoCompleteCommodity: () => import('@/components/common/EAutoCompleteCommodity')
  },
  // mixins: [commonBooking],
  data () {
    return {
      commonBookingComponent: null,
      commonBookingParams: {},
      commonBookingCallback: null,
      customComponent: null,
      popParams: {},
      bookingCancelParams: {
        bkgNo: 'KR03152177',
        lang: 'KOR'
      },
      originParams: {},
      selectFunc: null,
      cmdParams: {
        usrCtrCd: 'KR',
        cmdtDsc: ''
      },
      regParams: {
        realCompany: '',
        consoleBkgYn: '',
        weightTon: '',
        weightKg: '',
        oceanFreight: '',
        scNo: '',
        biddingNo: '',
        chkbox: [],
        list: [{
          col1: '',
          col2: '',
          col3: '',
          col4: '',
          col5: [
            { code: 'A01', name: 'A01-입니다.' }
          ]
        }]
      },
      tempFile: {
        id: '',
        msg: ''
      },
      alertTest: '',
      convertList: [],
      rowCnt: 0,
      cntrList: [
        {
          rowCnt: 2,
          blNo: 'AJM0000091',
          bkgNo: 'AE00036974',
          cntrNo: 'SEGU2488193',
          cntrSzCd: '20',
          cntrTypCd: 'GP',
          podPortNm: 'JEBEL ALI%%BUSAN NEW PORT%%BUSAN%%KOBE',
          polPortNm: 'AJMAN%%JEBEL ALI%%BUSAN NEW PORT%%BUSAN',
          vslNm: '1)DUBAI FORTUNE/432D%%2)EMIRATES DANA/02104E%%3)Truckage/210325%%4)SUNNY SPRUCE/2112S',
          voyNo: '432D',
          etd: '202102251800%%202103020442%%000000000000%%202103271805',
          eta: '202102262000%%202103250200%%000000000000%%202103291710',
          confirmCd: '02%%02%%02%%03',
          cnntDds: '1%%1%%1%%1',
          trstDds: '1%%21%%1%%2',
          polSchChk: 'A',
          tsYn: 'Y'
        },
        {
          rowCnt: 2,
          blNo: 'AJM0000091',
          bkgNo: 'AE00036974',
          cntrNo: 'TEMU5529543',
          cntrSzCd: '20',
          cntrTypCd: 'GP',
          podPortNm: 'JEBEL ALI%%BUSAN NEW PORT%%BUSAN%%KOBE',
          polPortNm: 'AJMAN%%JEBEL ALI%%BUSAN NEW PORT%%BUSAN',
          vslNm: '1)DUBAI FORTUNE/432D%%2)EMIRATES DANA/02104E%%3)Truckage/210325%%4)SUNNY SPRUCE/2112S',
          voyNo: '432D',
          etd: '202102251800%%202103020442%%000000000000%%202103271805',
          eta: '202102262000%%202103250200%%000000000000%%202103291710',
          confirmCd: '02%%02%%02%%03',
          cnntDds: '1%%1%%1%%1',
          trstDds: '1%%21%%1%%2',
          polSchChk: 'A',
          tsYn: 'Y'
        }
      ],
      rsParams2: {
        bkgShprCstNo: 'K70493',
        actShprCstEnm: 'AMORE PACIFIC CORPORATION'
      },
      rsParams: {
        actCtrCd: 'KR',
        cstCatCd: '01',
        bkgShprCstNo: '',
        actShprCstEnm: '',
        actShprCstEnmYn: 'N'
      }
    }
  },
  created () {
    this._ = require('lodash')

    // const items = [
    //   { bkgNo: 'BK001', srRno: 'SR001', fstEntDtm: '20210801113500' },
    //   { bkgNo: 'BK002', srRno: 'SR002', fstEntDtm: '20210901113500' },
    //   { bkgNo: 'BK003', srRno: 'SR003', fstEntDtm: '20210815113500' },
    //   { bkgNo: 'BK004', srRno: '', fstEntDtm: '20210923113500' }
    // ]

    // const srList = items.filter(vo => vo.srRno !== '')
    // console.log(srList)

    // const mstVo = srList.reduce((vo1, vo2) => parseInt(vo1.fstEntDtm) > parseInt(vo2.fstEntDtm) ? vo1 : vo2)
    // console.log(mstVo)

    // const arrBkgNo = items.filter(vo => vo.bkgNo !== mstVo.bkgNo).map(vo => vo.bkgNo)
    // const multiBkgParam = {
    //   bkgNo: mstVo.bkgNo,
    //   srRno: mstVo.srRno,
    //   arrBkgNo: arrBkgNo
    // }

    // console.log(multiBkgParam)

    const url = '/report/email/urgent-getoff?arrPortCd=INC&arrParm1=SGN3685211&arrParm1=SGN3685212&arrParm1=SGN3685212&usrNo=KMTCAIR&ctrCd=KR&ctrCd=KR&'
    const bodyReq = {}
    let onlyUrl = ''
    // URL 에 있는 GET Parameter 를 body 로 변경
    if (url.indexOf('?') > -1) {
      onlyUrl = url.substring(0, url.indexOf('?'))
      const arrParams = url.substring(url.indexOf('?') + 1).split('&')

      arrParams.forEach(str => {
        const k = str.substring(0, str.indexOf('='))
        const v = str.substring(str.indexOf('=') + 1)

        if (bodyReq[k] === undefined) {
          bodyReq[k] = v
        } else if (typeof bodyReq[k] === 'string') {
          bodyReq[k] = [bodyReq[k], v]
        } else {
          bodyReq[k].push(v)
        }
      })
    }

    console.log('----->', url)
    console.log(onlyUrl, bodyReq)

    this.setCntrList('')
  },
  methods: {
    apply () {
      console.log('---- apply ----')
      this.originParams = this._.cloneDeep(this.regParams)
    },
    compareCheck () {
      const excludeKeys = ['list.col5Cd', 'list.col5Nm']
      const res = this.$ekmtcCommon.compareObject(this.regParams, this.originParams, excludeKeys)

      console.log(res)

      if (res.length > 0) {
        alert('수정내용 있음\n\n- ' + res.join('\n- '))
      } else {
        alert('일치함')
      }
    },
    addCol5 (idx) {
      const vo = this.regParams.list[idx]
      if (vo.col5Cd === undefined || vo.col5Cd === '' || vo.col5Nm === undefined || vo.col5Nm === '') {
        alert('필수값 입력')
        return
      }
      vo.col5.push({
        code: vo.col5Cd,
        name: vo.col5Nm
      })
    },
    removeCol5 (idx, sidx) {
      this.regParams.list[idx].col5.splice(sidx, 1)
    },
    addRows () {
      this.regParams.list.push({
        col1: '',
        col2: '',
        col3: '',
        col4: '',
        col5: []
      })
    },
    removeRows (idx) {
      this.regParams.list.splice(idx, 1)

      if (this.regParams.list.length === 0) {
        this.addRows()
      }
    },
    openPopup (compNm) {
      this.customComponent = compNm
      this.$ekmtcCommon.layerOpen('.sample_compare_object')
    },
    fnFileUpload () {
      const formData = new FormData()
      const inpFile = document.querySelector('#inpFile')
      // formData.append('files', inpFile.files[0], 'sample-excel.xls')
      formData.append('files', inpFile.files[0])

      commonsApi.tempFileUpload(formData).then((result) => {
          const fileId = result.data.fileId
          this.tempFile.id = fileId
          this.tempFile.msg = 'SELECT * FROM CM530I WHERE FILE_ID = ' + fileId + ';'
        })
        .catch(err => {
          console.log(err)
        })
    },
    closePopup () {
      this.customComponent = null
      this.selectFunc = null
      this.$ekmtcCommon.layerClose('.sample_compare_object')
    },
    cancelBooking () {
      this.commonBookingParams = {
        action: 'cancel',
        bkgNo: this.bookingCancelParams.bkgNo,
        lang: this.bookingCancelParams.lang
      }
      this.commonBookingCallback = this.refresh

      if (this.commonBookingComponent === null) {
        this.commonBookingComponent = 'common-booking'
      } else {
        this.$refs.commonBooking.doAction()
      }
    },
    test () {
      const arr = ['1', '2', '3']

      arr.forEach(s => {
        console.log('----->', s)
        if (s === '2') {
          return true
        }
      })

      console.log('-----> End')
    },
    refresh () {
      alert('refresh')
    },
    async alertMessage () {
      this.alertTest = ''

      const options = {
        // useConfirmBtn: true,
        message: '안녕하세요 <input type="checkbox" id="alertChk01" v-model="regParams.consoleBkgYn" value="Y" /><label for="alertChk01"><span></span>콘솔부킹</label>',
        onConfirmSelf: (resolve, $Simplert) => {
          console.log('checked =>', $('#alertChk01').is(':checked'))
          if ($('#alertChk01').is(':checked')) {
            resolve(true)
            $Simplert.close()
          }
        }
      }

      if (!await this.$ekmtcCommon.asyncAlertMessage(options)) {
        this.alertTest = 'cancel'
      } else {
        this.alertTest = 'ok'
      }
    },
    goFreetimeRequest (type) {
      const query = {
        eiCatCd: 'O',
        searchType: 'BL',
        num: 'PUSD977076'
      }
      if (type === 'Q') {
        this.$router.push({ name: 'FreeTimeRequest', query: query }).catch(() => {})
      } else {
        this.$router.push({ name: 'FreeTimeRequest', params: query }).catch(() => {})
      }
    },
    goWorkingProgress (type) {
      const query = {
        keyword: 'PUSE025712A,PUS0010',
        workType: 'E',
        kind: '02'
      }
      if (type === 'Q') {
        this.$router.push({ name: 'progress', query: query }).catch(() => {})
      } else {
        this.$router.push({ name: 'progress', params: query }).catch(() => {})
      }
    },
    setCntrList (cntrNo) {
      this.convertList = this.cntrList.reduce((arr, vo, idx) => {
        if (arr === undefined) {
          arr = []
        }

        if (cntrNo === '' && idx !== 0) {
          return arr
        } else if (cntrNo !== '' && cntrNo !== vo.cntrNo) {
          return arr
        }

        const arrPolPortNm = vo.polPortNm.split('%%')
        const arrPodPortNm = vo.podPortNm.split('%%')
        const arrVslNm = vo.vslNm.split('%%')
        const arrEtd = vo.etd.split('%%')
        const arrEta = vo.eta.split('%%')
        const len = arrPolPortNm.length

        for (let i = 0; i < len; i++) {
          arr.push({
            blNo: vo.blNo,
            bkgNo: vo.bkgNo,
            cntrNo: vo.cntrNo,
            cntrIdx: i,
            rowCnt: len,
            polPortNm: arrPolPortNm[i],
            podPortNm: arrPodPortNm[i],
            vslNm: arrVslNm[i],
            etd: arrEtd[i],
            eta: arrEta[i]
          })
        }

        return arr
      }, [])

      this.rowCnt = this.convertList.length
    },
    actChange (vo) {
      this.rsParams.actShprCstEnmYn = vo.isSelected ? 'Y' : 'N'
      this.rsParams.actShprCstEnm = vo.cdNm
      this.rsParams.bkgShprCstNo = vo.cd
    },
    fnActShprCstEnmApply () {
      this.rsParams.actShprCstEnmYn = this.rsParams2.actShprCstEnm !== '' ? 'Y' : 'N'
      this.rsParams.actShprCstEnm = this.rsParams2.actShprCstEnm
      this.rsParams.bkgShprCstNo = this.rsParams2.bkgShprCstNo
    },
    changeCmdtDsc (vo) {
      this.cmdParams.cmdtDsc = vo.cdNm

      if (this.$ekmtcCommon.isNotEmpty(vo.cdNm)) {
        const frm = document.querySelector('#frm')
        const selector = frm.querySelector('#cmdtDsc')
        this.$ekmtcCommon.hideErrorTooltip(selector)
      }
    }
  }
}
</script>

<style scoped>
.ul_box {
  list-style: none;
  margin-top: 10px;
}

.ul_box li {
  float: left;
  margin-left: 3px;
  margin-top: 3px;
  height: 36px;
  line-height: 36px;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  min-width: 200px;
  padding-left: 5px;
  padding-right: 5px;
  text-align: left;
}

.ul_box .minus {
  float: right;
  margin-top: 5px;
}

.td_left_line {
  border-left: 1px solid #e1e3eb !important;
}

.auto-area {
  display: inline-block;
}

</style>
