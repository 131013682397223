<template>
  <div class="wrap">
    <Header></Header>
    <Content></Content>
    <Footer></Footer>
  </div>
</template>
<script>

  import Header from '@/layout/SampleHeader'
  import Content from '@/layout/Content'
  import Footer from '@/layout/Footer'

  export default {
    components: {
      Content,
      Header,
      Footer
    },
    methods: {
    }
  }

</script>
