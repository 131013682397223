<template>
  <div>
    <div
      v-for="(vo, idx) in paramList"
      :key="idx"
      style="display:inline-flex;width:100%;"
    >
      <select
        :ref="'startCtrCd_' + idx"
        v-model="vo.startCtrCd"
        class="custom-select"
        @change="updatePlcList($event, idx, 'start')"
      >
        <option value="">
          ::선택::
        </option>
        <option
          v-for="cvo in ctrList"
          :key="cvo.ctrCd"
          :value="cvo.ctrCd"
        >
          {{ cvo.ctrEnm }}
        </option>
      </select>
      <select
        :ref="'startPlcCd_' + idx"
        v-model="vo.startPlcCd"
        class="custom-select"
        @change="changeStartPlcList"
      >
        <option value="">
          ::선택::
        </option>
        <option
          v-for="cvo in plcList[vo.startCtrCd]"
          :key="cvo.plcCd"
          :value="cvo.plcCd"
        >
          {{ cvo.plcEnm }}
        </option>
      </select>
      <select
        :ref="'destCtrCd_' + idx"
        v-model="vo.destCtrCd"
        class="custom-select"
        @change="updatePlcList($event, idx, 'dest')"
      >
        <option
          key=""
          value=""
        >
          ::선택::
        </option>
        <option
          v-for="cvo in ctrList"
          :key="cvo.ctrCd"
          :value="cvo.ctrCd"
        >
          {{ cvo.ctrEnm }}
        </option>
      </select>
      <select
        :ref="'destPlcCd_' + idx"
        v-model="vo.destPlcCd"
        class="custom-select"
        @change="changeDestPlcList"
      >
        <option
          key=""
          value=""
        >
          ::선택::
        </option>
        <option
          v-for="cvo in plcList[vo.destCtrCd]"
          :key="cvo.plcCd"
          :value="cvo.plcCd"
        >
          {{ cvo.plcEnm }}
        </option>
      </select>
      <div v-if="isMulti === 'Y'">
        <b-button
          v-if="idx === 0"
          size="sm"
          variant="secondary"
          @click="addCountry"
        >
          +
        </b-button>
        <b-button
          v-if="idx !== 0"
          size="sm"
          variant="secondary"
          @click="removeCountry(idx)"
        >
          -
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'SelectCtrPlc',
  props: {
    parentInfo: {
      type: Object,
      default: null
    }
  },
  data () {
    return {
      isMulti: 'N',
      maxRowCnt: 3,
      ctrList: [
        { ctrCd: 'KR', ctrEnm: 'Korea' },
        { ctrCd: 'HK', ctrEnm: 'Hong Kong' }
      ],
      paramList: [],
      plcList: {
        KR: [
          { plcCd: 'PUS', plcEnm: 'Busan,Korea' },
          { plcCd: 'ASN', plcEnm: 'Ansan,Korea' }
        ],
        HK: [
          { plcCd: 'HKG', plcEnm: 'Hong Kong' }
        ]
      },
      searchParam: {
        arrCtrCd: []
      }
    }
  },
  created () {
    //this.commCountryList()
    this.initParams()
  },
  methods: {
    isEmpty (objValue) {
      if (objValue === undefined || objValue === null) {
        return true
      } else {
        return ('' + objValue).length === 0
      }
    },
    isNotEmpty (objValue) {
      return !this.isEmpty(objValue)
    },
    initParams () {
      const parentInfo = this.parentInfo
      const paramList = this.paramList || []
      if (parentInfo !== null) {
        this.maxRowCnt = this.isNotEmpty(parentInfo.maxRowCnt) ? parentInfo.maxRowCnt : this.maxRowCnt
        this.isMulti = parentInfo.isMulti
        const def = {
          startCtrCd: '',
          startPlcCd: '',
          destCtrCd: '',
          destPlcCd: ''
        }

        if (parentInfo.arrList !== null && parentInfo.arrList !== undefined && parentInfo.arrList.length > 0) {
          const arrList = parentInfo.arrList

          arrList.forEach((c, idx) => {
            paramList.push({ ...def, ...c })
          })
        } else {
          paramList.push({ ...def })
        }
      } else {
        paramList.push({ ...def })
      }

      this.initPlcList()
    },
    async initPlcList () {
      const paramList = this.paramList || []
      const searchParam = this.searchParam
      paramList.forEach((c, idx) => {
        if (this.isNotEmpty(c.startCtrCd) && searchParam.arrCtrCd.indexOf(c.startCtrCd) === -1) {
            searchParam.arrCtrCd.push(c.startCtrCd)
        }

        if (this.isNotEmpty(c.destCtrCd) && searchParam.arrCtrCd.indexOf(c.destCtrCd) === -1) {
          searchParam.arrCtrCd.push(c.destCtrCd)
        }
      })
      const arrCtrCd = searchParam.arrCtrCd

      if (arrCtrCd.length > 0) {
        //this.getPlcList('init')
      }
    },
    async commCountryList () {
      await axios
        .get('http://localhost:9080/api/VOSD100/countries')
        .then(res => {
          const result = res.data
          const list = result.data
          this.ctrList = list

          this.initParams()
        })
        .catch(err => {
          console.log(err)
        })
    },
    updatePlcList (e, idx, flag) {
      const ctrCd = e.target.value
      if (this.isNotEmpty(ctrCd) && (this.plcList[ctrCd] === null || this.plcList[ctrCd] === undefined)) {
        const searchParam = this.searchParam
        searchParam.arrCtrCd = []
        searchParam.arrCtrCd.push(ctrCd)

        this.getPlcList()
      }

      const paramList = this.paramList
      if (flag === 'start') {
        paramList[idx].startPlcCd = ''
      } else {
        paramList[idx].destPlcCd = ''
      }
    },
    getPlcList (initFlag) {
      axios
        .get('http://localhost:9080/api/VOSD100/plcs', { params: { arrCtrCd: this.searchParam.arrCtrCd.join(',') } })
        .then(res => {
          const result = res.data
          const list = result.data

          this.plcList = { ...this.plcList, ...list }

          if (initFlag === 'init') {
            const paramList = this.paramList
            paramList.forEach(c => {
              if (this.isNotEmpty(c.startCtrCd) && this.isNotEmpty(c.startPlcCd)) {
                const ctrPlcList = this.plcList[c.startCtrCd]

                if (ctrPlcList !== null && ctrPlcList !== undefined) {
                  const ctrPlcInfo = ctrPlcList.find(v => v.plcCd === c.startPlcCd)
                  if (ctrPlcInfo !== undefined || ctrPlcInfo !== null) {
                    let { plcEnm } = ctrPlcInfo
                    c.startPlcEnm = plcEnm
                  }
                }
              }

              if (this.isNotEmpty(c.destCtrCd) && this.isNotEmpty(c.destPlcCd)) {
                const ctrPlcList = this.plcList[c.destCtrCd]

                if (ctrPlcList !== null && ctrPlcList !== undefined) {
                  const ctrPlcInfo = ctrPlcList.find(v => v.plcCd === c.destPlcCd)
                  if (ctrPlcInfo !== undefined || ctrPlcInfo !== null) {
                    let { plcEnm } = ctrPlcInfo
                    c.destPlcEnm = plcEnm
                  }
                }
              }
            })
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    addCountry () {
      const paramList = this.paramList
      const length = paramList.length

      if (this.maxRowCnt <= length) {
        alert('최대' + this.maxRowCnt + '까지 추가 가능합니다.')
        return
      }

      paramList.push(
        {
          startCtrCd: '',
          startPlcCd: '',
          destCtrCd: '',
          destPlcCd: ''
        }
      )
    },
    removeCountry (idx) {
      const paramList = this.paramList
      paramList.splice(idx, 1)
    },
    changeStartPlcList (e) {
      if (this._events.parentStartPlcChange !== undefined) {
        this.$emit('parentStartPlcChange', e)
      }
    },
    changeDestPlcList (e) {
      if (this._events.parentDestPlcChange !== undefined) {
        this.$emit('parentDestPlcChange', e)
      }
    }
  }
}
</script>
