var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("h5", { staticClass: "mt-5" }, [_vm._v("자동완성??")]),
      _c("search-place"),
      _c("h5", { staticClass: "mt-5" }, [_vm._v("SELECT로 구현")]),
      _c("select-ctr-plc", {
        attrs: { parentInfo: _vm.paramInfo },
        on: { parentStartPlcChange: _vm.testfunction },
      }),
      _c("hr"),
      _c("h5", { staticClass: "mt-5" }, [_vm._v("DATALIST로 구현")]),
      _c("select-ctr-plc2", { attrs: { parentInfo: _vm.paramInfo } }),
      _c("hr"),
      _c("h5", { staticClass: "mt-5" }, [
        _vm._v("DATALIST로 구현 (복수 선택)"),
      ]),
      _c("select-ctr-plc2", { attrs: { parentInfo: _vm.paramInfo2 } }),
      _vm._m(0),
      _c("search-area", { attrs: { parentInfo: _vm.searchInfo } }),
      _vm._m(1),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ml-2 mt-2 mb-5" }, [
      _c("p", [_vm._v("* 출발지/도착지 검색 설정 방법")]),
      _c("p", [_vm._v("1. 컴포넌트 import")]),
      _c("p", [_vm._v("2. template 작성")]),
      _c("p", [
        _vm._v(
          "- parentInfo.arrList : 기본 셋팅 파라미터가 존재할 경우 작성, 없으면 작성하지 않음"
        ),
      ]),
      _c("p", [
        _vm._v(
          "- parentInfo.maxRowCnt : 기본 3개로 설정되어 있음, 추가로 변경이 필요할 경우만 작성."
        ),
      ]),
      _c("p", [
        _vm._v(
          "- parentInfo.isMulti : 'Y'인 경우 출발지, 도착지 +, - 버튼으로 다중 설정 가능. 'N'인 경우 단일."
        ),
      ]),
      _c("p", [
        _vm._v(
          "- @parentStartPlcChange='부모function' 설정시 출발지 항구 selectbox change 이벤트를 부포 컴포넌트에서 설정 가능."
        ),
      ]),
      _c("p", [
        _vm._v(
          "- @parentDestPlcChange='부모function' 설정시 도착지 항구 selectbox change 이벤트를 부포 컴포넌트에서 설정 가능."
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "ml-2 mt-2 mb-5" }, [
      _c("p", [_vm._v("설정 방법")]),
      _c("p", [_vm._v("1. 컴포넌트 import")]),
      _c("p", [_vm._v("2. template 작성")]),
      _c("p", [
        _vm._v(
          "- parentInfo.customSelectList : 조회 조건 selectbox 정보 설정. selectbox에 보여줄 option 순서대로 작성. codeNm은 디폴트 지정되어있으며 별도로 변경 필요할시에만 작성."
        ),
      ]),
      _c("p", [
        _vm._v(
          "- parentInfo.dateTerm : 기본 당일 기준 7일 전후. 별도 설정 필요시에만 작성"
        ),
      ]),
      _c("p", [
        _vm._v(
          "- parentInfo.startDate : dateTerm 값으로 전후 설정하지 않고 별도 지정이 필요할 경우 시작일 지정"
        ),
      ]),
      _c("p", [
        _vm._v(
          "- parentInfo.endDate : dateTerm 값으로 전후 설정하지 않고 별도 지정이 필요할 경우 시작일 지정"
        ),
      ]),
      _c("p", [
        _vm._v("- @parentClickEvt : 검색 버튼 클릭시 실행 function 지정"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }