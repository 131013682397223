<template>
  <div class="content resize">
    <!-- content : 화면 리사이징 되는 화면 resize -->
    <e-breadcrumbs />

    <div class="flex_box">
      <h1 class="page_title">화면 출력</h1>
    </div>
    <div class="content_box">
      <div>
        <button class="button blue lg mt10 layer_open" @click.prevent="screenToPdfDownload()">PDF 다운로드</button>
        &nbsp;
        <button class="button blue lg mt10 layer_open" @click.prevent="screenToPdfUpload()">PDF 서버에 저장</button>
      </div>
      <div class="mt-3" v-if="pdfUploadMessage != ''">
        {{ pdfUploadMessage }}
      </div>
      <div class="mt-3" v-if="pdfUploadMessage != ''">
        <a :href="'http://dev-api.ekmtc.com/common/commons/tempfile?fileId=' + tempFileId" target="_blank">다운로드</a>
      </div>
      <p class="mt-3"><span style="color:blue;font-weight:bold;">SampleHome.vue</span> 파일 참조</p>
    </div>

    <div class="flex_box">
      <h1 class="page_title">페이지 상단 Navigation</h1>
    </div>
    <div class="content_box">
      <p style="color:blue;font-weight:bold;">&lt;Breadcrumbs /&gt;</p>
      <p class="ic_txt mt-3">- 해당 페이지에 위 태그 삽입</p>
    </div>

    <div class="flex_box">
      <h1 class="page_title">자동완성</h1>
    </div>
    <div class="content_box">
      <SampleAutoComplete></SampleAutoComplete>
      <p class="ic_txt mt-3"><span style="color:blue;font-weight:bold;">SampleAutoComplete.vue</span> 파일 참조</p>
    </div>

    <div class="flex_box">
      <h1 class="page_title">자동완성 - 국가, 지역</h1>
    </div>
    <div class="content_box">
      <SampleAutoCompletePlace></SampleAutoCompletePlace>
      <p class="ic_txt mt-3"><span style="color:blue;font-weight:bold;">SampleAutoCompletePlace.vue</span> 파일 참조</p>
    </div>

    <div class="flex_box">
      <h1 class="page_title">자동완성 - Commodity</h1>
    </div>
    <div class="content_box">
      <SampleAutoCompleteCommodity></SampleAutoCompleteCommodity>
      <p class="ic_txt mt-3"><span style="color:blue;font-weight:bold;">SampleAutoCompleteCommodity.vue</span> 파일 참조</p>
    </div>

    <div class="flex_box">
      <h1 class="page_title">자동완성 - 실화주</h1>
    </div>
    <div class="content_box">
      <SampleAutoCompleteCstEnm></SampleAutoCompleteCstEnm>
      <p class="ic_txt mt-3"><span style="color:blue;font-weight:bold;">SampleAutoCompleteCstEnm.vue</span> 파일 참조</p>
    </div>

    <div class="flex_box">
      <h1 class="page_title">Multi select box</h1>
    </div>
    <div class="content_box">
      <SampleMultiSelectBox></SampleMultiSelectBox>
      <p class="ic_txt mt-3"><span style="color:blue;font-weight:bold;">SampleMultiSelectBox.vue</span> 파일 참조</p>
    </div>

    <div class="flex_box">
      <h1 class="page_title">팝업 호출</h1>
    </div>
    <div class="content_box">
      <SamplePopupCall></SamplePopupCall>
      <p class="ic_txt mt-3"><span style="color:blue;font-weight:bold;">SamplePopupCall.vue</span> 파일 참조</p>
    </div>

    <div class="flex_box">
      <h1 class="page_title">Form Error</h1>
    </div>
    <div class="content_box">
      <SampleFormError></SampleFormError>
      <p class="ic_txt mt-3"><span style="color:blue;font-weight:bold;">SampleFormError.vue</span> 파일 참조</p>
    </div>

    <div class="flex_box">
      <h1 class="page_title">주소 검색</h1>
    </div>
    <div class="content_box">
      <SampleAddress></SampleAddress>
      <p class="ic_txt mt-3"><span style="color:blue;font-weight:bold;">SampleAddress.vue</span> 파일 참조</p>
    </div>

    <div class="flex_box">
      <h1 class="page_title">파일 업로드</h1>
    </div>
    <div class="content_box">
      <SampleUploader></SampleUploader>
      <p class="ic_txt mt-3"><span style="color:blue;font-weight:bold;">SampleUploader.vue</span> 파일 참조</p>
      <SampleUploaderEmbed></SampleUploaderEmbed>
      <p class="ic_txt mt-3"><span style="color:blue;font-weight:bold;">SampleUploaderEmbed.vue</span> 파일 참조</p>
    </div>

    <div class="flex_box">
      <h1 class="page_title">Date Picker</h1>
    </div>
    <div class="content_box">
      <sample-date-picker />
      <p class="ic_txt mt-3"><span style="color:blue;font-weight:bold;">SampleDatePicker.vue</span> 파일 참조</p>
    </div>

    <div class="flex_box">
      <h1 class="page_title">숫자 입력</h1>
    </div>
    <div class="content_box">
      <SampleInputNumber></SampleInputNumber>
      <p class="ic_txt mt-3"><span style="color:blue;font-weight:bold;">SampleInputNumber.vue</span> 파일 참조</p>
    </div>
    <div class="flex_box">
      <h1 class="page_title">로그인</h1>
    </div>
    <div class="content_box">
      <sample-login />
      <p class="ic_txt mt-3"><span style="color:blue;font-weight:bold;">SampleLogin.vue</span> 파일 참조</p>
    <!-- content // -->
    </div>
    <div class="flex_box">
      <h1 class="page_title">alert </h1>
    </div>
    <div class="content_box">
      <p class="ic_txt mt-3"><span style="color:blue;font-weight:bold;">this.$ekmtcCommon.alert(obj)</span>호출</p>
      <p> obj.alertType : simple or normal</p>
      <p> obj.customCloseBtnClass : 닫기버튼 css class</p>
      <p> obj.customCloseBtnText: 닫기버튼 문구</p>
      <p> obj.customConfirmBtnClass: 확인버튼 css class</p>
      <p> obj.customConfirmBtnText: 확인버튼 문구</p>
      <p> obj.useConfirmBtn: 확인버튼 쓸지 말지 (true/false)</p>
      <p> obj.tile: normal 모드 알랏에서 타이틀 설정</p>
      <p> obj.message: 본문내용</p>
      <p> obj.type: info(체크아이콘) or success(느낌표아이콘)</p>
      <p> obj.showXclose: x버튼 노출할지 말지 (true/false) </p>
      <button class="button blue lg ml10" @click="openCheckAlert">체크알럿</button>
      <button class="button blue lg ml10" @click="openNoticeAlert">느낌표알럿</button>
      <button class="button blue lg ml10" @click="openAlert1">alert1 확인</button>
      <button class="button blue lg ml10" @click="openAlert2">alert2 확인닫기</button>
      <button class="button blue lg ml10" @click="asyncAlert">async alert</button>
    </div>
    <div class="flex_box">
      <h1 class="page_title">다국어확인 </h1>
    </div>
    <div class="content_box">
      <p> {{ this.$i18n.t('label.TEST01') }}</p>
    </div>
  </div>
</template>

<script>

import commonsApi from '@/api/services/commons'

export default {
  name: 'SampleHome',
  components: {
    SampleUploader: () => import('@/pages/sample/SampleUploader'),
    SampleMultiSelectBox: () => import('@/pages/sample/SampleMultiSelectBox'),
    SampleAutoComplete: () => import('@/pages/sample/SampleAutoComplete'),
    SampleAutoCompletePlace: () => import('@/pages/sample/SampleAutoCompletePlace'),
    SampleAutoCompleteCommodity: () => import('@/pages/sample/SampleAutoCompleteCommodity'),
    SamplePopupCall: () => import('@/pages/sample/SamplePopupCall'),
    SampleFormError: () => import('@/pages/sample/SampleFormError'),
    SampleAddress: () => import('@/pages/sample/SampleAddress'),
    SampleDatePicker: () => import('@/pages/sample/SampleDatePicker.vue'),
    SampleInputNumber: () => import('@/pages/sample/SampleInputNumber.vue'),
    SampleLogin: () => import('@/pages/sample/SampleLogin.vue'),
    SampleUploaderEmbed: () => import('@/pages/sample/SampleUploaderEmbed'),
    SampleAutoCompleteCstEnm: () => import('@/pages/sample/SampleAutoCompleteCstEnm'),
    EBreadcrumbs: () => import('@/components/common/EBreadcrumbs')
  },
  data: function () {
    return {
      pdfUploadMessage: '',
      tempFileId: ''
    }
  },
  methods: {
    openAlert1 () {
      //   alertType: 'simple',  alerttype simple or normal
      //   customCloseBtnClass: 'button blue lg simplert-test1',
      //   customCloseBtnText: '확인',
      //   useIcon: false,
      //   customClass: 'simple_alert',
      //   message: '검색어를 입력해주세요',
      //   type: 'info'
      let obj = {
        onClose: this.closeFun,
        onConfirm: this.confirmFun,
        alertType: 'simple',
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: '확인',
        useIcon: false,
        customClass: 'simple_alert',
        message: '검색어를 입력해주세요',
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    closeFun () {
      alert('closeFun')
    },
    confirmFun () {
      alert('closeCon')
    },
    openAlert2 () {
      console.log(this)
      let obj = {
        onClose: this.closeFun,
        onConfirm: this.confirmFun,
        customCloseBtnClass: 'button blue lg',
        customCloseBtnText: '닫기',
        customConfirmBtnText: '확인',
        useConfirmBtn: true,
        customConfirmBtnClass: 'button blue lg',
        message: '검색어를 입력해주세요',
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    async asyncAlert () {
      let obj = {
        alertType: 'normal',
        showXclose: true,
        customCloseBtnClass: 'button blue lg',
        customCloseBtnText: '닫기',
        customConfirmBtnText: '확인',
        customConfirmBtnClass: 'button blue lg',
        useConfirmBtn: true,
        title: '타이틀',
        message: '따라서 하기 LOI DOWNLOAD를 통해 양식을 다운 받은 뒤,<br> 내용 입력 및 B/L SHIPPER의 직인/명판 득하여<br> 스캔본 업로드 부탁드립니다.',
        type: 'info'
      }
      if (await this.$ekmtcCommon.asyncAlert(obj)) {
        alert('func call')
      }
    },
    openCheckAlert () {
      let obj = {
        alertType: 'normal',
        showXclose: true,
        customCloseBtnClass: 'button blue lg',
        customCloseBtnText: '닫기',
        customConfirmBtnText: '확인',
        customConfirmBtnClass: 'button blue lg',
        useConfirmBtn: true,
        title: '타이틀',
        message: '따라서 하기 LOI DOWNLOAD를 통해 양식을 다운 받은 뒤,<br> 내용 입력 및 B/L SHIPPER의 직인/명판 득하여<br> 스캔본 업로드 부탁드립니다.',
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
      // this.$Simplert.open(obj)
    },
    openNoticeAlert () {
      let obj = {
        alertType: 'normal',
        showXclose: true,
        customCloseBtnClass: 'button blue lg',
        customCloseBtnText: '닫기',
        title: '타이틀',
        message: '따라서 하기 LOI DOWNLOAD를 통해 양식을 다운 받은 뒤,<br> 내용 입력 및 B/L SHIPPER의 직인/명판 득하여<br> 스캔본 업로드 부탁드립니다.',
        type: 'success'
      }
      this.$ekmtcCommon.alert(obj)
    },
    screenToPdfDownload () {
      this.$ekmtcCommon.screenToPdf({ cssSelector: 'body > div', fileName: 'sample-home.pdf', download: 'Y' })
    },
    screenToPdfUpload () {
      this.$ekmtcCommon.screenToPdf({ cssSelector: 'body > div' })
        .then((res) => {
          const formData = new FormData()
          formData.append('files', res.output('blob'), 'sample-home.pdf')

          commonsApi.tempFileUpload(formData).then((result) => {
              const fileId = result.data.fileId
              this.tempFileId = fileId
              this.pdfUploadMessage = 'SELECT * FROM CM530I WHERE FILE_ID = ' + fileId + ';'
            })
            .catch(err => {
              console.log(err)
            })
        })
    },
    openPopup: function () {
      console.log('test')
      // this.display = true
      $('.popup_dim').fadeIn()
      $('body,html').css('overflow', 'hidden')
    }
  }
}
</script>

<style scoped>

</style>
